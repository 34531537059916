import { isEmail, isPhoneNumber } from '../../../core/helper'

const validate = values => {
  const errors = {}

  if (!values.firstNameTh) {
    errors.firstNameTh = 'ต้องใส่ข้อมูลในช่องนี้'
  }
  if (!values.lastNameTh) {
    errors.lastNameTh = 'ต้องใส่ข้อมูลในช่องนี้'
  }

  if (!values.email) {
    errors.email = 'ต้องใส่ข้อมูลในช่องนี้'
  } else if (!isEmail(values.email)) {
    errors.email = 'รูปแบบอีเมลไม่ถูกต้อง'
  }

  if (!values.phoneNumber) {
    errors.phoneNumber = 'ต้องใส่ข้อมูลในช่องนี้'
  } else if (!isPhoneNumber(values.phoneNumber)) {
    errors.phoneNumber = 'รูปแบบหมายเลขไม่ถูกต้อง'
  }

  if (!values.birthdate) {
    errors.birthdate = 'ต้องใส่ข้อมูลในช่องนี้'
  } else if (values.birthdate.length < 10) {
    errors.birthdate = 'กรุณากรอกวันเดือนปี (วว/ดด/ปปปป)'
  }

  if (!values.topicName) {
    errors.topicName = 'ต้องใส่ข้อมูลในช่องนี้'
  }

  return errors
}

export default validate
