import React from 'react'
import styled, { css } from 'react-emotion'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { TQM_SHOP_LINK } from '../../core/helper'
import { responsive } from '../../core/style'

const Container = styled.div`
  display: flex;
  flex: 1;
  margin-top: 20px;
  width: 100%;
  max-width: 915px;

  ${props =>
    responsive.tablet(css`
      position: relative;
      font-size: 12px;
      padding: 0 25px;
      padding-bottom: 35px;
    `)};

  ${props =>
    responsive.mobile(css`
      display: none;
    `)};
`
const FooterMenulistContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 50px;
  &.loginAndRegister {
    margin-left: auto;
    margin-right: 0;
  }

  ${props =>
    responsive.tablet(css`
      margin-right: 30px;
      &.loginAndRegister {
        position: absolute;
        right: 30px;
        top: 0;
        margin-right: 0;
      }

      &.aboutUs {
        margin-right: 19px;
      }
    `)};
`

const Topic = styled.div`
  margin-bottom: 10px;

  font-weight: bold;
  cursor: pointer;
`

const List = styled.div`
  font-size: 12px;
  cursor: pointer;
  margin: 2.5px 0;
`
const LoginAndRegisterPart = styled.div`
  display: flex;
`

const Line = styled.div`
  margin: 0 5px;
`

const CustomLink = styled.a`
  color: white;
  :active,
  :hover {
    color: white;
  }
  :focus {
    color: white;
    text-decoration: none;
  }
`

const FooterMenuList = ({ isLogin }) => (
  <Container>
    <FooterMenulistContainer>
      <CustomLink href="/smart">
        <Topic>NOON SMART</Topic>
      </CustomLink>
      <CustomLink href="/smart/InsuranceCategory/life">
        <List>ประกันชีวิต</List>
      </CustomLink>
      <CustomLink href="/smart/InsuranceCategory/health/intermediate">
        <List>ประกันสุขภาพ</List>
      </CustomLink>
      <CustomLink href={TQM_SHOP_LINK}>
        <List>ประกันรถยนต์</List>
      </CustomLink>
    </FooterMenulistContainer>
    <FooterMenulistContainer>
      <CustomLink href={TQM_SHOP_LINK}>
        <Topic>BUY ONLINE</Topic>
      </CustomLink>
      <CustomLink href={TQM_SHOP_LINK}>
        <List>ซื้อออนไลน์</List>
      </CustomLink>
    </FooterMenulistContainer>
    <FooterMenulistContainer>
      <CustomLink href="/blog">
        <Topic>NOON BLOG</Topic>
      </CustomLink>
      <CustomLink href="/blog">
        <List>อ่านบทความ</List>
      </CustomLink>
    </FooterMenulistContainer>
    <FooterMenulistContainer>
      <CustomLink href="/contact-us">
        <Topic>CONTACT US</Topic>
      </CustomLink>
    </FooterMenulistContainer>
    {!isLogin && (
      <FooterMenulistContainer className="loginAndRegister">
        <LoginAndRegisterPart>
          <CustomLink href="/authorize/login">
            <Topic>LOG IN </Topic>
          </CustomLink>
          <Line>|</Line>
          <CustomLink href="/authorize/register">
            <Topic>REGISTER</Topic>
          </CustomLink>
        </LoginAndRegisterPart>
      </FooterMenulistContainer>
    )}
  </Container>
)

const enhancer = compose(
  connect(
    state => ({
      isLogin: state.auth.isLogin,
    }),
    {}
  )
)
export default enhancer(FooterMenuList)
