import React from 'react'
import styled from 'react-emotion'
import { compose, withProps, withState } from 'recompose'

import { Header as NoonQuestionHeader } from '../NoonQuestion/components'
import Background from './Background'
import Content from './Content'
import CountNumber from './CountNumber'
import Free from './Free'
import Noon from './Noon'
import SearchBox from './SearchBox'

import withSEO from '../share/withSEO'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  overflow: hidden;
`

const TopContainer = styled.div`
  min-height: 607px;
  display: flex;
  flex-direction: column;
`

const Landing = ({ gender, setGender, location, showNoonQuestionHeader }) => (
  <Container>
    <Background gender={gender} />
    {showNoonQuestionHeader && <NoonQuestionHeader show={false} />}
    <TopContainer>
      <SearchBox gender={gender} setGender={setGender} location={location} />
      <Noon />
    </TopContainer>
    <CountNumber />
    <Content />
    <Free />
  </Container>
)

const enhancer = compose(
  withSEO(
    'noon Insure With Accuracy - ค้นหาแบบประกันภัยที่ใช่ ตามไลฟ์สไตล์คุณ',
    'noon เครื่องมือในการวางแผนและเลือกซื้อแบบประกันภัยที่เหมาะสม ตอบโจทย์ทุกไลฟ์สไตล์ของคุณ คิดเรื่องประกันไม่ออกให้บอกนูน เพราะนูนรับประกันความเที่ยงตรง',
    'แบบประกันภัยครบวงจร,ซื้อประกันอะไรดี,ทุกเรื่องครบ จบเกี่ยวกับการประกันภัย'
  ),
  withState('gender', 'setGender', 'male'),
  withProps(props => ({
    showNoonQuestionHeader: true,
  }))
)

export default enhancer(Landing)
