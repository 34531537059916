import { connect } from 'react-redux'
import { compose, lifecycle, withState } from 'recompose'
import { formValueSelector, reduxForm } from 'redux-form'

import { editProfile, getAvatar } from '../../../ducks/profile'
import { toDate } from '../../NoonTax/components/DatePicker'
import validate from './UserProfile.validate'

const selector = formValueSelector('userProfile')

const enhancer = compose(
  connect(
    state => ({
      logo: selector(state, 'selectFile'),
      user: state.auth.user,
      avartars: state.profile.avartars,
      isLoading: state.profile.isLoading,
      avatar: selector(state, 'avatar'),
      initialValues: {
        gender: 'male',
        ...state.auth.user,
        birthdate: toDate(state.auth.user.birthdate),
        avatarImage: state.auth.user.avatar,
      },
    }),
    { editProfile, getAvatar }
  ),
  withState('isShowModal', 'setIsShowModal', false),
  withState('avatarId', 'setAvatarId', ''),
  reduxForm({
    form: 'userProfile',
    validate,
    enableReinitialize: true,
  }),
  lifecycle({
    componentDidMount() {
      const { getAvatar } = this.props
      getAvatar()
    },
  })
)

export default enhancer
