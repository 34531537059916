import React from 'react'
import styled, { css } from 'react-emotion'
import { connect } from 'react-redux'
import { compose, lifecycle } from 'recompose'

import { go2TQM } from '../../ducks/product'
import logo from '../../static/images/Noon-Logo-PNG-512.png'
import { responsive, tada } from '../../core/style'

const Container = styled.div`
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  height: 100vh;
  padding: 50px 20px;

  background-color: white;
`

const Header = styled.div`
  display: flex;
  flex-direction: column;
`

const Noon = styled.img`
  height: 200px;
  margin: 0 auto;

  animation: ${tada} 1s linear 2s infinite;
`

const Text = styled.div`
  text-align: center;

  a {
    color: #ffb933;
  }

  br {
    display: none;
  }

  ${props =>
    responsive.mobile(css`
      br {
        display: block;
      }
    `)};
`

const LoadingContainer = styled.div`
  position: relative;

  width: 100%;
  height: 8px;
  margin-top: 15px;

  border-radius: 4px;
  overflow: hidden;

  background-color: #fff4de;
`

const LoadingBar = styled.div`
  position: absolute;

  width: 100%;
  height: 8px;

  border-radius: 4px;
  background-color: ${props => props.theme.ORANGE};

  animation: loading 5s ease-out;

  @keyframes loading {
    from {
      transform: translateX(-100%);
    }
    to {
      transform: translateX(0);
    }
  }
`

const Footer = styled.div`
  color: ${props => props.theme.GREY50};
  font-size: 12px;
  line-height: 14px;
  text-align: center;
`

const LoadingMask = props => {
  return (
    <Container>
      <Header>
        <Noon src={logo} alt="noon" />
        <Text>
          เรากำลังพาคุณไปยังเว็บไซต์ผู้จัดจำหน่ายแผนประกัน <br />
          ถ้าหากรอนานแล้วแต่ยังไม่ถึงหน้าเว็บไซต์{' '}
          <a onClick={props.go2TQM}>คลิกที่นี่</a>
        </Text>
        <LoadingContainer>
          <LoadingBar />
        </LoadingContainer>
      </Header>
      <Footer>
        COPYRIGHT © 2020 ALL RIGHT RESERVED
        <br />
        นโยบายและเงื่อนไขการใช้งาน
        <br />
        EMPOWERED BY TQD
      </Footer>
    </Container>
  )
}

const enhancer = compose(
  connect(
    state => ({}),
    { go2TQM }
  ),
  lifecycle({
    componentDidMount() {
      document.getElementsByTagName('body')[0].style.overflowY = 'hidden'
    },
    componentWillUnmount() {
      document.getElementsByTagName('body')[0].style.overflowY = 'auto'
    },
  })
)

export default enhancer(LoadingMask)
