import React, { Fragment } from 'react'
import styled from 'react-emotion'
import { connect } from 'react-redux'
import { compose, withState, lifecycle, withHandlers } from 'recompose'

import ContentCard from '../NoonSquare/Card'
import PolicyCard from '../NoonSmart/Product/Card/index'
import BlogCard from '../NoonSquare/BlogCard'
import FundCard from '../NoonFund/components/FundCard'
import { getFavorite } from '../../ducks/profile'
import { fetchFavoriteFunds } from '../../ducks/fund'

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
`

const HeaderOption = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 30px;
  margin: 20px 0 30px;
`

const Header = styled.div`
  font-size: 20px;
  font-weight: bold;
`

const HeaderSub = styled.span`
  font-size: 16px;
  font-weight: ${props => props.selectedContent && 'bold'};
  cursor: ${props => !props.selectedContent && 'pointer'};
  margin: 0 5px;
`
const Or = styled.span`
  font-size: 16px;
  margin: 0 10px;
`
const ContainerCard = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`
const CardWrapper = styled.div`
  margin: 0 10px 20px 10px;
`

const FavoliteProfile = ({
  selectedContent,
  onArticleClick,
  onPolicyClick,
  onFundClick,
  articles,
  insurancePolicies,
  favoriteFunds,
  wpBlog,
}) => (
  <Container>
    <Header>รายการโปรด</Header>
    <HeaderOption>
      <HeaderSub
        selectedContent={selectedContent === 'article'}
        onClick={onArticleClick}
      >
        บทความ
      </HeaderSub>
      <Or>|</Or>
      <HeaderSub
        selectedContent={selectedContent === 'policy'}
        onClick={onPolicyClick}
      >
        แบบประกัน
      </HeaderSub>
      <Or>|</Or>
      <HeaderSub
        selectedContent={selectedContent === 'fund'}
        onClick={onFundClick}
      >
        กองทุน
      </HeaderSub>
    </HeaderOption>
    {selectedContent === 'article' ? (
      <ContainerCard>
        {articles.map(article => {
          !!article && article.favorite ? (
            <ContentCard key={article.id} value={article} from="profile" />
          ) : null
        })}
        {wpBlog.map(blog => (
          <BlogCard blog={blog} />
        ))}
      </ContainerCard>
    ) : selectedContent === 'policy' ? (
      <ContainerCard>
        {insurancePolicies.map((policy, index) =>
          policy.favorite ? (
            <Fragment>
              <PolicyCard
                key={index}
                data={policy}
                params={JSON.parse(atob(policy.paramsEncode))}
                fromFavoriteProfile
              />
            </Fragment>
          ) : null
        )}
      </ContainerCard>
    ) : null}
    {selectedContent === 'fund' && (
      <ContainerCard>
        {favoriteFunds.map(fund => (
          <CardWrapper key={fund.refId}>
            <FundCard {...fund} comparable={false} />
          </CardWrapper>
        ))}
      </ContainerCard>
    )}
  </Container>
)

const enhancer = compose(
  connect(
    state => ({
      articles: state.profile.articles || [],
      wpBlog: state.profile.wpBlog || [],
      insurancePolicies: state.profile.insurancePolicies || [],
      favoriteFunds: state.fund.favoriteFunds,
    }),
    { getFavorite, fetchFavoriteFunds }
  ),
  withState('selectedContent', 'setSelectedContent', 'article'),
  withHandlers({
    onArticleClick: ({ setSelectedContent }) => () => {
      setSelectedContent('article')
    },
    onPolicyClick: ({ setSelectedContent }) => () => {
      setSelectedContent('policy')
    },
    onFundClick: ({ setSelectedContent }) => () => {
      setSelectedContent('fund')
    },
  }),
  lifecycle({
    componentDidMount() {
      const { getFavorite, fetchFavoriteFunds } = this.props
      getFavorite()
      fetchFavoriteFunds()
    },
  })
)

export default enhancer(FavoliteProfile)
