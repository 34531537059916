import React from 'react'
import jump from 'jump.js'
import { change } from 'redux-form'
import { connect } from 'react-redux'
import styled from 'react-emotion'
import { compose, withState } from 'recompose'
import TrackVisibility from 'react-on-screen'
import useWindowSize from '@rehooks/window-size'

import CoverageTable from './CoverageTable'
import { Products } from './utils'
import DetailModal from './DetailModal'

import { breakpoints } from '../../core/style'
import CompanyTable from './CompanyTable'

const Container = styled.div`
  width: 100%;
  padding: 60px 0 30px;
  margin: 0;

  color: ${props => props.theme.GREY80};
  background-color: white;
`

const Coverage = props => {
  const windowSize = useWindowSize()
  const go2Buy = () => {
    if (windowSize.innerWidth > breakpoints.tablet) {
      jump('#corona_lead', { offset: -80 })
    } else {
      jump('#corona_lead')
    }
  }

  return (
    <React.Fragment>
      <Container>
        <TrackVisibility once>
          {({ isVisible }) => isVisible && props.onCoverageSectionVisible()}
        </TrackVisibility>
        <CoverageTable
          go2Buy={go2Buy}
          change={props.change}
          setDetailIndex={props.setDetailIndex}
          onClickGotoBuyPolicy={props.onClickGotoBuyPolicy}
          onOpenPolicyDetailModal={props.onOpenPolicyDetailModal}
        />
        {Products.map((product, index) => (
          <CompanyTable
            key={index}
            go2Buy={go2Buy}
            company={product}
            change={props.change}
            setDetailIndex={props.setDetailIndex}
            onClickGotoBuyPolicy={props.onClickGotoBuyPolicyOnIndividualTable}
            onOpenPolicyDetailModal={props.onOpenPolicyDetailModal}
          />
        ))}
      </Container>
      <DetailModal
        index={props.detailIndex}
        isShow={props.detailIndex > -1}
        onClose={() => props.setDetailIndex(-1)}
      />
    </React.Fragment>
  )
}

const enhancer = compose(
  connect(
    () => ({}),
    { change }
  ),
  withState('detailIndex', 'setDetailIndex', -1)
)

export default enhancer(Coverage)
