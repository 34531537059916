import React from 'react'
import styled from 'react-emotion'
import { compose, withState, withHandlers } from 'recompose'
import { connect } from 'react-redux'
import jump from 'jump.js'
import windowSize from '../../core/react-window-size'
import withSEO from '../share/withSEO'

import FAQ from './FAQ'
import Header from './Header'
import Coverage from './Coverage'
import LeadForm from './LeadForm'
import Loading from '../share/Loading'
import LoadingMask from './LoadingMask'
import ModalConsent from './ModalConsent'

import { breakpoints } from '../../core/style'

const Container = styled.div`
  background-color: white;
`

const Corona = props => {
  const {
    isLoading,
    isShowModal,
    onOpenModal,
    onCloseModal,
    onPriceClick,
    onDetailSectionVisible,
    onCoverageSectionVisible,
    onLeadFormVisible,
    onOpenPolicyDetailModal,
    onClickGotoBuyPolicy,
    onClickGotoBuyPolicyOnIndividualTable,
    onToggleShowFaq,
  } = props
  return (
    <Loading isLoading={isLoading}>
      <Container>
        <Header onPriceClick={onPriceClick} />
        <Coverage
          onCoverageSectionVisible={onCoverageSectionVisible}
          onOpenPolicyDetailModal={onOpenPolicyDetailModal}
          onClickGotoBuyPolicy={onClickGotoBuyPolicy}
          onClickGotoBuyPolicyOnIndividualTable={
            onClickGotoBuyPolicyOnIndividualTable
          }
        />
        <LeadForm
          onOpenModal={onOpenModal}
          onLeadFormVisible={onLeadFormVisible}
        />
        <FAQ onToggleShowFaq={onToggleShowFaq} />
        <ModalConsent isShow={isShowModal} onClose={onCloseModal} />
        {props.isBuyCoronaSuccess && <LoadingMask />}
      </Container>
    </Loading>
  )
}

const enhancer = compose(
  withSEO(
    'noon COVID-19 | ประกันภัยไวรัสโคโรนา',
    'noon ประกันภัยไวรัสโคโรนา COVID-19 หากตรวจเจอเชื้อไวรัสรับเงินทันที คุ้มครองค่ารักษาพยาบาล และรับเงินก้อนเมื่อเจ็บป่วยด้วยภาวะโคม่า จากการติดเชื้อไวรัส COVID-19',
    'ประกันภัย,โคโรนา,COVID-19,ไวรัสอู่ฮั่น,เจอจ่ายจบ,ภาวะโคม่า,ประกันโคโรนา,ประกันโควิด,เบี้ยต่ำ,ค่ารักษาพยาบาล,คุ้มครองเลย,ทีคิวเอ็ม,สินทรัพย์ประกันภัย ,วิริยะประกันภัย,ทิพยประกันภัย,กรุงเทพประกันภัย'
  ),
  connect(
    state => ({
      isLoading: state.product.isLoading,
      isBuyCoronaSuccess: state.product.isBuyCoronaSuccess,
    }),
    {}
  ),
  withState('isShowModal', 'setShowModal', false),
  withHandlers({
    onPriceClick: ({ windowWidth }) => () => {
      dataLayer.push({
        event: 'call_to_action',
        actionButtonName: 'corona_insurance_banner',
      })

      if (windowWidth > breakpoints.tablet) {
        jump('#corona_lead', { offset: -80 })
      } else {
        jump('#corona_lead')
      }
    },
    onOpenModal: ({ setShowModal }) => () => {
      dataLayer.push({
        event: 'open_modal',
        modalName: 'corona_insurance_consent',
      })

      setShowModal(true)
    },
    onCloseModal: ({ setShowModal }) => () => {
      dataLayer.push({
        event: 'close_modal',
        modalName: 'corona_insurance_consent',
      })

      setShowModal(false)
    },
    onLeadFormVisible: _props => () =>
      dataLayer.push({
        event: 'lead_form_appear',
        leadFormName: 'corona_insurance',
      }),
    onDetailSectionVisible: _props => () =>
      dataLayer.push({
        event: 'section_appear',
        sectionName: 'corona_insurance_detail',
      }),
    onCoverageSectionVisible: _props => () =>
      dataLayer.push({
        event: 'section_appear',
        sectionName: 'corona_insurance_coverage',
      }),
    onOpenPolicyDetailModal: _props => (policyName, device) =>
      dataLayer.push({
        event: 'open_modal',
        modalName: `corona_policy_${policyName}_detail_modal_on_${device}`,
        device: device,
      }),
    onClickGotoBuyPolicy: _props => (policyName, device) =>
      dataLayer.push({
        event: 'button_click',
        buttonName: `goto_buy_corona_policy_${policyName}_on_${device}`,
        policyName: policyName,
        device: device,
      }),
    onClickGotoBuyPolicyOnIndividualTable: _props => (policyName, device) =>
      dataLayer.push({
        event: 'button_click',
        buttonName: `goto_buy_corona_policy_from_individual_table_${policyName}_on_${device}`,
        policyName: policyName,
        device: device,
        onScreenLocation: 'individual_table',
      }),
    onToggleShowFaq: _props => faqQuestion =>
      dataLayer.push({
        event: 'read_faq',
        faqQuestion: faqQuestion,
      }),
  })
)

export default windowSize(enhancer(Corona))
