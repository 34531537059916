import React from 'react'
import styled from 'react-emotion'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Favorite from '../Favorite'

import { getThaiDate } from '../../../core/helper'

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 300px;
  height: 420px;
  background-color: white;
  border: 1px solid #7f7f7f;
  border-radius: 5px;
  overflow: hidden;
  margin: 10px 10px;
`

const CardImage = styled.a`
  width: 300px;
  height: 200px;
  margin-bottom: 17px;
  cursor: pointer;

  background: url(${props => props.src});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`

const ContentCardContainer = styled.div`
  position: relative;

  width: 100%;
  height: 163px;
  padding: 0 20px;
`

const CardTagContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
`

const FooterIcon = styled(FontAwesomeIcon)`
  font-size: 16px;
  color: #7f7f7f;
`

const TagContainer = styled.div`
  display: flex;
  max-width: 180px;
  overflow: hidden;
`

const Tag = styled.div`
  margin-right: 5px;

  font-size: 14px;
  color: #265fa7;
  white-space: nowrap;
  /* cursor: pointer; */
`

const ContentCardName = styled.h1`
  overflow: hidden;
  max-height: 50px;
  margin: 0;

  font-size: 20px;
  font-weight: bold;
  line-height: 24px;
  color: black;

  cursor: pointer;
`

const ContentCardDate = styled.h2`
  font-size: 12px;
  color: #7f7f7f;
  text-align: left;
  margin-bottom: 8px;
`

const ContentCardDetails = styled.p`
  font-size: 16px;
  max-height: 80px;
`

const LinkTag = styled.a`
  position: absolute;
  right: 25px;
  bottom: 0;

  font-size: 16px;
  color: #265fa7;
  text-decoration: underline;
`

const ContentCardFooterContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
`

const ContentCardFooterNumber = styled.div`
  font-size: 12px;
  color: #7f7f7f;
  margin-left: 8px;
  margin-right: 21px;
`

const SpanText = styled.span`
  display: block;
  font-family: Sarabun, sans-serif;
  line-height: 20px;
`

const truncate = text => {
  const t = text
  var subCharReg = new RegExp(/[([ิีึืุูั้็่๋๊ํ์)]/, 'g')
  const limit = 60
  return t.split('').reduce(
    (mem, cur) => {
      if (!cur.match(subCharReg)) {
        mem.count++
      }
      if (mem.count < limit) mem.text += cur
      if (mem.count === limit) mem.text += '...'
      return mem
    },
    { text: '', count: 0 }
  ).text
}

const ContentCard = ({ value, from }) => (
  <Container>
    <CardImage
      src={value.imageArticle}
      href={`/blog/details/${value.id}`}
      target="_blank"
    />
    <ContentCardContainer>
      <CardTagContainer>
        <TagContainer>
          {value.tags[0] && <Tag>#{value.tags[0].name}</Tag>}
          {value.tags[1] && <Tag>#{value.tags[1].name}</Tag>}
        </TagContainer>
        <Favorite article={value} type="card" from={from} />
      </CardTagContainer>
      <ContentCardName
        onClick={() => window.open(`/blog/details/${value.id}`, '_blank')}
      >
        {value.topicName}
      </ContentCardName>
      <ContentCardDate>
        {getThaiDate(value.createdAt)} บทความโดย {value.signatureSynonym}
      </ContentCardDate>
      <ContentCardDetails>
        <SpanText>{truncate(value.description)}</SpanText>
        <LinkTag
          id={`square-read-more-${value.id}`}
          href={`/blog/details/${value.id}`}
          target="_blank"
        >
          Read more
        </LinkTag>
      </ContentCardDetails>
    </ContentCardContainer>
    <ContentCardFooterContainer>
      <FooterIcon icon={['far', 'heart']} />
      <ContentCardFooterNumber>{value.favoriteCount}</ContentCardFooterNumber>
      <FooterIcon icon={['far', 'comment-alt']} />
      <ContentCardFooterNumber>{value.commentCount}</ContentCardFooterNumber>
      <FooterIcon icon={['fas', 'share-alt']} />
      <ContentCardFooterNumber>{value.shareCount}</ContentCardFooterNumber>
    </ContentCardFooterContainer>
  </Container>
)

export default ContentCard
