import React from 'react'
import styled, {css} from 'react-emotion'
import {connect} from 'react-redux'
import {compose, withState, lifecycle} from 'recompose'
import Button from '../../share/Button'
import {getPort} from '../../../ducks/profile'
import Port from './Port.js'
import {responsive} from '../../../core/style'
import InsurancePortModal from './InsurancePortModal'
import ShowPortDetail from './ShowPortDetail'
import InsuranceCreatePortModal from './InsuranceCreatePortModal'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

const EditIcon = styled(FontAwesomeIcon)`
  font-size: 16px;
  cursor: pointer;
`
const SubHeader = styled.div`
  font-size: 16px;
  font-weight: bold;
  text-align: center;
`

const Upper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  height: 54px;
  width: 100%;
`

const ContainerAllDetail = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 604px;
  height: auto;

  @media screen and (max-width: 900px) {
    width: 470px;
  }

  ${props =>
    responsive.mobile(css`
      width: 320px;
    `)};
`

const ContainerPortDetail = styled.div`
  display: flex;
  flex-direction: column;
  width: 600px;
  height: 150px;
  border-radius: 3px;
  background-color: white;
  font-size: 12px;
  margin: 15px 0px 0px 0px;

  padding: 13px 15px;

  @media screen and (max-width: 900px) {
    width: 470px;
  }
`

const Hr = styled.hr`
  background-color: #6a94cb;
  height: 1px;
  margin: 30px 0;
  width: 100%;
`

const LogoImage = styled.img`
  width: 30px;
  height: 30px;

  border-radius: 10%;
  object-fit: cover;
`
const Description = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`
const NameCompany = styled.div`
  font-size: 14px;
  font-weight: bold;
  font-family: Prompt;
  text-align: center;

  margin-top: 10px;
  display: flex;
  align-self: flex-end;
`

const TextHead = styled.div`
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  font-family: Prompt;
`

const TextNormal = styled.div`
  font-size: 12px;

  text-align: center;
`

const TextSub = styled.div`
  font-size: 12px;

  text-align: center;
`

const TextBigRight = styled.div`
  font-size: 12px;
  display: flex;
  justify-content: flex-end;

  text-align: center;

  width: 100px;
`

const TextBigBoldRight = styled.div`
  font-size: 12px;
  display: flex;
  font-family: Prompt;
  justify-content: flex-end;
  font-weight: bold;
  text-align: center;

  width: 100px;
`

const PaymentDateContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
`

const PremiunDetailContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-self: flex-end;
`
const AnnualpremiumContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-self: flex-end;
  margin-top: -7px;
`

const CustomButton = styled(Button)`
  font-size: 12px;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 600px;
  background-color: white;
  font-size: 12px;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  opacity: 1;
  padding: 20px 15px;
`

const ButtonContainer = styled.div`
  display: flex;
`

// const ShowDetailHeader = styled.div`
//   font-size: 16px;
//   font-weight: bold;
//   font-family: Prompt;

//   display: flex;
//   justify-content: flex-start;

//   &.CustomSub {
//     font-size: 14px;
//     margin-top: 2px;
//   }
// `

// const ShowDetaillContainer = styled.div`
//   display: flex;
//   flex-direction: row;

//   &.CustomBlackTopLine {
//     color: #333333;
//     justify-content: space-between;
//   }

//   &.CustomGrayTopLine {
//     color: #7f7f7f;
//     justify-content: flex-end;
//   }

//   &.CustomGrayButtomLine {
//     color: #7f7f7f;
//     margin-left: 37px;
//   }
// `

// const ShowDetailNormalText = styled.div`
//   font-size: 14px;
//   text-align: center;
// `

// const ShowDetailNormalTextBath = styled.div`
//   font-size: 14px;
//   text-align: center;
//   margin-left: 10px;
// `

// const ShowDetailNormalNumber = styled.div`
//   font-size: 14px;
//   display: flex;
//   justify-content: flex-end;
//   text-align: center;

//   &.CustomSumInsuredText {
//     font-family: Prompt;
//     min-width: 98px;
//   }

//   &.CustomPremiumText {
//     font-family: Prompt;
//     min-width: 69px;
//   }

//   &.CustomText {
//     min-width: 69px;
//   }

//   &.CustomPayDaily {
//     min-width: 278px;
//   }

//   &.CustomPayRoom {
//     min-width: 315px;
//   }

//   &.CustomAllPay {
//     min-width: 306px;
//   }

//   &.CustomPayExternalPatient {
//     min-width: 249px;
//   }

//   &.CustomDeadlyDisease {
//     min-width: 200px;
//   }

//   &.CustomDeadByCancer {
//     min-width: 198px;
//   }

//   &.CustomPayCancer {
//     min-width: 203px;
//   }

//   &.CustomSaveLife {
//     min-width: 155px;
//   }

//   &.CustomDailyPayAccident {
//     min-width: 191px;
//   }
// `

// const DetailContainer = styled.div`
//   display: flex;

//   &.CustomTop {
//     margin-top: 15px;
//   }

//   &.CustomButtom {
//     margin-top: 10px;
//     flex-direction: column;
//   }
// `

// const ShowDetailSubHeader = styled.div`
//   color: #333333;
//   font-size: 14px;
//   font-family: Prompt;
//   margin-left: 19px;

//   &.CustomSubFirst {
//     margin-top: 15px;
//   }

//   &.CustomSubNext {
//     margin-top: 20px;
//   }
// `

const Topics = styled.div`
  display: flex;
  margin: 50px 5px 0 0;
`

const Topic = styled.h1`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  background: ${props => (props.isActived ? '#ffffff' : '#E5E5E5')};
  color: '#333333';

  font-size: 12px;
  margin: 0 0 0 5px;
  padding: 6px 17px;
  border-radius: 5px 5px 0px 0px;
  opacity: ${props => (props.isActived ? 1 : 0.6)};
  :hover {
    cursor: pointer;
  }
`

const SwapTab = styled.form`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 604px;
  height: auto;
  margin-right: 3px;
`

const Coverage = ({portData, swap, setSwap, isShowModal, setIsShowModal}) => (
  <>
    <SwapTab>
      <Topics>
        {/* <Topic isActived={swap === 'graph'} onClick={() => setSwap('graph')}>
          ดูกราฟ
        </Topic> */}
        <Topic isActived={swap === 'detail'} onClick={() => setSwap('detail')}>
          ดูรายละเอียด
        </Topic>
        {/* <Topic
          isActived={swap === 'assessCoverage'}
          onClick={() => setSwap('assessCoverage')}>
          ประเมินความคุ้มครอง
        </Topic> */}
      </Topics>
    </SwapTab>
    <ContainerAllDetail>
      {swap === 'graph' && <ShowGraph />}
      {swap === 'detail' && <ShowPortDetail />}
      {swap === 'assessCoverage' && <ShowAssessCoverage />}
      <PortDetail
        portData={portData}
        isShowModal={isShowModal}
        setIsShowModal={setIsShowModal}
      />
    </ContainerAllDetail>
  </>
)

const ShowAssessCoverage = ({}) => <Container>AssessCoverage</Container>

const ShowGraph = ({}) => <Container>Graph</Container>

const PortDetail = ({portData, isShowModal, setIsShowModal}) => (
  <ContainerAllDetail>
    <Upper>
      <SubHeader>กรมธรรม์ที่ถืออยู่</SubHeader>
      <ButtonContainer>
        <CustomButton onClick={() => setIsShowModal(true)}>
          เพิ่มข้อมูลประกันที่ถืออยู่
        </CustomButton>
      </ButtonContainer>
    </Upper>

    <InsuranceCreatePortModal
      isShowModal={isShowModal}
      setIsShowModal={setIsShowModal}
    />
    {portData.map((value, index) => (
      <Port portData={value} />
    ))}
  </ContainerAllDetail>
)

const enhancer = compose(
  withState('isShowModal', 'setIsShowModal', false),
  withState('swap', 'setSwap', 'detail'),
  connect(
    state => ({portData: state.profile.port}),
    {getPort},
  ),
  lifecycle({
    componentDidMount() {
      const {getPort} = this.props
      getPort()
    },
  }),
)

export default enhancer(Coverage)
