import React from 'react'
import styled, {css} from 'react-emotion'
import {
  compose,
  withProps,
  withHandlers,
  withState,
  defaultProps,
  lifecycle,
} from 'recompose'
import {displayNumber, numberParser} from '../../../core/helper'
import {withField, withCaption} from '../enhancers/index'
import TextInput from '../../share/Field/TextInput'

const Input = styled(TextInput)`
  text-align: center;
`

const disabledStyled = css`
  ${'' /* opacity: 0.8; */}
  background-color: #e5e5e5;
`

const ReadOnlyInput = styled.div`
  text-align: right;
`

const CustomTextInput = props => (
  <>
    {props.readOnly ? (
      <ReadOnlyInput>{props.value}</ReadOnlyInput>
    ) : (
      <Input {...props} />
    )}
  </>
)

const enhancer = compose(
  withField(),
  withCaption(),
  withState('displayValue', 'setDisplayValue', props =>
    displayNumber(props.value),
  ),
  withHandlers({
    onFocus: props => e => {
      props.setDisplayValue(displayNumber(props.value))
      props.onFocus && props.onFocus(props.value)
    },
    onChange: props => e => {
      try {
        const currentToLastLength =
          e.target.value.length - e.target.selectionStart
        const element = e.target

        window.requestAnimationFrame(() => {
          element.selectionStart = element.value.length - currentToLastLength
          element.selectionEnd = element.value.length - currentToLastLength
        })
      } catch (e) {}

      let value = e.target.value ? numberParser(e.target.value) : null
      let prevValue = value
      if (value !== null && value !== undefined) {
        if (props.min) {
          value = Math.max(value, props.min)
        }
        if (props.max) {
          value = Math.min(value, props.max)
        }
        if (!value && value !== 0 && value !== '0') {
          value = null
        }
      }

      props.onChange && props.onChange(value)

      if (prevValue !== value) {
        props.setDisplayValue(value)
      } else if (/^0(\d)$/.test(e.target.value)) {
        const [_, digit] = e.target.value.match(/^0(\d)$/)
        props.setDisplayValue(digit)
      } else if (/^(0|[1-9][\d,]*)?(\.[\d]*)?$/.test(e.target.value)) {
        const [_, digit = '', precision = ''] = e.target.value.match(
          /^(0|[1-9][\d,]*)?(\.[\d]*)?$/,
        )
        const displayValue = `${displayNumber(digit)}${precision}`
        props.setDisplayValue(displayValue)
      }
    },
    onBlur: props => e => {
      props.setDisplayValue(displayNumber(props.value))
      props.onBlur && props.onBlur(props.value)
    },
  }),
  withProps(props => {
    let value = null
    if (props.active) {
      value = props.displayValue
    } else {
      value = displayNumber(props.value)
    }
    return {value}
  }),
)

export default enhancer(CustomTextInput)
