import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { navigate } from '@reach/router'
import { Popover } from 'antd'
import { isEqual } from 'lodash'
import React, { useEffect, useMemo } from 'react'
import styled from 'react-emotion'
import { connect } from 'react-redux'
import TimeAgo from 'react-timeago'
import { compose, lifecycle, withState } from 'recompose'
import { enableBodyScrollable } from '../../core/helper'
import { getNotification, logout } from '../../ducks/auth'
import { getFavorite } from '../../ducks/profile'
import logo from '../../static/images/Noon-Logo-PNG-512.png'
import userIcon from '../../static/images/avatar-02.svg'
import notificationLogo from '../../static/images/notification-default.jpg'
import Favorite from './Favorite'
import { useScrollPosition } from './userScrollPosition'

const NavbarBase = styled.nav`
  background: rgba(255, 255, 255, 0.8);
  color: #ffd900;
  a {
    color: #ffd900;
    text-decoration: none;
    font-weight: bold;
    padding-left: 15px;
  }
  line-height: 1;
  height: 80px;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 998;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  display: none;
  padding: 0 20px;
  @media (max-width: 1024px) {
    display: flex;
  }
`
const MenuListPart = styled.div`
  display: flex;
  margin-left: 0;
  align-items: center;
  justify-content: center;
  @media (max-width: 1024px) {
    width: calc(50% + 44.5px);
    justify-content: space-between;
  }
`
const Navbar = styled(NavbarBase)`
  visibility: visible;
  transition: all 200ms ease-in;
  @media (max-width: 425px) {
    visibility: ${props => (props.show ? 'visible' : 'hidden')};
    transition: all 200ms ${props => (props.show ? 'ease-in' : 'ease-out')};
    transform: ${props => (props.show ? 'none' : 'translate(0, -100%)')};
  }
`
const MenuListIcon = styled(FontAwesomeIcon)`
  margin: 0 5px;
  font-size: 30px;
  color: #ffd900;
  cursor: pointer;
`
const Logo = styled.img`
  margin-top: 5px;
  width: 85px;
  height: 85px;

  cursor: pointer;
  object-fit: cover;
`
const MenuContainer = styled.div`
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;

  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.9);

  transform: ${props =>
    props.menuListActive ? 'translateX(0)' : 'translateX(-100vw)'};
  transition: transform 0.5s;
`
const ProfileContainer = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 999;

  transform: ${props =>
    props.menuLoginActive ? 'translateX(0)' : 'translateX(100vw)'};
  transition: transform 0.5s;
`
const InnerContainer = styled.div`
  width: 384px;
  background-color: #265fa7;
  height: 100vh;
  transition: 0.5s;
  padding-top: 40px;
  @media (max-width: 375px) {
    width: 90%;
  }
`
const InnerProfile = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 384px;
  background-color: #265fa7;
  height: 100vh;
  padding-top: 20px;
  @media (max-width: 375px) {
    width: 90%;
  }
`
const MenuUser = styled.div`
  display: flex;
  align-items: center;
  padding: 0 96px;
  @media (max-width: 375px) {
    padding: 0 60px;
  }
`
const MenuLink = styled.a`
  width: fit-content;
  padding: 0 96px;
  display: flex;
  height: 50px;
  font-size: 16px;
  color: white;
  font-weight: 500;
  align-items: center;
  cursor: pointer;
  :hover {
    color: white;
    opacity: 1;
  }
  @media (max-width: 375px) {
    padding: 0 60px;
  }
`
const CustomLink = styled.a`
  width: fit-content;
  display: flex;
  font-size: 16px;
  color: white;
  height: 50px;
  font-weight: 500;
  align-items: center;
  cursor: pointer;

  :hover {
    color: white;
    opacity: 1;
  }
`
const Separator = styled.span`
  margin: 0 10px;
  font-size: 16px;
  color: white;
`
const CollapseFieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
`
const CollapseHeader = styled.div`
  display: flex;
  cursor: pointer;
  height: 50px;
  padding: 0 96px;
  background: ${props => (props.ismenuactive ? '#0e4791' : '')};
  align-items: center;
  @media (max-width: 375px) {
    padding: 0 60px;
  }
`
const CollapseTitle = styled.a`
  font-size: 16px;
  font-weight: 500;
  color: white;
  margin-right: 5px;
`
const ChevronUpIcon = styled(FontAwesomeIcon)`
  font-size: 20px;
  font-weight: 500;
  cursor: pointer;
  color: white;
  margin-left: 30px;
`
const CollapseChilds = styled.div`
  height: ${props => (props.isMenuActive ? '100%' : '0')};
  transform: ${props => (props.isMenuActive ? 'scaleY(1)' : 'scaleY(0)')};
  transform-origin: center top;
  transition: height 0.25s, transform 0.25s ease-in-out;
  overflow: ${props => (props.isMenuActive ? 'hidden' : 'unset')};
`
const List = styled.a`
  height: 50px;
  background: #093771;
  padding-left: 130px;
  display: flex;
  align-items: center;
  color: white;
  font-size: 16px;
  font-weight: 500;
  @media (max-width: 375px) {
    padding-left: 80px;
  }
`
const UserIcon = styled.img`
  margin-left: 12px;
  width: 40px;
  height: 40px;

  border-radius: 50%;
  object-fit: cover;

  cursor: pointer;

  &.userLoginIcon {
    margin-left: 0;
  }
`
const CustomPopover = styled(Popover)`
  display: flex;
  align-items: center;
`
const WrapActive = styled.div`
  border-bottom: 2px solid;
  opacity: ${props => (props.active ? '1' : '0.5')};
  color: ${props => (props.active ? '1' : '0.5')};
  font-weight: ${props => (props.active ? '600' : '400')};
  flex: 1;
  padding-bottom: 10px;
  justify-content: center;
  height: 50px;
  align-items: center;
  display: flex;
`
const Username = styled.div`
  margin-left: 10px;
  margin-right: 20px;
  font-size: 16px;
  font-weight: bold;

  &.userLoginUsername {
    margin-right: 0;
    color: #ffffff;
    opacity: ${props => (props.menuActive === 0 ? '1' : '0.5')};
  }
  cursor: pointer;

  @media (max-width: 375px) {
    font-size: 12px;
    display: ${props =>
      props.menuActive === 0 || props.menuActive === 1 || props.menuActive === 2
        ? 'unset'
        : 'none'};
  }
`
const CustomIcon = styled(FontAwesomeIcon)`
  font-size: 20px;
  cursor: pointer;
  &.heartIcon {
    display: unset;
  }
`
const HeaderProfile = styled.div`
  display: flex;
  align-items: center;
  color: #ffffff;
`
const BodyProfile = styled.div`
  color: #ffffff;
  font-size: 16px;
`
const UserMenu = styled.div`
  display: ${props => (props.active ? 'block' : 'none')};
  color: white;
  font-size: 16px;
  margin-top: 20px;
`
const WrapNotification = styled.div`
  display: ${props => (props.active ? 'block' : 'none')};
  padding-top: 20px;
  padding-bottom: 10px;
  overflow-y: scroll;
  max-height: calc(100vh - 70px);
`
const WrapFavorite = styled.div`
  display: ${props => (props.active ? 'block' : 'none')};
`
const UserProfilePart = styled.div`
  cursor: pointer;
  height: 50px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 40px;
  background: ${props => props.active && '#265fa7'};

  :hover {
    opacity: 1;
  }
`
const UserProfileIcon = styled(FontAwesomeIcon)`
  margin-right: 12px;
  min-width: 20px;
  font-size: 16px !important;
`
const UserLogoutPart = styled(UserProfilePart)``
const CustomNotificationContainer = styled.div`
  display: flex;
  align-items: center;
  min-height: 60px;
`
const NotificationContentIcon = styled.img`
  margin-left: 21px;
  width: 30px;
  height: 30px;

  border-radius: 50%;
`
const NotificationTopic = styled.span`
  font-size: 12px;
  white-space: normal;
  @media (max-width: 425) {
    width: 260px;
  }
  @media (max-width: 375px) {
    width: 200px;
  }
  @media (max-width: 320px) {
    width: 180px;
  }
`
const NotificationLastUpdate = styled.span`
  font-size: 8px;
`
const CustomNotificationCententContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 19px;
`
const SadTearIcon = styled(FontAwesomeIcon)`
  font-size: 48px;
  margin-bottom: 10px;
  color: white;
  opacity: 0.6;
`
const NottingText = styled.div`
  font-size: 12px;
  color: white;
  text-align: center;
  opacity: 0.6;
`
const NottingContainer = styled.div`
  display: flex;
  justify-content: center;
`
const NottingContent = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 30.5px;
`
const TimeAgoStyled = styled(TimeAgo)`
  font-size: 12px;
`
const HeaderMenuContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`
const LoginAndRegisterContainer = styled.div`
  display: flex;
  align-items: center;
`
const LoginAndRegisterPart = styled.div`
  display: flex;
  background-color: #ffb933;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 30px;
  border-radius: 20px;
  color: #333333;
  @media (max-width: 425px) {
    width: 70px;
  }
`
const Line = styled.div`
  margin: 0 5px;
  padding-bottom: 1px;
  @media (max-width: 425px) {
    display: none;
  }
`
const MenuContent = styled.div`
  font-size: 12px;
  margin: 0 8px;

  cursor: pointer;
  &.mobileCustom {
    @media (max-width: 425px) {
      display: none;
    }
  }

  &.login,
  &.mobileCustom {
    margin: 0;
  }
`
const Div = styled.div``
const Span = styled.span``

const ResponsiveMenu = props => {
  const {
    menus,
    isLogin,
    getFavorite,
    user,
    notification,
    logout,
    profileMenus,
  } = props
  const {
    hideOnScroll,
    setHideOnScroll,
    isActive,
    setActive,
    isMenuActive,
    setMenuActive,
    isProfileActive,
    setProfileActive,
    active,
    setLoginActive,
  } = props

  useEffect(() => {
    if (isLogin) {
      getFavorite()
      props.getNotification()
      clearInterval()
      setInterval(() => props.getNotification(), 300000)
    }
  }, [isLogin, user])

  useScrollPosition(
    ({ prevPos, currPos }) => {
      const isShow =
        currPos.y > prevPos.y ||
        currPos.y === 0 ||
        currPos.y > 0 ||
        prevPos.y > 0
      if (isShow !== hideOnScroll) setHideOnScroll(isShow)
    },
    [hideOnScroll],
    null,
    false,
    300
  )

  return useMemo(
    () => (
      <>
        <Navbar key="ResponsiveMenu" show={hideOnScroll}>
          <MenuListPart>
            <MenuListIcon
              key="bar"
              icon={['fa', 'bars']}
              onClick={() => setActive(true)}
            />
            <Logo id="noon-logo" src={logo} onClick={() => navigate('/')} />
          </MenuListPart>
          {isLogin ? (
            <UserIcon
              src={user.avatar || userIcon}
              onClick={() => setProfileActive(true)}
            />
          ) : (
            <HeaderMenuContainer>
              {!isLogin && (
                <LoginAndRegisterContainer>
                  <LoginAndRegisterPart>
                    <MenuContent
                      className="login"
                      onClick={() => navigate(`/authorize/login`)}
                    >
                      เข้าสู่ระบบ
                    </MenuContent>
                    <Line className="mobileCustom">|</Line>
                    <MenuContent
                      className="mobileCustom"
                      onClick={() => navigate(`/authorize/register`)}
                    >
                      สมัคร
                    </MenuContent>
                  </LoginAndRegisterPart>
                </LoginAndRegisterContainer>
              )}
            </HeaderMenuContainer>
          )}
        </Navbar>
        <MenuContainer
          className="ipad-header"
          onClick={event => {
            if (!(event.target.id && event.target.id === 'profile-menu-ipad')) {
              setActive(false)
              setMenuActive(true)
            }
          }}
          menuListActive={isActive}
        >
          <InnerContainer menuListActive={isActive}>
            {menus.map((menu, i) => (
              <Div key={`wrapper-menu-${i}`}>
                {menu.submenu ? (
                  <CollapseFieldWrapper key={`menu-${i}`}>
                    <CollapseHeader ismenuactive={isMenuActive}>
                      <CollapseTitle href={menu.link}>
                        {menu.title}
                      </CollapseTitle>
                      <ChevronUpIcon
                        id="profile-menu-ipad"
                        onClick={event => {
                          setMenuActive(!isMenuActive)
                          event.stopPropagation()
                        }}
                        icon={
                          isMenuActive
                            ? ['fas', 'caret-up']
                            : ['fas', 'caret-down']
                        }
                      />
                    </CollapseHeader>
                    <CollapseChilds isMenuActive={isMenuActive}>
                      {menu.submenu.map((sub, index) => {
                        return (
                          <List
                            id={sub.id}
                            key={`sub-${index}`}
                            href={sub.link}
                          >
                            {sub.title}
                          </List>
                        )
                      })}
                    </CollapseChilds>
                  </CollapseFieldWrapper>
                ) : menu.link === '/square' ? (
                  <MenuLink id={menu.id} key={i} href="/blog">
                    {menu.title}
                  </MenuLink>
                ) : (
                  <MenuLink id={menu.id} key={i} href={menu.link}>
                    {menu.title}
                  </MenuLink>
                )}
              </Div>
            ))}
            {!isLogin && (
              <MenuUser>
                <CustomLink href="/authorize/login">เข้าสู่ระบบ</CustomLink>
                <Separator> | </Separator>
                <CustomLink href="/authorize/register">สมัคร</CustomLink>
              </MenuUser>
            )}
          </InnerContainer>
        </MenuContainer>
        <ProfileContainer
          menuLoginActive={isProfileActive}
          id="profile-container"
          onClick={event => {
            if (event.target.id && event.target.id === 'profile-container') {
              setProfileActive(false)
            }
          }}
        >
          <InnerProfile>
            <HeaderProfile>
              <WrapActive key="user" active={active === 'user'}>
                <CustomPopover onClick={() => setLoginActive('user')}>
                  <UserIcon src={user.avatar || userIcon} />
                  <Username>{user.firstNameTh}</Username>
                </CustomPopover>
              </WrapActive>
              <WrapActive key="bell" active={active === 'bell'}>
                <CustomIcon
                  icon={['far', 'bell']}
                  onClick={() => setLoginActive('bell')}
                />
              </WrapActive>
              <WrapActive key="heart" active={active === 'heart'}>
                <CustomIcon
                  icon={['far', 'heart']}
                  onClick={() => setLoginActive('heart')}
                />
              </WrapActive>
            </HeaderProfile>
            <BodyProfile>
              <UserMenu active={active === 'user'}>
                {profileMenus.map((menu, index) => (
                  <UserProfilePart
                    className={menu.className.split(' ')[1] || ''}
                    key={index}
                  >
                    <UserProfileIcon icon={['fas', menu.icon]} />
                    <Span
                      onClick={() => {
                        navigate(menu.link)
                        setProfileActive(false)
                      }}
                    >
                      {menu.title}
                    </Span>
                  </UserProfilePart>
                ))}
                <UserLogoutPart className="logout" onClick={() => logout()}>
                  <UserProfileIcon icon={['fas', 'sign-out-alt']} />
                  <Span>ออกจากระบบ</Span>
                </UserLogoutPart>
              </UserMenu>
              <WrapNotification active={active === 'bell'}>
                {notification.length > 1 ? (
                  notification.map(value => (
                    <CustomNotificationContainer
                      key={value.id}
                      className="notificationContent"
                      onClick={() =>
                        window.open(`/blog/details/${value.id}`, '_blank')
                      }
                    >
                      <NotificationContentIcon
                        src={value.avatar || notificationLogo}
                      />
                      <CustomNotificationCententContainer>
                        <NotificationTopic>{value.topicName}</NotificationTopic>
                        <NotificationLastUpdate>
                          <TimeAgoStyled date={value.createdAt} />
                        </NotificationLastUpdate>
                      </CustomNotificationCententContainer>
                    </CustomNotificationContainer>
                  ))
                ) : (
                  <NottingContainer>
                    <NottingContent>
                      <SadTearIcon icon={['fas', 'sad-tear']} />
                      <NottingText>ไม่มีอะไรอัพเดต</NottingText>
                    </NottingContent>
                  </NottingContainer>
                )}
              </WrapNotification>
              <WrapFavorite active={active === 'heart'}>
                <Favorite />
              </WrapFavorite>
            </BodyProfile>
          </InnerProfile>
        </ProfileContainer>
      </>
    ),
    [
      hideOnScroll,
      isActive,
      isMenuActive,
      isProfileActive,
      active,
      notification,
    ]
  )
}

const enhancer = compose(
  connect(
    state => ({
      user: state.auth.user,
      isLogin: state.auth.isLogin,
      notification: state.auth.notification,
      articles: state.profile.articles || [],
      insurancePolicies: state.profile.insurancePolicies || [],
    }),
    { getFavorite, logout, getNotification }
  ),
  withState('hideOnScroll', 'setHideOnScroll', true),
  withState('isActive', 'setActive', false),
  withState('isMenuActive', 'setMenuActive', true),
  withState('isProfileActive', 'setProfileActive', false),
  withState('active', 'setLoginActive', 'user'),
  lifecycle({
    componentWillReceiveProps(nextProps) {
      if (!isEqual(this.props.isProfileActive, nextProps.isProfileActive)) {
        enableBodyScrollable(nextProps.isProfileActive)
      }
    },
  })
)
export default enhancer(ResponsiveMenu)
