import React from 'react'
import styled from 'react-emotion'
import { compose, withHandlers } from 'recompose'
import taxBannerDesktop from '../assets/images/Tax_Banner_63_Desktop.png'
import taxBannerMobile from '../assets/images/Tax_Banner_63_Mobile.png'
import taxBannerBackground from '../assets/images/Tax_Banner_63_Background.png'
import rdlogoImage from '../assets/images/rd-logo.svg'
import rdSmartTaxImage from '../assets/images/logo-rd-smart-tax.jpg'
import instructionImage from '../assets/images/instruction.png'
import instructionImage1 from '../assets/images/instruction1.png'
import instructionImage2 from '../assets/images/instruction2.png'
import instructionImage3 from '../assets/images/instruction3.png'
import { Button, SpidusManager } from '../components'
import ServiceCard from './ServiceCard'
import BenefitCard from './BenefitCard'
import * as paths from '../common/paths'
import { withRouter } from '../enhances'
import Slider from 'react-slick'

const Layout = styled(SpidusManager)`
  position: relative;
  min-width: 300px;
  overflow: auto;
`
const BackgroundHeader = styled.img`
  position: absolute;
  z-index: 0;

  width: 100%;
  height: 366px;
`
const Header = styled.div`
  position: relative;

  height: 366px;
  display: flex;
  padding: 10px 0px;
  justify-content: center;
  align-items: center;
  background-image: url("${taxBannerDesktop}");
  background-repeat: no-repeat;
  background-size: auto calc(100% + 35px);
  background-position-y: -35px;
  background-position-x: calc(50%);

  @media screen and (max-width: 680px) {
    background-image: url("${taxBannerMobile}");
    background-position-x: calc(50%);
  }

  @media screen and (min-width: 2050px) {
    background-size: cover;
    background-position-x: center;
    background-position-y: -35px;
  }
`

const TitleLayout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 335px;
  max-width: 438px;
  min-height: 265px;
  padding: 52px 0px 30px 0px;
  margin-left: 238px;

  @media screen and (max-width: 680px) {
    max-width: 350px;
    margin-left: 0;
    background: #ffffff;
    border-radius: 5px;
    opacity: 0.9;
  }

  @media screen and (max-width: 350px) {
    border-radius: 0px;
  }
`

const HeaderTitle = styled.div`
  font-family: Prompt;
  font-weight: bold;
  font-size: 20px;
  color: #333333;
  text-align: center;
`

const DescriptionLayout = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 12px 20px 12px;
`

const RdLogo = styled.img`
  height: 60px;
`

const Message = styled.div`
  margin-left: 20px;
  font-family: Prompt;
  font-size: 16px;
  color: #7f7f7f;

  @media screen and (max-width: 680px) {
    color: #333333;
  }
`

const PrepareTaxButton = styled(Button)`
  width: 220px;
  height: 50px;
  margin-bottom: 20px;

  background: #ffb933;
  border-radius: 40px;
  font-size: 20px;
  font-family: Prompt;
  color: #333333;
`

const NoteTitle = styled.span`
  display: grid;

  font-family: Prompt;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: #7f7f7f;
`

const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px 10px 85px 10px;
`

const BodyTitle = styled.div`
  font-size: 20px;
  font-family: Prompt;
  font-weight: bold;
  color: #333333;
  text-align: center;
`

const InstructionLayout = styled.div`
  margin-top: 28px;
  text-align: center;
  max-width: 636px;
  width: 100%;
`

const StepImage = styled.img`
  width: 100%;
  max-width: 636px;

  @media screen and (max-width: 720px) {
    display: none;
  }
`

const BenefitLayout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 85px;
`

const BenefitBody = styled.div`
  > div:nth-child(1) {
    margin-top: 34px;
  }
  > div:nth-child(2) {
    margin-top: 54px;
  }

  @media screen and (min-width: 680px) {
    flex-direction: row;
    width: 100%;
    display: flex;
    align-items: flex-start;
    margin-top: 60px;

    > div:nth-child(1) {
      margin-top: 0px;
      flex: 1;
    }
    > div:nth-child(2) {
      margin-top: 0px;
      flex: 1;
    }
  }
`

const OtherServiceLayout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 80px;
`

const OtherServiceBody = styled.div`
  > div:nth-child(1) {
    margin-top: 28px;
  }
  > div:nth-child(2) {
    margin-top: 20px;
  }
`

const Remark = styled.div`
  margin-top: 15px;
  text-align: center;
  font-size: 12px;
  font-family: 'Prompt', sans-serif;
  color: #7f7f7f;
`

const BodyTitle1 = styled(BodyTitle)`
  @media screen and (max-width: 500px) {
    max-width: 230px;
  }
`

const BodySubTitle = styled.div`
  text-align: center;
  font-family: 'Prompt', sans-rerif;
  font-size: 14px;
  color: #333333;
  margin-top: 10px;
  margin-bottom: 30px;
  max-width: 420px;
`

const BodyDescription = styled.div`
  text-align: left;
  color: #333333;
  font-size: 14px;
  margin-bottom: 42px;
  max-width: 700px;
`

const BodySolution = styled.div`
  background: #eeeeee;
  border-radius: 5px;
  padding: 14px;
  max-width: 500px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-family: 'Prompt', sans-serif;
  color: #333333;
  margin-bottom: 84px;
  text-align: center;
  min-height: 40px;

  @media screen and (max-width: 520px) {
    flex-direction: column;
  }
`

const BodySolutionRDLogo = styled.img`
  width: 40px;
  height: 40px;
  margin: 0 8px;
  border-radius: 50%;

  @media screen and (max-width: 520px) {
    flex-direction: column;
    margin: 8px;
  }
`

const SliderImageLayout = styled.div``

const SliderImage = styled.img`
  min-height: 320px;
  margin: 0 auto;
  margin-bottom: 20px;
`

const SliderWrapper = styled.div`
  display: none;

  .slick-dots {
    button::before {
      font-size: 11px;
    }
  }

  @media screen and (max-width: 720px) {
    display: block;
  }
`
const MoreInfoButton = styled(PrepareTaxButton)`
  width: 338px;
  height: 35px;
  font-size: 18px;
  margin-top: 34px;
`

const Landing = props => (
  <Layout configName="noontax_config_web_prepare_tax">
    <BackgroundHeader src={taxBannerBackground} />
    <Header>
      <TitleLayout>
        <HeaderTitle>เตรียมข้อมูลยื่นภาษีที่นี่ EASY กว่าที่เคย</HeaderTitle>
        <DescriptionLayout>
          <RdLogo src={rdlogoImage} />
          <Message>
            ผู้ให้บริการเตรียมข้อมูลภาษีออนไลน์
            <br />
            ได้รับการอนุญาตจาก กรมสรรพากร
          </Message>
        </DescriptionLayout>
        <PrepareTaxButton
          id="start-tax-preparation-button"
          onClick={props.gotoPrepareTax}
        >
          เตรียมข้อมูลภาษี
        </PrepareTaxButton>
      </TitleLayout>
    </Header>
    <Body>
      <BodyTitle1>เตรียมข้อมูลยื่นภาษีง่าย ยื่นไว ได้ลดหย่อนครบ</BodyTitle1>
      <BodySubTitle>
        noon tax ช่วยอำนวยความสะดวกจัดการเอกสารข้อมูลภาษีปี 63 ฟรี
        ภายใต้กฎหมายและการรับรองจากกรมสรรพากร
      </BodySubTitle>
      <BodyDescription>
        ช่วยให้คุณ<b>ยื่นภาษีออนไลน์</b>บุคคลธรรมดา (
        <a href="https://bit.ly/3aGSrbS" target="__blank">
          ภ.ง.ด. 91
        </a>
        ) ได้อย่างสะดวกสบาย และครบตามสิทธิประโยชน์ลดหย่อนสูงสุด
        เพียงแค่กรอกข้อมูลไม่กี่ขั้นตอนผ่านเว็บไซต์ของเราโดยไม่ต้องใช้แอปพลิเคชั่นใดให้ยุ่งยาก
        แล้วนำรหัสความปลอดภัย 6 หลัก ไปกรอกที่ปุ่ม{' '}
        <b>"นำเข้าข้อมูลจากผู้ให้บริการ"</b> หน้ายื่นภาษีของ RD Smart Tax
        Application ข้อมูลภาษีของคุณจะถูกป้อน เข้าไปอัตโนมัติ แล้วกดยื่นภาษีที่
        Application ของสรรพากรได้ทันที
      </BodyDescription>
      <BodySolution>
        กรุณานำรหัส 6 หลักไปกรอกในแอปฯ RD Smart Tax
        <BodySolutionRDLogo src={rdSmartTaxImage} />
        เพื่อยื่นภาษีต่อไป
      </BodySolution>

      <BodyTitle>ขั้นตอนการเตรียมยื่นภาษีออนไลน์</BodyTitle>
      <InstructionLayout>
        <StepImage src={instructionImage} />
        <SliderWrapper>
          <Slider dots={true} infinite={false}>
            <SliderImageLayout>
              <SliderImage src={instructionImage1} />
            </SliderImageLayout>
            <SliderImageLayout>
              <SliderImage src={instructionImage2} />
            </SliderImageLayout>
            <SliderImageLayout>
              <SliderImage src={instructionImage3} />
            </SliderImageLayout>
          </Slider>
        </SliderWrapper>
      </InstructionLayout>
      <a href="https://bit.ly/2DTtH3e">
        <MoreInfoButton
          onClick={() => dataLayer.push({ event: 'go_to_tax_blog' })}
        >
          ดูรายละเอียดเพิ่มเติม
        </MoreInfoButton>
      </a>
      <BenefitLayout>
        <BodyTitle>ประโยชน์ที่ได้รับจากบริการเรา</BodyTitle>
        <BenefitBody>
          <BenefitCard icon="cog" description="ความถูกต้องในการคำนวณภาษี" />
          <BenefitCard
            icon="folder-open"
            description="รองรับการเตรียมข้อมูลภาษีบุคคลธรรมดา 40(1) ตามมาตรฐานกรมสรรพากร"
          />
        </BenefitBody>
      </BenefitLayout>
      <OtherServiceLayout>
        <BodyTitle>บริการอื่นๆ ที่เกี่ยวข้อง</BodyTitle>
        <OtherServiceBody>
          <ServiceCard
            id="goto-insure-products-button"
            icon="calculator"
            title="เลือกซื้อประกันเพื่อลดหย่อนภาษี"
            description="ซื้อประกันชีวิต ประกันสุขภาพตามความต้องการของคุณ ซื้อสบาย พร้อมให้คำปรึกษา เพื่อนำไปใช้ลดหย่อนภาษีได้สูงสุด"
            to={paths.lifeInsuranceProductsPath()}
            onClick={() =>
              dataLayer.push({ event: 'goto_insure_products_page' })
            }
          />
          {/* <ServiceCard
            id="goto-fund-products-button"
            icon="coins"
            title="ค้นหากองทุนเพื่อลดหย่อนภาษี"
            description="มองหากองทุน ศึกษากองทุนในแบบที่คุณต้องการ พร้อมให้คำปรึกษา เพื่อใช้ลดหย่อนภาษีสูงสุด"
            to={paths.fundProductsPath()}
            onClick={() => dataLayer.push({ event: 'goto_fund_products_page' })}
          /> */}
        </OtherServiceBody>
      </OtherServiceLayout>
    </Body>
  </Layout>
)

const enhancer = compose(
  withRouter(),
  withHandlers({
    gotoPrepareTax: props => async () => {
      dataLayer.push({ event: 'start_tax_preparation' })

      // if (!JSON.parse(localStorage.getItem(getNoonTaxStorageKey()))) {
      //   await spidusStore.loadAnswers('noontax_config_web_prepare_tax', '$all')
      // }

      props.history.push(paths.prepareTaxPath())
    },
  })
)

export default enhancer(Landing)
