import { put, takeLatest } from 'redux-saga/effects'
import { API_HOST, httpDelete, httpGet, httpPost } from '../../core/httpClient'
import { Creator, createReducer } from '../helper'

const SET_MY_POLICY_DATA = 'SET_MY_POLICY_DATA'
const FETCH_MY_POLICY = 'FETCH_MY_POLICY'
const SCRAP_MY_POLICY = 'SCRAP_MY_POLICY'
const DELETE_MY_POLICY = 'DELETE_MY_POLICY'

export const setData = Creator(SET_MY_POLICY_DATA, 'data')
export const fetchMyPolicy = Creator(FETCH_MY_POLICY, 'cb')
export const scrapMyPolicy = Creator(SCRAP_MY_POLICY, 'link', 'cb')
export const deleteMyPolicy = Creator(DELETE_MY_POLICY, 'policyNo', 'cb')

export function* fetchMyPolicySaga({
  payload: { cb: { onSuccess = () => {}, onFailure = () => {} } = {} },
}) {
  try {
    yield put(setData({ isLoading: true }))
    const url = `${API_HOST}my_policies`
    const response = yield httpGet(url)
    yield put(setData({ policyList: response.data.data.policies }))
    onSuccess(response.data.data)
  } catch (error) {
    console.error('error', error)
    onFailure()
  } finally {
    yield put(setData({ isLoading: false }))
  }
}

export function* scrapMyPolicySaga({
  payload: {
    link = '',
    cb: { onSuccess = () => {}, onFailure = () => {} } = {},
  },
}) {
  try {
    yield put(setData({ isLoading: true }))
    const url = `${API_HOST}my_policies/scraping_oic_policy`
    yield httpPost(url, { link })
    onSuccess()
  } catch (error) {
    console.error('error', error)
    onFailure()
  } finally {
    yield put(setData({ isLoading: false }))
  }
}

export function* deleteMyPolicySaga({
  payload: {
    policyNo = '',
    cb: { onSuccess = () => {}, onFailure = () => {} } = {},
  },
}) {
  try {
    yield put(setData({ isLoading: true }))
    const url = `${API_HOST}my_policies/${policyNo}`
    yield httpDelete(url)
    onSuccess()
  } catch (error) {
    console.error('error', error)
    onFailure()
  } finally {
    yield put(setData({ isLoading: false }))
  }
}

export function* watchMyPolicySaga() {
  yield takeLatest(FETCH_MY_POLICY, fetchMyPolicySaga)
  yield takeLatest(SCRAP_MY_POLICY, scrapMyPolicySaga)
  yield takeLatest(DELETE_MY_POLICY, deleteMyPolicySaga)
}

const initial = {
  isLoading: false,
  policyList: [],
}

export default createReducer(initial, state => ({
  [SET_MY_POLICY_DATA]: ({ data }) => ({ ...state, ...data }),
}))
