import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from '@reach/router'
import React from 'react'
import styled, { css } from 'react-emotion'
import { compose } from 'recompose'

import Banner from '../../static/images/Banner.png'
import BannerMobile from '../../static/images/BannerMobile.jpg'
import Navigator from './Navigator'

import { TQM_SHOP_LINK } from '../../core/helper'
import { responsive } from '../../core/style'
import withSEO from '../share/withSEO'

const NoonSmartWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Container = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 80px;
  left: calc(50%);
  width: fit-content;

  ${props =>
    responsive.tablet(css`
      left: calc(40%);
    `)};

  ${props =>
    responsive.mobile(css`
      top: 10%;
    `)};
`

const NoonSmartHeader = styled.div`
  font-size: 57px;
  font-weight: bold;
  color: white;

  ${props =>
    responsive.mobile(css`
      font-size: 27px;
    `)};
`

const NoonSubSmartHeader = styled.div`
  font-size: 37px;
  font-weight: bold;
  color: white;
  margin-bottom: 24px;

  ${props =>
    responsive.mobile(css`
      font-size: 9px;
    `)};
`

const CategoryContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  ${props =>
    responsive.mobile(css`
      margin-top: 21px;
      margin-bottom: 40px;
    `)};
`

const CategoryIcon = styled(Link)`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 370px;
  height: 70px;
  background-color: #6a94cb;
  border-radius: 5px;
  margin: 10px 0;
  color: white;
  overflow: hidden;
  padding-left: 75px;

  &.CustomLifeCategory {
    padding-left: 71px;
  }

  :hover {
    background-color: #265fa7;
    color: white;
  }

  ${props =>
    responsive.mobile(css`
      width: 170px;
      height: 35px;
      margin: 5px 0;

      padding-left: 26px;
      background-color: #265fa7;

      &.CustomLifeCategory {
        padding-left: 23px;
      }
    `)};
`
const CustomLink = styled.a`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 370px;
  height: 70px;
  background-color: #6a94cb;
  border-radius: 5px;
  margin: 10px 0;
  color: white;
  overflow: hidden;
  padding-left: 75px;

  &.CustomLifeCategory {
    padding-left: 71px;
  }

  :hover {
    background-color: #265fa7;
    color: white;
  }

  ${props =>
    responsive.mobile(css`
      width: 170px;
      height: 35px;
      margin: 5px 0;

      padding-left: 26px;
      background-color: #265fa7;

      &.CustomLifeCategory {
        padding-left: 23px;
      }
    `)};
`

const CustomIcon = styled(FontAwesomeIcon)`
  font-size: 30px;
  z-index: 1;

  ${props =>
    responsive.mobile(css`
      font-size: 20px;
    `)};
`

const CategoryText = styled.div`
  font-size: 30px;
  margin-left: 25px;
  z-index: 1;

  ${props =>
    responsive.mobile(css`
      font-size: 16px;
      margin-left: 8px;
    `)};
`

const NoonSmartBanner = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  background-image: url(${Banner});
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-position-x: center;
  overflow: scroll;
  ${props =>
    responsive.mobile(css`
      background-image: url(${BannerMobile});
      height: calC(100vh - 80px);
      background-position-x: calc(50% - 80px);
    `)};
`

const CustomNavigator = styled(Navigator)`
  margin-bottom: 24px;
`

const Category = [
  {
    key: 'life',
    name: 'ประกันชีวิต',
    link: '/smart/InsuranceCategory/life',
    icon: ['fas', 'user-shield'],
  },
  {
    key: 'health',
    name: 'ประกันสุขภาพ',
    link: '/smart/InsuranceCategory/health/intermediate',
    icon: ['fas', 'briefcase-medical'],
  },
  {
    key: 'motor',
    name: 'ประกันรถยนต์',
    link: TQM_SHOP_LINK,
    isExternal: true,
    icon: ['fas', 'car'],
  },
]

const NoonSmart = () => (
  <NoonSmartWrapper>
    <NoonSmartBanner>
      <Container>
        <NoonSmartHeader>NOON SMART</NoonSmartHeader>
        <NoonSubSmartHeader>แบบประกันที่ใช่ ใครๆ ก็ชอบ</NoonSubSmartHeader>
        <CustomNavigator id="noon-smart-insurance-back" />
        <CategoryContainer>
          {Category.map(cat => {
            if (cat.isExternal) {
              return (
                <CustomLink
                  id={`noon-smart-insurance-${cat.key}`}
                  key={cat.key}
                  href={cat.link}
                >
                  <CustomIcon icon={cat.icon} />
                  <CategoryText>{cat.name}</CategoryText>
                </CustomLink>
              )
            }
            return (
              <CategoryIcon
                id={`noon-smart-insurance-${cat.key}`}
                key={cat.key}
                to={cat.link}
              >
                <CustomIcon icon={cat.icon} />
                <CategoryText>{cat.name}</CategoryText>
              </CategoryIcon>
            )
          })}
        </CategoryContainer>
      </Container>
    </NoonSmartBanner>
  </NoonSmartWrapper>
)

const enhancer = compose(
  withSEO(
    'เริ่มค้นหาแบบประกันภัย - ประกันภัยที่ใช่ตามไลฟ์สไตล์คุณ',
    'noon Smart แหล่งข้อมูลของรายละเอียดทุกแบบประกันรวมไว้ให้คุณที่นี่ ให้คุณได้เช็คราคาและศึกษารายละเอียดก่อนตัดสินใจ',
    'เลือกซื้อประกันประกันชีวิต,ประกันสุขภาพ,ประกันรถยนต์ ระบบแนะนำประกัน ผลิตภัณฑ์ประกันภัยที่ครบถ้วน'
  )
)

export default enhancer(NoonSmart)
