import React from 'react'
import styled, { css } from 'react-emotion'
import { compose, lifecycle, withState } from 'recompose'

import { responsive } from '../../../core/style'
import Modal from '../../share/Modal'
import OICStep from './assets/images/oic.png'
import OICModalStep from './assets/images/oic_modal.png'
import { Breadcrumb, Button } from './components'

const FirstTimeOIC = ({ isShowModal = false, setIsShowModal = () => {} }) => {
  return (
    <React.Fragment>
      <Container>
        <Breadcrumb
          items={[
            { label: 'ข้อมูลของฉัน', link: '/profile/user-profile' },
            { label: 'ข้อมูลกรมธรรม์ คปภ.' },
          ]}
        />
        <Wrapper>
          <Topic>ข้อมูลกรมธรรม์ คปภ.</Topic>
          <SubTopic>
            เพื่อให้ตัวแทนของคุณสามารถทำสรุปกรมธรรม์ได้คุณสามารถแชร์ข้อมูล
            <br />
            กรมธรรม์ของคุณจากบริการกรมธรรม์ของฉันของคปภ. ได้ตามวิธีด้านล่างนี้
          </SubTopic>
          <Image
            src={OICStep}
            alt="show step to get your policy from oic line official"
            onClick={() => {
              setIsShowModal(true)
            }}
          />
          <ButtonContainer>
            <Button
              block
              size="large"
              type="secondary"
              title="แนบลิงก์"
              icon={['fas', 'link']}
              iconPosition="left"
              onClick={() => {
                window.location.reload()
                window.scrollTo(0, 0)
              }}
            />
          </ButtonContainer>
        </Wrapper>
      </Container>
      <CustomModal isShowModal={isShowModal} setIsShowModal={setIsShowModal}>
        <Image
          style={{ marginBottom: '0px' }}
          src={OICModalStep}
          alt="show step to get your policy from oic line official"
        />
      </CustomModal>
    </React.Fragment>
  )
}

const enhancer = compose(
  withState('isShowModal', 'setIsShowModal', false),
  lifecycle({
    componentDidMount() {
      if (typeof window !== undefined) {
        window.localStorage.setItem('NoonImportOICFirstTime', 'no')
      }
    },
  })
)

export default enhancer(FirstTimeOIC)

const Container = styled.div`
  width: 100%;
  margin-top: -30px;
`
const Wrapper = styled.div`
  width: 100%;
  max-width: 700px;
  margin: 0 auto;
`
const Topic = styled.div`
  margin-bottom: 8px;
  padding: 0 18px;
  font-size: 28px;
  font-weight: bold;
  line-height: 34px;
  text-align: center;
  color: #101820;
`
const SubTopic = styled.div`
  margin-bottom: 24px;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: #101820;
`
const Image = styled.img`
  display: block;
  width: 100%;
  margin: 0 auto 24px;
  cursor: pointer;
`
const ButtonContainer = styled.div`
  width: 100%;
  max-width: 388px;
  padding: 0 18px;
  margin: 0 auto 30px;
`
const CustomModal = styled(Modal)`
  &.ant-modal {
    width: unset !important;
    display: flex;
    justify-content: center;
    margin: 0 calc((100vw - 800px) / 2);
  }
  .ant-modal-content {
    width: 100%;
    padding: 45px 0 35px 0;
  }

  ${props =>
    responsive.mobile(css`
      &.ant-modal {
        width: 100% !important;
        margin: unset;
      }
    `)};
`
