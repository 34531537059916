import React from 'react'
import {compose} from 'recompose'
import styled, {css} from 'react-emotion'
import {Link} from '@reach/router'
import Banner from '../../../../static/images/Banner.png'
import BannerMobile from '../../../../static/images/BannerMobile.jpg'
import {responsive, fadeIn} from '../../../../core/style'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {reduxForm, Field} from 'redux-form'

import Navigator from '../../Navigator'

const NoonSmartWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`

const Container = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 80px;
  left: calc(50%);
  width: fit-content;

  ${props =>
    responsive.tablet(css`
      left: calc(40%);
    `)};

  ${props =>
    responsive.mobile(css`
      top: 10%;
    `)};
`

const NoonSmartHeader = styled.div`
  font-size: 57px;
  font-weight: bold;
  color: white;

  ${props =>
    responsive.mobile(css`
      font-size: 27px;
    `)};
`

const NoonSubSmartHeader = styled.div`
  font-size: 20px;
  line-height: 30px;
  font-weight: bold;
  color: white;
  margin-bottom: 24px;

  ${props =>
    responsive.mobile(css`
      font-size: 9px;
    `)};
`

const NoonSmartBanner = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  background-image: url(${Banner});
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-position-x: center;
  overflow: scroll;
  ${props =>
    responsive.mobile(css`
      background-image: url(${BannerMobile});
      height: calC(100vh - 80px);
      background-position-x: calc(50% - 80px);
    `)};
`

const InfoContainerBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  animation: ${fadeIn} 2s;

  ${props =>
    responsive.mobile(css`
      flex-direction: row;
      justify-content: flex-start;
      margin-left: 29px;
    `)};
`

const CustomIcon = styled(FontAwesomeIcon)`
  font-size: 40px;
  width: 40px;
  height: 41px;
  margin-bottom: 12px;

  ${props =>
    responsive.mobile(css`
      font-size: 16px;
      margin-bottom: 0;
    `)};
`

const Info = styled.div`
  font-size: 12px;

  ${props =>
    responsive.mobile(css`
      max-width: 81px;
      margin-left: 17px;
      white-space: pre;
    `)};
`

const BoxOnce = styled(Link)`
  border: none;
  display: flex;
  position: relative;
  width: 200px;
  height: 140px;
  margin: 5px;
  border-radius: 5px;
  background-color: ${props => (props.isSelected ? '#265FA7' : '#6A94CB')};
  color: white;
  overflow: hidden;
  cursor: pointer;
  align-items: center;

  :hover {
    background-color: #265fa7;
    color: white;
    .briefcase-medical,
    .procedures {
      color: rgba(178, 117, 0, 0.3);
    }
  }

  @media (max-width: 450px) {
    margin-top: 15px;
  }

  ${props =>
    responsive.mobile(css`
      width: 170px;
      height: 35px;
      background-color: #265fa7;
      margin: 10px 0;
    `)};
`
const ContainerBox = styled.div`
  display: flex;
  width: 420px;
  justify-content: space-between;
  margin-top: 30px;
  flex-wrap: wrap;
  @media (max-width: 450px) {
    flex-direction: column;
    align-items: center;
  }

  ${props =>
    responsive.mobile(css`
      flex-direction: column;
      width: 100%;
    `)};
`

const BoxList = [
  {
    iconTag: 'fas',
    iconName: 'umbrella',
    info: 'แบบตลอดชีพ',
    id: 'whole_life',
    analyticName: 'whole-life',
  },
  {
    iconTag: 'fas',
    iconName: 'coins',
    info: 'แบบสะสมทรัพย์',
    id: 'saving',
    analyticName: 'saving',
  },
  {
    iconTag: 'fas',
    iconName: 'clock',
    info: 'แบบชั่วระยะเวลา',
    id: 'term',
    analyticName: 'terms',
  },
  {
    iconTag: 'fas',
    iconName: 'man-with-cane',
    info: 'แบบบำนาญ',
    id: 'annuity',
    analyticName: 'annuity',
  },
]

const Box = ({id, item, isSelected, setIsSelected, change}) => {
  let link = '/';
  if (item.id === 'whole_life') {
    link = `/smart/InsuranceCategory/life/intermediate/Lifeform/${
        item.id
      }/${5}?insuranceType=whole_life`
  }
  if (item.id === 'saving') {
    link = `/smart/InsuranceCategory/life/intermediate/Lifeform/${
        item.id
      }/${1}?insuranceType=saving`
  }
  if (item.id === 'term') {
    link = `/smart/InsuranceCategory/life/intermediate/Lifeform/${
        item.id
      }/${5}?insuranceType=term`
  }
  if (item.id === 'annuity') {
    link = `/smart/InsuranceCategory/life/intermediate/Lifeform/${
        item.id
      }/${3}?insuranceType=annuity`
  }
  return (
  <BoxOnce
    id={id}
    to={link}
    onClick={() => {
      setIsSelected(item.id)
      change(`searchBy`, 'premium')
      if (item.id === 'whole_life') {
        change(`weightType`, 5)
      }
      if (item.id === 'saving') {
        change(`weightType`, 1)
      }
      if (item.id === 'term') {
        change(`weightType`, 5)
      }
      if (item.id === 'annuity') {
        change(`weightType`, 3)
      }
    }}>
    <InfoContainerBox>
      <CustomIcon icon={[item.iconTag, item.iconName]} />
      <Info>{item.info}</Info>
    </InfoContainerBox>
  </BoxOnce>
)}

const CustomSelector = ({input, change}) => (
  <ContainerBox>
    {BoxList.map(item => (
      <Box
        id={`noon-smart-insurance-life-intermediate-${item.analyticName}`}
        key={item.iconName}
        item={item}
        isSelected={input.value}
        setIsSelected={input.onChange}
        change={change}
      />
    ))}
  </ContainerBox>
)

const LifeBannerCover = ({change}) => (
  <NoonSmartWrapper>
    <NoonSmartBanner>
      <Container>
        <NoonSmartHeader>แบบประกันชีวิต</NoonSmartHeader>
        <NoonSubSmartHeader>
          วางแผนคุ้มครองทุกความเสี่ยงด้วยประกันชีวิต
        </NoonSubSmartHeader>
        <Navigator />
        <Field
          name="insuranceType"
          change={change}
          component={CustomSelector}
        />
      </Container>
    </NoonSmartBanner>
  </NoonSmartWrapper>
)

const enhancer = compose(
  reduxForm({
    form: 'Planning',
    enableReinitialize: true,
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
  }),
)

export default enhancer(LifeBannerCover)
