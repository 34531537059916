import React from 'react'
import Field from '../share/Field'
import styled, { css } from 'react-emotion'
import { breakpoints, responsive } from './Form'
import { CIOptions } from '../NoonSmart/options'
import Tooltip from '../share/Tooltip'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  displayNumber,
  numberParser,
} from '../../core/helper'

const Container = styled.div``

const FieldContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;

  ${props =>
    responsive.mobile(css`
      width: 100%;
      justify-content: center;
      position: relative;

      > div{
        display: flex;
        flex-direction: column;
        position: relative;

        >div {
          margin-top: 15px;
          justify-content: center;

        }
      }
      span {
        padding-left: 32px;
        font-size: 14px;
        justify-content: flex-start;
      }

    `)};
`
const CustomFieldContainer = styled(FieldContainer)`
  span {
    min-width: 150px;
    max-width: 150px;
    white-space: pre;
    text-align: right !important;
  }

  ${props =>
    responsive.mobile(css`
      flex-direction: column;
      justify-content: center;

      span {
        padding-left: 47px;
        font-size: 16px;
        // justify-content: flex-start;
        white-space: pre;
        text-align: left !important;
      }

    `)};
`

const Label = styled.div`
  margin: auto 0 auto 0;
`
const CustomLabel = styled(Label)`
  margin: auto 0 auto 0;
  min-width: 150px;
  font-size: 16px;
  margin-right: 25px;
  text-align: right;

  span {
    white-space: pre;
    text-align: right !important;
  }

  ${props =>
    responsive.mobile(css`
      font-size: 16px;
      text-align: left;
      padding-left: 47px;
    `)};
`
const Unit = styled.div`
  height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 15px;
  position: relative;

  ${props =>
    responsive.mobile(css`
      position: unset;
      display: none !important;
    `)};
`

const CustomUnit = styled.div`
  height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 15px;
  position: relative;

  text-align: center;
  font-size: 16px;
  font-weight: bold;
  margin-left: 0px;

  ${props =>
    responsive.mobile(css`
      font-size: 16px;
      padding-left: 47px;
      align-items: flex-start;
    `)};
`
const CustomField = styled(Field)`
  width: fit-content;

  input[type='text'] {
    text-align: center;
    width: 200px;
  }

  ${props =>
    responsive.mobile(css`
      width: 100% !important;
      input {
        width: 280px !important;
        font-size: 16px;
      }
    `)};
`
const ContainerRadio = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-bottom: 15px;
  height: ${props => (props.type === 'ci' ? '100px' : '62px')};
  margin-left: ${props => (props.type === 'ci' ? '30px' : '150px')};
  .ci {
    height: 100px;
    margin-right: -10px;
  }

  ${props =>
    responsive.mobile(css`
      flex-direction: column;
      width: 280px;
      height: ${props.type === 'ci' ? 'auto' : '108px'};
      margin-left: 0;
      align-items: unset;
      justify-content: unset;
      margin-bottom: 9px;
    `)};
`
const LabelRadio = styled.div`
  height: ${props => (props.type === 'ci' ? '100px' : '62px')};
  // width: ${props => (props.type === 'ci' ? '130px' : '240px')};
  min-width: 150px;
  font-size: 16px;
  // padding-left: ${props => (props.type === 'ci' ? '3px' : '12px')};

  ${props =>
    responsive.mobile(css`
      text-align: left;
      font-size: 16px;
      height: ${props.type === 'ci' ? '30px' : '62px'};
      padding-left: 47px;
    `)};
`
const ContainerSelector = styled.div`
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-right: 33px;
  margin-top: auto;
  margin-left: -47px;
  min-width: 550px;

  ${props =>
    responsive.mobile(css`
      min-width: 100%;
      height: fit-content;
      margin-right: auto;
      margin-left: auto;
      margin-bottom: 30px;
      margin-top: 20px;
      position: relative;

      span {
        width: 280px !important;
        font-size: 16px;
        justify-content: flex-start;
      }
    `)};
`
const Radios = styled(Field)`
  display: flex;
  align-items: center;
  margin: 0;
  width: 100%;
  height: 62px;
  margin-right: 13px;

  .input-radios-container {
    height: 100%;
  }
  .ant-radio-group {
    flex-direction: column;
    justify-content: center;
    margin-right: 0;
    flex-wrap: unset;
    height: 100%;
    justify-content: space-between;
    align-items: start;

    label {
      // width: 100%;
      margin-right: 0;
      margin: 5px 0;
      white-space: normal;
    }
    .ant-radio-wrapper {
    }
  }

  ${props =>
    responsive.mobile(css`
      margin-left: 63px;
      margin-top: 5px;
      height: 130px;
      .ant-radio-group {
        .ant-radio-wrapper {
          align-items: flex-start;
          .ant-radio {
            padding-top: 2px;
          }
        }
      }
    `)};
`
const Selector = styled(Field)`
  display: flex;
  align-items: center;
  margin-top: 0px;
  margin-right: 20px;
  width: unset;
  .Select-control {
    text-align: center;
  }
  .input-selector-container {
    width: 200px;
    height: 40px;
    font-size: 16px;
  }
  span {
    min-width: 150px;
  }
  .Select-arrow-zone {
    margin-left: 0px;
  }
  ${props =>
    responsive.mobile(css`
      flex-direction: column;
      margin: 0;
      .input-selector-container {
        width: 280px;
      }
      span {
        margin-bottom: 15px;
        margin-right: 0;
        width: 100%;
        justify-content: flex-start;
      }
    `)};
`

const CustomTooltip = styled(Tooltip)`
  &.ant-tooltip {
    > .ant-tooltip-content {
      > .ant-tooltip-arrow {
        border-right-color: #7f7f7f;
      }
      > .ant-tooltip-inner {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        background-color: #7f7f7f;
      }
    }
  }
`

const CustomIconInfo = styled(FontAwesomeIcon)`
  // right: ${props => (props.type === 'ci' ? '41px' : '65px')};
  font-size: 20px;
  margin-right: 80px;

  cursor: pointer;
  color: #7f7f7f;
  border-right-color: #7f7f7f;

  ${props =>
    responsive.mobile(css`
      position: absolute;
      left: 309px;
      margin-left: 0px;
      top: 0px;
    `)};
`

const CustomIconInfoHs = styled(CustomIconInfo)`
    left: 40px;
    margin-top: 10px;
    margin-left: 17px;
    margin-right: 0px;

    ${props =>
    responsive.mobile(css`
      left: 300px;
      `)};
`

const HsSearchBylabel = (searchBy) => {
  switch (searchBy) {
    case 'sum_insured':
      let text = ''

      if (window.innerWidth > breakpoints.mobile) {
        text = 'วงเงินประกัน\nค่ารักษาพยาบาลรวมสูงสุด'
      }
      else {
        text = 'วงเงินประกันค่ารักษาพยาบาล\nรวมสูงสุด (บาท/ ปี)'
      }
      return [text, 'บาท/ ปี']
    case 'room':
      return ['วงเงินค่าห้องค่าสูงสุด', 'บาท/ คืน']
  }
}

const HsFields = props => {
  return (
    <CustomFieldContainer>
      <CustomField
        name='searchValue'
        label={HsSearchBylabel(props.hsGoal)[0]}
        type='text'
        autoComplete='off'
        placeholder='จำนวนเงิน'
        format={displayNumber}
        parse={numberParser}
      />
      <Unit>
        {HsSearchBylabel(props.hsGoal)[1]}
      </Unit>
      {props.hsGoal === 'sum_insured' && (
        <CustomTooltip
          placement="right"
          title={`คุ้มครองค่ารักษาพยาบาลที่เกิดขึ้นภายในโรงพยาบาล ทั้งแบบเหมาจ่ายและจ่ายตามจริง`}>
          <CustomIconInfoHs type={props.insuranceType} icon={['fas', 'info-circle']} />
        </CustomTooltip>
      )}
    </CustomFieldContainer>
  )
}

const CiFields = props => {
  return (
    <>
      {props.ciOption === 'a' && (
        <ContainerRadio type={props.insuranceType}>
          <LabelRadio type={props.insuranceType}>ระยะคุ้มครอง</LabelRadio>
          <Radios
            className={props.insuranceType}
            name="ciRoles"
            type="radios"
            options={CIOptions}
          />
        </ContainerRadio>
      )}
      {props.ciOption === 'b' && (
        <CustomFieldContainer>
          <CustomLabel>ระยะคุ้มครอง</CustomLabel>
          <CustomUnit>
            {(props.ciRoles || '') && (
              CIOptions.find(t => t.value === props.ciRoles).label
            )}
          </CustomUnit>
        </CustomFieldContainer>
      )}
      <ContainerSelector>
        <Selector
          label="โรคที่สนใจคุ้มครองเป็นพิเศษ"
          name="ciDiseases"
          type="selector"
          options={props.diseasesOptions}
          themeColor="orange"
          placeholder="เลือก"
        />
        <CustomTooltip
          placement="right"
          title={`เลือกกลุ่มโรคที่อยากให้มีความคุ้มครองครอบคลุมกลุ่มโรคนี้แน่นอน หรือไม่ระบุก็ได้`}>
          <CustomIconInfo type={props.insuranceType} icon={['fas', 'info-circle']} />
        </CustomTooltip>
      </ContainerSelector>
      <CustomFieldContainer>
        <CustomField
          name='searchValue'
          label={(window.innerWidth > breakpoints.mobile ? 'ระบุจำนวนเงิน' : 'ระบุจำนวนเงิน (บาท)')}
          type='text'
          autoComplete='off'
          placeholder='จำนวนเงิน'
          format={displayNumber}
          parse={numberParser}
        />
        <Unit>บาท</Unit>
      </CustomFieldContainer>
    </>
  )
}

const HealthFields = props => {
  return (
    <Container>
      {props.insuranceType === 'hs' && (
        <HsFields
          insuranceType={props.insuranceType}
          hsGoal={props.hsGoal}
        />
      )}
      {props.insuranceType === 'ci' && (
        <CiFields
          insuranceType={props.insuranceType}
          searchBy={props.searchBy}
          ciRoles={props.ciRoles}
          ciOption={props.ciOption}
          diseasesOptions={props.diseasesOptions}
        />
      )}
    </Container>
  )
}

export default HealthFields