import React from 'react'

import FirstTimeOIC from './FirstTimeOIC'
import MyPolicy from './MyPolicy'

import enhancer from './Landing.compose'

const Landing = ({ initialized = false, isFirstTime = false }) => {
  if (initialized) {
    if (isFirstTime) {
      return <FirstTimeOIC />
    } else {
      return <MyPolicy />
    }
  }

  return <React.Fragment />
}

export default enhancer(Landing)
