import React from 'react'
import { compose, lifecycle, nest } from 'recompose'
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'
import { withRouter } from './enhances'
import * as paths from './common/paths'
import Landing from './Landing'
import PrepareTax from './PrepareTax'
import TaxPro from './TaxPro'
import TaxCalculator from './TaxCalculator'
import withSEO from '../share/withSEO'
import { HOST } from '../../core/httpClient'
import Canonical from '../share/Canonical'

const NoonTax = props => (
  <>
    <Canonical withRouter href={props => `${HOST}${props.location.pathname}`} />
    <Switch>
      <Route path={paths.prepareTaxPath()} component={PrepareTax} />
      <Route path={paths.calculatorPath()} component={TaxCalculator} />
      <Route path={paths.proPath()} component={TaxPro} />
      <Route path={paths.rootPath()} component={Landing} />
      <Redirect to={paths.rootPath()} />
    </Switch>
  </>
)

const enhancer = compose(
  withSEO(
    'เตรียมเอกสารยื่นภาษีออนไลน์ฟรีที่กรมสรรพากรรับรอง | noon tax',
    'เตรียมข้อมูลยื่นภาษีออนไลน์ได้ง่ายๆ รับประกันความถูกต้อง ได้เงินคืนภาษีสูงสุด ฟรี!',
    'noon tax,ยื่นภาษี,ภาษี,ยื่นภาษี2563,ยื่นภาษีออนไลน์,ยื่นภาษีบุคคลธรรมดา,ภาษีออนไลน์,จ่ายภาษี'
  ),
  withRouter(),
  lifecycle({
    componentDidMount() {
      if (this.props.location.pathname !== window.location.pathname) {
        this.props.history.push(window.location.pathname)
      }
    },
    componentDidUpdate(prevProps) {
      if (this.props.location.pathname !== window.location.pathname) {
        this.props.history.push(window.location.pathname)
      }
      if (this.props.location.pathname !== prevProps.location.pathname) {
        window.scrollTo(0, 0)
      }
    },
  })
)

export default nest(BrowserRouter, enhancer(NoonTax))
