import React from 'react'
import styled from 'react-emotion'
import { connect } from 'react-redux'
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'
import { compose, lifecycle, nest } from 'recompose'

import Form from './Form'

import { setData } from '../../ducks/404'

const Container = styled.div`
  margin-top: -80px;
`

const Embed = props => {
  return (
    <Container>
      <Switch>
        <Route path="/embed/form" component={Form} />
        <Redirect to="/" />
      </Switch>
    </Container>
  )
}
const enhancer = compose(
  connect(
    () => ({}),
    { setData }
  ),
  lifecycle({
    componentDidMount() {
      const { setData } = this.props
      setData({ isNoHeader: true, isNoFooter: true, isNoBackground: true })
    },
    componentWillUnmount() {
      const { setData } = this.props
      setData({ isNoHeader: false, isNoFooter: false, isNoBackground: true })
    },
  })
)

export default nest(BrowserRouter, enhancer(Embed))
