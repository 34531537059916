import { navigate } from '@reach/router'
import React from 'react'
import styled, { css } from 'react-emotion'

import CustomField from '../../share/Field'
import Loading from '../../share/Loading'
import Modal from '../../share/Modal'
import { Button, InfoRow } from '../MyPolicy/components'
import UploadAvatar from './UploadAvatar'

import { getThaiDate } from '../../../core/helper'
import { responsive } from '../../../core/style'
import enhancer from './UserProfile.compose'

const UserProfile = ({
  handleSubmit,
  isShowModal,
  setIsShowModal,
  editProfile,
  avartars = [],
  setAvatarId,
  avatarId,
  isLoading,
  reset,
  change,
  avatar,
  user = {},
}) => (
  <StyledLoading isLoading={isLoading}>
    <Container
      onSubmit={handleSubmit(value => {
        editProfile(
          {
            ...value,
            avatarName: avatarId
              ? null
              : value.avatarImage
              ? value.avatarImage.name
              : null,
            avatarImage: avatarId ? null : value.avatar,
            avatarId: avatarId,
          },
          reset
        )
      })}
    >
      <Header>ข้อมูลของฉัน</Header>
      <Wrapper>
        <UserIcon src={avatar} onClick={() => setIsShowModal(true)} />
        <Button
          block
          size="large"
          onClick={() => {
            navigate('/profile/my-policy')
          }}
        >
          ข้อมูลกรมธรรม์ คปภ.
        </Button>
        <InputUserProfileContainer>
          <InfoRow label="ชื่อ" value={user.firstNameTh} />
          <InfoRow label="นามสกุล" value={user.lastNameTh} />
          <InfoRow label="เพศ" value={user.gender} />
          <InfoRow
            label="วันเกิด"
            value={user.birthdate ? getThaiDate(user.birthdate) : ''}
          />
          <InfoRow label="อีเมล" value={user.email} />
          <InfoRow label="เบอร์ติดต่อ" value={user.phoneNumber} />
        </InputUserProfileContainer>
      </Wrapper>
      <CustomModal isShowModal={isShowModal} setIsShowModal={setIsShowModal}>
        <UploadAvatar
          setIsShowModal={setIsShowModal}
          avartars={avartars}
          setAvatarId={setAvatarId}
          onAvatarChange={value => change('avatar', value)}
        />
      </CustomModal>
    </Container>
  </StyledLoading>
)

export default enhancer(UserProfile)

const StyledLoading = styled(Loading)`
  width: 100%;
`

const Container = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 456px;
  padding: 30px 18px 0;
  margin: 0 auto;

  font-family: Roboto;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin-bottom: 20px;
`

const Header = styled.div`
  font-size: 28px;
  line-height: 34px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 32px;
`

const InputUserProfileContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  padding-top: 40px;
`

const GenderButton = styled(CustomField)`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  .label {
    min-width: fit-content;
  }
`

const TextInput = styled(CustomField)`
  position: relative;
  display: flex;
  flex-direction: row;

  .label {
    justify-content: flex-start;
    margin-bottom: 10px;
  }
  input {
    width: 246px;
    text-align: center;
  }
`

const DatePickerInput = styled(CustomField)`
  position: relative;
  display: flex;
  flex-direction: column;

  width: 246px;

  > div > div {
    width: 246px !important;

    > div:first-child {
      width: 246px !important;
    }
  }

  .label {
    justify-content: flex-start;
    margin-bottom: 10px;
  }
  input {
    width: 246px;
    text-align-last: center;
  }
`

const UserIcon = styled.img`
  display: block;
  width: 102px;
  height: 102px;
  margin: 0 auto 32px;

  border-radius: 50%;
  cursor: pointer;
  object-fit: cover;
`

const CustomModal = styled(Modal)`
  &.ant-modal {
    width: unset !important;
    display: flex;
    justify-content: center;
    margin: 0 calc((100vw - 800px) / 2);
  }
  .ant-modal-content {
    width: 600px;
    padding: 45px 0 35px 0;
  }

  ${props =>
    responsive.mobile(css`
      &.ant-modal {
        width: 100% !important;
        margin: unset;
      }
    `)};
`
