import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { navigate } from '@reach/router'
import React from 'react'
import styled, { css } from 'react-emotion'
import { connect } from 'react-redux'
import { compose, withState } from 'recompose'

import { responsive } from '../../core/style'
import { logout } from '../../ducks/auth'
import ProfileLogo from '../../static/images/avatar-02.svg'

const EMAIL_MENU = [
  {
    id: 0,
    text: 'ข้อมูลส่วนตัว',
    icon: 'edit',
    link: 'user-profile',
    analyticName: 'user-profile',
  },
  {
    id: 1,
    text: 'รายการโปรด',
    icon: 'heart',
    link: 'favorite',
    analyticName: 'favorite',
  },
  // {id: 2, text: 'พอร์ตแบบประกัน', icon: 'chart-area', link: 'port', analyticName: 'insurance-port'},
  {
    id: 3,
    text: 'เปรียบเทียบแบบประกัน',
    icon: 'table',
    link: 'compare',
    analyticName: 'insurance-product-compare',
  },
  {
    id: 4,
    text: 'แบบประกันที่สนใจ',
    icon: 'folder-open',
    link: 'interest',
    analyticName: 'insurance-product-interested',
  },
  // {id: 5, text: 'UBI', icon: 'lightbulb', link: 'ubi'},
  {
    id: 6,
    text: 'เปลี่ยนรหัสผ่าน',
    icon: 'key',
    link: 'changed-password',
    analyticName: 'change-password',
  },
  {
    id: 7,
    text: 'ออกจากระบบ',
    icon: 'sign-out-alt',
    link: 'logout',
    analyticName: 'logout',
  },
]

const OTHER_MENU = [
  {
    id: 0,
    text: 'ข้อมูลส่วนตัว',
    icon: 'edit',
    link: 'user-profile',
    analyticName: 'user-profile',
  },
  {
    id: 1,
    text: 'รายการโปรด',
    icon: 'heart',
    link: 'favorite',
    analyticName: 'favorite',
  },
  // {id: 2, text: 'พอร์ตแบบประกัน', icon: 'chart-area', link: 'port', analyticName: 'insurance-product'},
  {
    id: 3,
    text: 'เปรียบเทียบแบบประกัน',
    icon: 'table',
    link: 'compare',
    analyticName: 'insurance-product-compare',
  },
  {
    id: 4,
    text: 'แบบประกันที่สนใจซื้อ',
    icon: 'folder-open',
    link: 'interest',
    analyticName: 'insurance-product-interested',
  },
  {
    id: 7,
    text: 'ออกจากระบบ',
    icon: 'sign-out-alt',
    link: 'logout',
    analyticName: 'logout',
  },
]

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #6a94cb;
  color: white;
  align-items: center;
  justify-content: flex-start;
  min-width: 300px;
  min-height: calC(100vh - 294px);

  ${props =>
    responsive.tablet(css`
      min-width: 218px;
    `)};

  ${props =>
    responsive.mobile(css`
      position: absolute;
      left: ${props.hideActive ? '0px' : '-218px'};
      transition: 0.5s;
      min-height: 500px;
      height: 100%;
      z-index: 5;
      width: ${props.hideActive ? '100vw' : '-218px'};
    `)};
`

const ProfileLogoImage = styled.img`
  width: 100px;
  height: 100px;
  margin-bottom: 25px;
  margin-top: 36px;

  border-radius: 50%;
  object-fit: cover;
`

const ProfileMenuListPart = styled.div``

const ProfileMenuListContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 25px;
  cursor: pointer;
`

const ProfileMenuListIcon = styled(FontAwesomeIcon)`
  font-size: 16px;
  margin-right: 6px;
  min-width: 20px;
`

const ProfileMenuListText = styled.div`
  font-size: 14px;
  font-family: 'Prompt';
  font-weight: ${props => (props.isActive ? 700 : 400)};
`

const HideMenuContainer = styled.div`
  position: absolute;
  display: none;
  justify-content: center;
  align-items: center;
  right: -25px;
  top: 0;
  background-color: #6a94cb;
  width: 30px;
  height: 60px;
  border-radius: 0 30px 30px 0;
  cursor: pointer;

  ${props =>
    responsive.mobile(css`
      display: flex;
      right: ${props.hideActive ? '25px' : '-25px'};
    `)};
`

const HideIcon = styled(FontAwesomeIcon)`
  font-size: 20px;
`

const menus = user => (user.loginWith === 'email' ? EMAIL_MENU : OTHER_MENU)

const ProfileMenu = ({
  logout,
  user,
  hideActive,
  setHideActive,
  activeMenu = '',
}) => {
  const profileMenuList = menus(user)
  return (
    <Container hideActive={hideActive}>
      <HideMenuContainer
        hideActive={hideActive}
        onClick={() => setHideActive(!hideActive)}
      >
        <HideIcon
          icon={['fas', hideActive ? 'chevron-left' : 'chevron-right']}
        />
      </HideMenuContainer>
      <ProfileLogoImage src={user.avatar || ProfileLogo} />
      <ProfileMenuListPart>
        {profileMenuList.map(value => (
          <ProfileMenuListContainer
            className={value.analyticName}
            key={value.id}
            onClick={() => {
              value.link === 'logout'
                ? logout()
                : navigate(`/profile/${value.link}`)
              setHideActive(!hideActive)
            }}
          >
            <ProfileMenuListIcon icon={['fas', value.icon]} />
            <ProfileMenuListText isActive={activeMenu === value.link}>
              {value.text}
            </ProfileMenuListText>
          </ProfileMenuListContainer>
        ))}
      </ProfileMenuListPart>
    </Container>
  )
}

const enhancer = compose(
  withState('hideActive', 'setHideActive', false),
  connect(
    () => ({}),
    { logout }
  )
)

export default enhancer(ProfileMenu)
