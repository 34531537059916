import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { navigate } from '@reach/router'
import React from 'react'
import styled, { css } from 'react-emotion'
import { connect } from 'react-redux'
import { compose, withState } from 'recompose'
import { formValueSelector, reduxForm } from 'redux-form'

import Button from '../share/Button'
import Field from '../share/Field'
import Loading from '../share/Loading'
import GeneralSearch from './GeneralSearchBox'

import { inRange, numberParser, TQM_SHOP_LINK } from '../../core/helper'
import { responsive } from '../../core/style'
import { searchBox } from '../../ducks/landing'

const CustomField = styled(Field)`
  margin: 0 10px;
  input {
    background-color: white;
    border: unset;
    height: 30px;
    width: 70px;
    text-align: center;
    color: ${props => (props.error ? '#FF0034' : 'black')};

    -webkit-appearance: none;
  }

  .input-selector-container {
    margin: 0;

    .Select {
      width: 100px;
      height: 30px;
      background-color: white;
      border: 0;
    }
    .Select-menu-outer {
      > .Select-menu {
        > .Select-option {
          opacity: 0.5;
          :hover {
            opacity: 1;
          }
        }
      }
    }

    .Select-control {
      > .Select-multi-value-wrapper {
        > .Select-placeholder {
          color: black;
          opacity: 0.6;
        }
      }
      > .Select-arrow-zone {
        color: rgba(0, 0, 0, 0.6);
      }
    }
  }

  .error-container {
    left: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    span,
    svg {
      color: #ffd900 !important;
    }
    .error {
      position: unset;
      line-height: 22px;
      width: fit-content;
    }
  }

  ${props =>
    responsive.mobile(css`
      input {
        width: 260px;
      }
      .input-selector-container {
        margin: 0;

        .Select {
          width: 260px;
          height: 35px;
        }
      }
      .error-container {
        .error {
          width: 100%;
        }
      }
    `)};
`

const AgeField = styled(CustomField)``

const Container = styled.div`
  width: 600px;
  align-items: center;
  text-align: center;
  justify-content: center;
  flex-direction: column;
  margin: 0 auto;

  ${props =>
    responsive.mobile(css`
      width: 280px;
    `)};
`

const CustomGenderIcon = styled(FontAwesomeIcon)`
  font-size: 30px;
  width: 11px;
  height: 31px;
  margin-right: 15px;
  color: ${props => props.theme.ORANGE};
  opacity: ${props => (props.active === 'true' ? 1 : 0.5)};

  cursor: pointer;
`

const Topic = styled.h1`
  margin: 84px 0 50px 0;
  font-size: 26px;
  font-family: Prompt;
  font-weight: 700;
  color: #265fa7;

  ${props =>
    responsive.mobile(css`
      font-size: 20px;
      margin-top: 40px;
      margin-bottom: 20px;
    `)};
`

const Search = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 120px;
  background-color: #265fa7;
  border-radius: 0 0 5px 5px;
  color: #ffffff;

  ${props =>
    responsive.mobile(css`
      height: 450px;
      justify-content: unset;
    `)};
`

const TopicSearch = styled.h1`
  display: flex;
  align-items: center;
  justify-content: center;

  width: ${props => (props.isActive ? 330 : 270)}px;
  height: ${props => (props.isActive ? 35 : 30)}px;
  background-color: #265fa7;

  border-radius: ${props =>
    props.isActive
      ? '5px 5px 0 0'
      : props.position === 'search'
      ? '0 5px 0 0'
      : '5px 0 0 0'};
  color: ${props =>
    props.isActive ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.5)'};

  font-size: 16px;
  font-weight: bold;
  margin: ${props => (props.isActive ? 0 : 5)}px 0 0;
  opacity: ${props => (props.isActive ? 1 : 0.4)};
  :hover {
    cursor: pointer;
  }
`

const ContainerTopicSearch = styled.div`
  display: flex;
  flex-direction: row;
`

const ContainerHeadSearch = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  margin: 5px 20px 10px;
  .insuranceType {
    width: unset;
    margin: 0;
    .input-selector-container {
      .Select {
        width: 360px;
      }

      .Select-control {
        > .Select-multi-value-wrapper {
          > .Select-placeholder {
            color: black;
            opacity: 0.6;
          }
        }
      }
    }
  }

  ${props =>
    responsive.mobile(css`
      flex-direction: column;

      .insuranceType {
        .input-selector-container {
          .Select {
            width: 260px;
          }
        }
      }
    `)};
`

const Head = styled.div`
  ${props =>
    responsive.mobile(css`
      margin: 20px 0 15px;
    `)};
`

const ContainerHeadInfo = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  .gender {
    margin-right: 25px;
  }
  .classFemale {
    margin-right: 0;
  }
  .age {
    margin-left: 9px;
  }

  ${props =>
    responsive.mobile(css`
      flex-direction: column;
      width: 260px;
      .gender {
        margin-right: 0;
      }
      .age {
        margin-left: 0;
      }
    `)};
`

const CustomButton = styled(Button)`
  width: 67px;
  height: 30px;

  ${props =>
    responsive.mobile(css`
      margin-top: 20px;
    `)};
`
const MobileCutWord = styled.br`
  display: none;

  ${props =>
    responsive.mobile(css`
      display: flex;
    `)};
`
const ErrorAgeMotor = styled.span`
  position: absolute;
  bottom: -20px;
  left: 43px;
  width: fit-content;
  font-size: 10px;
  color: #ffd900;
  ${props =>
    responsive.mobile(css`
      left: 0;
    `)};
`
const validate = values => {
  const errors = {}
  if (!values.age) {
    errors.age = 'กรุณาระบุอายุ'
  }
  if (!values.insuranceType) {
    errors.insuranceType = 'กรุณาเลือกประกันที่สนใจ'
  }
  if (!values.status) {
    errors.status = 'กรุณาเลือกสถานะ'
  }
  if (values.insuranceType === 'motor' && parseInt(values.age) < 18) {
    errors.age = 'ต้องมีอายุ 18 ปีขึ้นไปเท่านั้น'
  }
  return errors
}
const SearchBox = props => {
  const {
    activeChannel,
    setActiveChannel,
    gender,
    setGender,
    searchBox,
    handleSubmit,
    isLoading,
  } = props
  return (
    <Loading isLoading={isLoading}>
      <Container>
        <Topic>
          ค้นหาประกันที่เข้าใจคุณ
          <MobileCutWord />
          ในทุกไลฟ์สไตล์
        </Topic>
        <ContainerTopicSearch>
          <TopicSearch
            onClick={() => setActiveChannel('search')}
            isActive={activeChannel === 'search'}
            position={activeChannel}
          >
            ค้นหาแบบประกัน
          </TopicSearch>
          <TopicSearch
            onClick={() => setActiveChannel('general')}
            isActive={activeChannel === 'general'}
            position={activeChannel}
          >
            ค้นหาทั่วไป
          </TopicSearch>
        </ContainerTopicSearch>
        {activeChannel === 'search' ? (
          <Search
            aid="insurance-product-search"
            onSubmit={handleSubmit(value => {
              dataLayer.push({
                event: 'search_insurance_product',
                searchInsuranceType: value.insuranceType,
                searchGender: gender,
                searchAge: value.age,
                searchMarriageStatus: value.status,
              })

              if (value.insuranceType === 'motor') {
                navigate(TQM_SHOP_LINK)
              } else {
                return searchBox({ ...value, gender: gender })
              }
            })}
          >
            <ContainerHeadSearch>
              <Head>เลือกประกันที่สนใจ</Head>
              <CustomField
                className="insuranceType"
                name="insuranceType"
                placeholder="เลือก"
                type="selector"
                options={[
                  {
                    label: 'ประกันรถยนต์',
                    value: 'motor',
                  },
                  {
                    label: 'ประกันชีวิต - ตลอดชีพ',
                    value: 'whole_life',
                  },
                  {
                    label: 'ประกันชีวิต - สะสมทรัพย์',
                    value: 'saving',
                  },
                  {
                    label: 'ประกันชีวิต - ชั่วระยะเวลา',
                    value: 'term',
                  },
                  {
                    label: 'ประกันชีวิต - บำนาญ',
                    value: 'annuity',
                  },
                  {
                    label: 'ประกันสุขภาพ - คุ้มครองค่ารักษาพยาบาล (HS)',
                    value: 'hs',
                  },
                  {
                    label: 'ประกันสุขภาพ - คุ้มครองโรคร้ายแรง (CI)',
                    value: 'ci',
                  },
                ]}
              />
            </ContainerHeadSearch>
            <ContainerHeadSearch>
              <ContainerHeadInfo>
                <Head className="gender">เพศ</Head>
                <div>
                  <CustomGenderIcon
                    onClick={() => {
                      setGender('male')
                    }}
                    active={gender === 'male' ? 'true' : 'false'}
                    icon={['fas', 'male']}
                  />
                  <CustomGenderIcon
                    className="classFemale"
                    onClick={() => {
                      setGender('female')
                    }}
                    active={gender === 'female' ? 'true' : 'false'}
                    icon={['fas', 'female']}
                  />
                </div>
              </ContainerHeadInfo>
              <ContainerHeadInfo>
                <Head className="age">อายุ</Head>
                <AgeField
                  name="age"
                  placeholder="ระบุ"
                  normalize={inRange(0, 100)}
                  parse={numberParser}
                />
              </ContainerHeadInfo>
              <ContainerHeadInfo>
                <Head>สถานะ</Head>
                <CustomField
                  name="status"
                  placeholder="เลือก"
                  type="selector"
                  options={[
                    {
                      label: 'โสด',
                      value: 'single',
                    },
                    {
                      label: 'แต่งงาน',
                      value: 'married',
                    },
                  ]}
                />
              </ContainerHeadInfo>
              <CustomButton
                htmlType="submit"
                icons={['fas', 'search']}
                disabled={props.invalid}
              >
                ค้นหา
              </CustomButton>
            </ContainerHeadSearch>
          </Search>
        ) : (
          <GeneralSearch />
        )}
      </Container>
    </Loading>
  )
}

const selector = formValueSelector('search')

const enhancer = compose(
  connect(
    (state, props) => ({
      isLoading: state.landing.isLoading,
      insuranceTypeSelected: selector(state, 'insuranceType'),
      age: selector(state, 'age'),
    }),
    { searchBox }
  ),
  withState('activeChannel', 'setActiveChannel', 'search'),
  reduxForm({ form: 'search', validate })
)
export default enhancer(SearchBox)
