import React from 'react'
import Helmet from 'react-helmet'
import { branch, compose, lifecycle, withProps, withHandlers } from 'recompose'
import { withRouter } from '../NoonQuestion/enhancers'
import previewNoon from '../../static/images/preview-noon-1200x630.jpg'
import fbPreviewCorona from '../../static/images/fb-preview-corona.jpg'
import { HOST } from '../../core/httpClient'
import { isClient } from '../NoonQuestion/common/helper'

const Canonical = props => (
  <div />
  // <Helmet>
  //   {props.href && (
  //     <>
  //       <link rel="canonical" href={props.href} />
  //       <meta property="og:url" content={props.href}></meta>
  //     </>
  //   )}
  //   {props.image && (
  //     <>
  //       <meta property="og:image" content={props.image}></meta>
  //       <meta property="og:image:width" content="1200"></meta>
  //       <meta property="og:image:height" content="630"></meta>
  //     </>
  //   )}
  // </Helmet>
)

const enhancer = compose(
  branch(props => props.withRouter, withRouter()),
  withProps(props => {
    let href = props.href instanceof Function ? props.href(props) : props.href
    let image = `${HOST}${previewNoon}`

    if (isClient) {
      href = href || window.location.href
    }

    if (/\/corona/.test(href)) {
      image = `${HOST}${fbPreviewCorona}`
    }

    if (/\/square/.test(href)) {
      href = null
      image = null
    }

    return { href, image }
  }),
  withHandlers({
    addTags: props => () => {
      let link = null
      let meta = null

      if (props.href) {
        try {
          document.querySelector("[rel='canonical']").remove()
          document.querySelector("[rel='canonical']").remove()
        } catch (e) {}
        try {
          document.querySelector("[property='og:url']").remove()
          document.querySelector("[property='og:url']").remove()
        } catch (e) {}

        link = document.createElement('link')
        link.rel = 'canonical'
        link.href = props.href
        document.head.appendChild(link)

        meta = document.createElement('meta')
        meta.setAttribute('property', 'og:url')
        meta.content = props.href
        document.head.appendChild(meta)
      }

      if (props.image) {
        try {
          document.querySelector("[property='og:image']").remove()
          document.querySelector("[property='og:image']").remove()
        } catch (e) {}

        meta = document.createElement('meta')
        meta.setAttribute('property', 'og:image')
        meta.content = props.image
        document.head.appendChild(meta)
      }
    },
  }),
  lifecycle({
    componentDidMount() {
      this.props.addTags()
    },
    componentWillReceiveProps() {
      this.props.addTags()
    },
  })
)

export default enhancer(Canonical)
