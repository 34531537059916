import React from 'react'
import PropTypes from 'prop-types'
import {
  compose,
  lifecycle,
  withContext,
  withHandlers,
  withState,
} from 'recompose'
import spidusStore from './spidusStore'
import styled from 'react-emotion'
import SpidusConsole from './SpidusConsole'
import withForm from '../../enhances/withForm'
import {isEmpty, get} from 'lodash'
import Loading from '../../../share/Loading'

const MainLayout = styled.div``

const SpidusManager = ({
  change,
  handleSubmit,
  children,
  enableLoading,
  fetching,
  ...rest
}) => (
  <MainLayout {...rest}>
    {children}
    <SpidusConsole {...{change, handleSubmit}} configName={rest.configName} />
    {enableLoading && <Loading isLoading={fetching} />}
  </MainLayout>
)

export const getSessionId = () => window.localStorage.getItem('Noon-Auth-Token')
export const getNoonTaxStorageKey = () => `NoonTaxSpidusForm-${getSessionId()}`

const enhance = compose(
  withForm({
    form: spidusStore.STORE_NAME,
    enableReinitialize: true,
    destroyOnUnmount: false,
  }),
  withContext(
    {
      configName: PropTypes.string,
      autoSave: PropTypes.bool,
    },
    props => ({
      configName: props.configName,
      autoSave: props.autoSave,
    }),
  ),
  withState('fetching', 'setFetching', false),
  withHandlers({
    loadValues: props => async () => {
      props.initialize(
        JSON.parse(localStorage.getItem(getNoonTaxStorageKey())) || {},
      )
    },
    saveValues: props => () => {
      localStorage.setItem(getNoonTaxStorageKey(), JSON.stringify(props.values))
    },
  }),
  lifecycle({
    async componentDidMount() {
      if (isEmpty(this.props.values)) {
        this.props.loadValues()
      }

      window.addEventListener(
        'beforeunload',
        (window.beforeunloadCallback = this.props.saveValues),
      )

      const answerNames = spidusStore.getRegistedNames().filter(name => !!name)

      const hasUnloadAnswer = answerNames.reduce(
        (memo, answerName) =>
          memo ||
          !get(
            this.props.values,
            `answers.${this.props.configName}.${answerName}`,
          ),
        false,
      )

      if (spidusStore.changed || hasUnloadAnswer) {
        this.props.setFetching(true)
        spidusStore.changed = false
      }

      await spidusStore.loadAnswers(this.props.configName, ...answerNames)

      this.props.setFetching(false)
    },
    componentWillUnmount() {
      try {
        this.props.saveValues()
        window.removeEventListener('beforeunload', window.beforeunloadCallback)
      } catch (e) {}
    },
  }),
)

export default enhance(SpidusManager)
