import React from 'react'
import styled, { css } from 'react-emotion'
import { withState } from 'recompose'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { responsive } from '../../core/style'

const Container = styled.div`
  padding: 115px 20px 155px;

  ${props =>
    responsive.mobile(css`
      padding: 75px 20px 97px;
    `)};
`

const Header = styled.div`
  margin: 0 0 40px;

  font-size: 30px;
  font-weight: bold;
  line-height: 46px;
  text-align: center;
`

const Caret = styled(FontAwesomeIcon)`
  margin-left: 12px;
`

const Question = styled.pre`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  max-width: 900px;
  min-height: 50px;
  margin: 0 auto;
  padding: 10px 25px;

  background-color: ${props =>
    props.isActive ? 'rgba(127, 127, 127, 0.3)' : 'rgba(229, 229, 229, 0.3)'};

  font-family: Prompt, Roboto, sans-serif;
  font-size: 20px;
  font-weight: ${props => (props.isActive ? 'bold' : 'normal')};
  line-height: 30px;
  color: ${props => props.theme.GREY80};
  white-space: pre-wrap;
  cursor: pointer;

  ${props =>
    responsive.mobile(css`
      font-size: 16px;
      line-height: 24px;
    `)};
`

const Answer = styled(Question)`
  flex-direction: column;
  align-items: flex-start;
  padding: 21px 50px 21px 25px;

  background-color: rgba(229, 229, 229, 0.1);
  cursor: default;

  ${props =>
    responsive.mobile(css`
      ul,
      ol {
        padding-inline-start: 20px;
      }
    `)};
`

const enhancer = withState('isActive', 'setActive', false)

const FAQ = enhancer(props => {
  const onClick = () => {
    if (props.isActive == false) {
      props.onToggleShowFaq(props.question)
    }
    props.setActive(!props.isActive)
  }
  return (
    <React.Fragment>
      <Question isActive={props.isActive} onClick={onClick}>
        {props.question}
        <Caret
          icon={props.isActive ? ['fas', 'caret-up'] : ['fas', 'caret-down']}
        />
      </Question>
      {props.isActive && props.answer}
    </React.Fragment>
  )
})

const Text = [
  {
    question: `คำถาม : ขั้นตอนการเคลม`,
    answer: (
      <Answer>
        คำตอบ :
        <ul>
          <li>วิธีการจัดส่งเอกสารเรียกร้อง : แจ้งมาที่ TQM เบอร์ 1737</li>
          <li>
            ระยะเวลาในการจ่ายค่าสินไหมทดแทนให้แก่ผู้เอาประกันภัย : ภายใน 7
            วันนับตั้งแต่วันที่ทางบริษัทฯ
            ได้รับเอกสารประกอบการพิจารณาค่าสินไหมทดแทนครบถ้วน
          </li>
          <li>
            วิธีการจ่ายค่าสินไหมทดแทนให้แก่ผู้เอาประกันภัย : ทางบริษัทฯ
            จะจ่ายค่าสินไหมทดแทนให้แก่ผู้เอาประกันภัยโดยการโอนเข้าบัญชีธนาคารประเภทออมทรัพย์
            โดยจะจ่ายให้แก่ผู้เอาประกันภัยเท่านั้น
            แต่หากกรณีผู้เอาประกันภัยเสียชีวิต จะดำเนินการจ่ายให้กับทายาทโดยธรรม
            (ตามกฎหมาย)
          </li>
        </ul>
      </Answer>
    ),
  },
  {
    question: `คำถาม : เอกสารสำหรับการเคลมประกันภัย`,
    answer: (
      <Answer>
        คำตอบ : เอกสารประกอบการเรียกร้องเคลม ประกันโคโรนา มีดังนี้
        <ol>
          <li>แบบฟอร์มการเรียกร้องค่าสินไหมทดแทน</li>
          <li>
            ใบรับรองแพทย์ที่วินิจฉัยว่า
            ผู้เอาประกันภัยมีการเจ็บป่วยด้วยโรคโควิด-19
          </li>
          <li>
            ผลการตรวจทางห้องปฏิบัติการที่ตรวจพบเชื้อโคโรน่าไวรัส – โควิด-19
            (Novel- Coronavirus หรือ 2019-CoV)
          </li>
          <li>สำเนาหน้าบัตรประชาชนของผู้เอาประกันภัย</li>
          <li>สำเนาหน้าบัญชีธนาคารประเภทออมทรัพย์ของผู้เอาประกันภัย</li>
        </ol>
      </Answer>
    ),
  },
  {
    question: `คำถาม : การยกเลิกการทำประกัน`,
    answer: <Answer>คำตอบ : สามารถโทรแจ้งยกเลิกได้ที่ TQM 1737</Answer>,
  },
  {
    question: `คำถาม : คุ้มครองทันทีเลยไหม`,
    answer: (
      <Answer>คำตอบ : ไม่ ประกันภัยไวรัสโคโรนามีระยะเวลารอคอย 14 วัน</Answer>
    ),
  },
  {
    question: `คำถาม : มีค่าใช้จ่ายอะไรเพิ่มเติมไหมนอกจากค่าเบี้ยประกัน`,
    answer: <Answer>คำตอบ : ไม่มีค่าใช้จ่ายเพิ่มเติม</Answer>,
  },
  {
    question: `คำถาม : ตายด้วยโรคอื่นๆ ที่ไม่ได้เกิดจากเชื้อไวรัสโคโรนาจะคุ้มครองไหม`,
    answer: (
      <Answer>
        คำตอบ :
        ไม่คุ้มครองเพราะประกันนี้เฉพาะแค่โรคที่เกิดจากการติดเชื้อไวรัสโคโรนาเท่านั้น
      </Answer>
    ),
  },
  {
    question: `คำถาม : สามารถทำได้ทุกอาชีพหรือเปล่า`,
    answer: (
      <Answer>
        คำตอบ : ขึ้นอยู่กับเงื่อนไข และรายละเอียดแผนประกันแต่ละบริษัมประกัน
      </Answer>
    ),
  },
  {
    question: `คำถาม : หากจะเดินทางไปต่างประเทศแล้วทำไว้ได้ไหม`,
    answer: <Answer>คำตอบ : ไม่สามารถทำได้</Answer>,
  },
  {
    question: `คำถาม : ชำระเงินค่าเบี้ยประกันผ่านช่องทางไหนได้บ้าง`,
    answer: (
      <Answer>คำตอบ : สามารถชำระเงินผ่านบัตรเครดิต, 2C2P, โอนเงินสด</Answer>
    ),
  },
  {
    question: `คำถาม : หากเคลมการติดเชื้อไวรัสโคโรนาไปแล้วจะเคลมอีกได้ไหม`,
    answer: (
      <Answer>
        คำตอบ : สิ้นสุดความคุ้มครองของโคโรนา
        แต่ยังคุ้มครองกรณีที่สูญเสียอวัยวะสายตา ทุพพลภาพถาวรสิ้นเชิง
        หรือเสียชีวิตจากอุบัติเหตุทั่วไป (อบ.1)
      </Answer>
    ),
  },
]

const FAQs = props => {
  return (
    <Container>
      <Header>คำถามที่พบบ่อย</Header>

      {Text.map((faq, index) => (
        <FAQ
          aid="corona-insurance-faq"
          onToggleShowFaq={props.onToggleShowFaq}
          key={index}
          {...faq}
        />
      ))}
    </Container>
  )
}

export default FAQs
