import React from 'react'
import { compose, withHandlers, withState } from 'recompose'
import styled from 'react-emotion'
import {
  Currency,
  DatePicker,
  Dropdown,
  Button,
  SpidusManager,
  Checkbox as OldCheckbox,
} from '../../components'
import AnswerGroup from './AnswerGroup'
import * as paths from '../../common/paths'
import State from './State'
import AddDeductButton from './AddDeductButton'
import { withCaption, withRouter, withAnswers } from '../../enhances'
import ErrorModal from '../Step3/ErrorModal'
import { range } from 'lodash'

export const Checkbox = styled(OldCheckbox)`
  .ant-checkbox-wrapper:hover {
    border-color: #ffb933 !important;
  }

  .ant-checkbox-inner {
    border-radius: 5px !important;
    width: 24px !important;
    height: 24px !important;

    ::after {
      top: 1.5px !important;
    }
  }

  .ant-checkbox-wrapper-checked {
    .ant-checkbox-inner {
      border-color: #ffb933 !important;
      background-color: #ffb933 !important;
    }
  }
`

export const Layout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  padding: 0 5px;
`

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;

  > :nth-child(2) {
    margin-top: 27px;
    margin-bottom: 45px;
  }
`

export const HeaderTitle = styled.div`
  font-size: 30px;
  font-weight: bold;
  font-family: Prompt;
  color: #333333;
  margin-top: 47px;
  text-align: center;
`

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 625px;
  margin: 0 auto;
  padding: 0 10px;

  > ${AnswerGroup.Layout} {
    /* margin-top: 24px; */
  }

  ${AnswerGroup.Body} {
    > ${withCaption.Layout} {
      > ${withCaption.Left} {
        flex: 1;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding-right: 5px;
      }
      > ${withCaption.Right} {
        width: 255px;
        flex: unset;
      }
    }
  }

  @media screen and (max-width: 550px) {
    max-width: 400px;

    ${AnswerGroup.Body} {
      > ${withCaption.Layout} {
        flex-wrap: wrap;

        > ${withCaption.Left} {
          width: 100%;
          flex: unset;
          margin-bottom: 8px;
          justify-content: space-between;
          padding-right: 0px;
        }
        > ${withCaption.Right} {
          width: 100%;
          flex: unset;
        }
      }
    }
  }

  @media screen and (min-width: 551px) {
    ${AnswerGroup.Body} {
      > ${withCaption.Layout} {
        position: relative;
        > ${withCaption.Left} {
          > svg {
            position: absolute;
            right: 0px;
          }
        }
        > ${withCaption.Right} {
          margin-right: 30px;
        }
      }
    }
  }

  ${AnswerGroup.Body} {
    > ${withCaption.Layout}.checkbox {
      flex-wrap: unset;
      position: relative;
      > ${withCaption.Left} {
        flex: 1;
        margin-bottom: 0px;

        > svg {
          position: absolute;
          right: 0px;
        }
      }
      > ${withCaption.Right} {
        margin-right: 30px;
        width: auto;
        text-align: right;

        > div {
          margin-bottom: -2px;
        }
      }
    }
  }
`

export const BodyTitle = styled.div`
  font-family: Prompt;
  font-size: 20px;
  color: #333333;
  font-weight: 600;
`

export const BodyDescription = styled.div`
  margin-top: 8px;
  margin-bottom: 32px;
  font-size: 16px;
  color: #333333;
  text-align: center;
`

export const Footer = styled.div`
  margin-top: 64px;
  margin-bottom: 35px;
  display: flex;
  width: 100%;
  max-width: 325px;

  > div {
    flex: 1;

    > button {
      width: 100% !important;
    }
  }
`

export const NextButton = styled(Button)`
  background: #ffb933;
  border-radius: 50px;
  min-height: 48px;
  max-width: 150px;
  font-family: Prompt;
  font-size: 20px;
  color: #333333;
  line-height: 1;

  &:hover,
  &:focus {
    color: #333333 !important;
  }
`

const LineLayout = styled.div`
  width: 100%;
  position: relative;
  margin-top: ${props => props.marginTop};
  margin-bottom: ${props => props.marginBottom};
`

const MainLine = styled.div`
  height: 1px;
  background: #ffb933;
  position: absolute;
  left: -1000px;
  right: -1000px;

  @media screen and (max-width: 550px) {
    left: 0px;
    right: 0px;
  }
`

export const Line = props => (
  <LineLayout {...props}>
    <MainLine />
  </LineLayout>
)

const MainAnswerGroup = styled(AnswerGroup)`
  margin-top: 56px;

  ${AnswerGroup.Body} {
    > * {
      margin-top: 16px;
    }
  }
`

const SubAnswerGroup = styled(AnswerGroup)`
  :not(:first-child) {
    margin-top: 32px !important;
  }

  + * {
    margin-top: 32px !important;
  }

  ${AnswerGroup.Header} {
    text-align: left;
    font-size: 16px;
    font-weight: 500;
    font-family: 'Sarabun', sans-serif;
    color: #333333;
  }
`

const SectionHeader = styled.div`
  width: 100%;
  font-weight: bold;
  font-size: 20px;
  font-family: 'Sarabun', sans-serif;
  color: #333333;
  margin-top: 48px;
  display: flex;
  justify-content: space-between;
`

const Remark = styled.div`
  text-align: center;
  font-size: 16px;
  font-family: 'Sarabun', sans-serif;
  font-style: italic;
  color: #7f7f7f;
  margin-top: 24px !important;
`

const Step1 = props => (
  <SpidusManager configName="noontax_config_web_prepare_tax" enableLoading>
    <Layout>
      <Header>
        <HeaderTitle>เตรียมข้อมูลเพื่อยื่นภาษีปี 2563</HeaderTitle>
        <State step={1} />
      </Header>
      <Body>
        <BodyTitle>ข้อมูลภาษีประจำปี 2563</BodyTitle>
        <BodyDescription>
          กรอกข้อมูลที่จำเป็นเพื่อคำนวณภาษีของคุณ
        </BodyDescription>
        <SectionHeader>รายได้</SectionHeader>
        <MainAnswerGroup caption="ประเภท 40(1)" marginTop="24px">
          <Currency
            answerName="income_40_1_salary_raw"
            caption="เงินเดือน ค่าจ้าง"
            tooltip="เงินเดือน ค่าจ้าง รวมทั้งปี 63 ตามในใบ 50 ทวิ"
            required
          />
          <Currency
            answerName="income_40_1_salary_tax"
            caption="ภาษีหัก ณ ที่จ่าย"
            tooltip="ภาษีหัก ณ ที่จ่าย รวมทั้งปี 63 ตามในใบ 50 ทวิ"
          />
        </MainAnswerGroup>
        <Line marginTop="48px" />
        <SectionHeader>
          ลดหย่อน
          <AddDeductButton
            onClick={() => dataLayer.push({ event: 'edit_tax_deduction' })}
          />
        </SectionHeader>
        <MainAnswerGroup caption="ส่วนตัว" marginTop="20px">
          <DatePicker
            answerName="personal_birthdate"
            caption="วันเกิด"
            required
          />
          <Dropdown
            answerName="personal_handicap_status"
            caption="คนปกติ/คนพิการ/คนทุพพลภาพ"
            tooltip={['ต้องมีเอกสารรับรอง']}
          />
          <Dropdown
            answerName="personal_marriage_status"
            caption="สถานภาพ"
            required
          />
        </MainAnswerGroup>

        <MainAnswerGroup
          caption="ลดหย่อนจากบุคคลในครอบครัว"
          answerName="group_deduct_family"
        >
          <SubAnswerGroup
            answerName="deduct_spouse"
            caption="คู่สมรส"
            deleteAnswerName
          >
            <Checkbox
              answerName="deduct_spouse_used"
              caption="- ลดหย่อนได้ 60,000"
              tooltip={['จดทะเบียนสมรสถูกต้อง', 'คู่สมรสไม่มีรายได้']}
            />
            <Dropdown
              answerName="deduct_spouse_handicap_status"
              caption="- คนปกติ/คนพิการ/คนทุพพลภาพ ?"
              tooltip={['ต้องมีเอกสารรับรอง']}
            />
          </SubAnswerGroup>
          <SubAnswerGroup
            answerName="deduct_children"
            caption="ลูก"
            deleteAnswerName
          >
            <Dropdown
              answerName="deduct_children_count"
              caption="- จำนวนลูกทั้งหมด"
              tooltip={['จำนวนลูกแท้ๆ และลูกบุญธรรมรวมกัน']}
            />
            <Remark>* กรุณากรอกรายละเอียดของลูกทุกคนให้ครบถ้วน</Remark>
            {range(1, 11).map(i => (
              <SubAnswerGroup
                key={i}
                answerName={`deduct_child_${i}`}
                caption={`ลูกคนที่ ${i}`}
              >
                <DatePicker
                  answerName={`deduct_child_${i}_birthdate`}
                  caption="วันเกิด"
                />
                <Dropdown
                  answerName={`deduct_child_${i}_type`}
                  caption="ลูกแท้ๆ/ลูกบุญธรรม ?"
                />
                <Dropdown
                  answerName={`deduct_child_${i}_handicap_status`}
                  caption="คนปกติ/คนพิการ/คนทุพพลภาพ ?"
                  tooltip={['ต้องมีเอกสารรับรอง']}
                />
              </SubAnswerGroup>
            ))}
          </SubAnswerGroup>
          <SubAnswerGroup
            answerName="deduct_dad"
            caption="พ่อ"
            deleteAnswerName
          >
            <Checkbox
              answerName="deduct_dad_used"
              caption="- ลดหย่อนได้ 60,000"
              tooltip={['อายุมากกว่า 60 ปี', 'รายได้ไม่เกิน ฿30,000 ในปี 63']}
            />
            <Dropdown
              answerName="deduct_dad_handicap_status"
              caption="- คนปกติ/คนพิการ/คนทุพพลภาพ ?"
              tooltip={['ต้องมีเอกสารรับรอง']}
            />
          </SubAnswerGroup>
          <SubAnswerGroup
            answerName="deduct_mom"
            caption="แม่"
            deleteAnswerName
          >
            <Checkbox
              answerName="deduct_mom_used"
              caption="- ลดหย่อนได้ 60,000"
              tooltip={['อายุมากกว่า 60 ปี', 'รายได้ไม่เกิน ฿30,000 ในปี 63']}
            />
            <Dropdown
              answerName="deduct_mom_handicap_status"
              caption="- คนปกติ/คนพิการ/คนทุพพลภาพ ?"
              tooltip={['ต้องมีเอกสารรับรอง']}
            />
          </SubAnswerGroup>
          <SubAnswerGroup
            answerName="deduct_sdad"
            caption="พ่อคู่สมรส"
            deleteAnswerName
          >
            <Checkbox
              answerName="deduct_sdad_used"
              caption="- ลดหย่อนได้ 60,000"
              tooltip={['อายุมากกว่า 60 ปี', 'รายได้ไม่เกิน ฿30,000 ในปี 63']}
            />
            <Dropdown
              answerName="deduct_sdad_handicap_status"
              caption="- คนปกติ/คนพิการ/คนทุพพลภาพ ?"
              tooltip={['ต้องมีเอกสารรับรอง']}
            />
          </SubAnswerGroup>
          <SubAnswerGroup
            answerName="deduct_smom"
            caption="แม่คู่สมรส"
            deleteAnswerName
          >
            <Checkbox
              answerName="deduct_smom_used"
              caption="- ลดหย่อนได้ 60,000"
              tooltip={['อายุมากกว่า 60 ปี', 'รายได้ไม่เกิน ฿30,000 ในปี 63']}
            />
            <Dropdown
              answerName="deduct_smom_handicap_status"
              caption="- คนปกติ/คนพิการ/คนทุพพลภาพ ?"
              tooltip={['ต้องมีเอกสารรับรอง']}
            />
          </SubAnswerGroup>
          <SubAnswerGroup
            answerName="deduct_disabled"
            caption="อุปการะผู้พิการ/ทุพพลภาพ"
            deleteAnswerName
          >
            <Dropdown
              answerName="deduct_disabled_handicap_status"
              caption="- คนปกติ/คนพิการ/คนทุพพลภาพ ?"
              tooltip={['ต้องมีเอกสารรับรอง']}
            />
          </SubAnswerGroup>
          <Currency
            answerName="deduct_pregnancy"
            caption="ค่าฝากครรภ์และทำคลอด"
            tooltip={['ไม่เกิน ฿60,000 ต่อท้อง']}
            deleteAnswerName
          />
        </MainAnswerGroup>

        <MainAnswerGroup
          caption="เบี้ยประกันและกองทุนรวม"
          answerName="group_deduct_insurance_and_fund"
        >
          <Currency
            answerName="deduct_life_insurance"
            caption="เบี้ยประกันชีวิตตนเอง"
            tooltip={['รวมทุกกรมธรรม์ที่จ่ายในปี 63']}
            deleteAnswerName
          />
          <Currency
            answerName="deduct_annuity_insurance"
            caption="เบี้ยประกันชีวิตแบบบำนาญตนเอง"
            tooltip={['รวมทุกกรมธรรม์ที่จ่ายในปี 63']}
            deleteAnswerName
          />
          <Currency
            answerName="deduct_health_insurance"
            caption="เบี้ยประกันสุขภาพตนเอง"
            tooltip={[
              'รวมทุกกรมธรรม์ที่จ่ายในปี 63',
              'เฉพาะปี 63 ลดหย่อนสูงสุด 25,000',
            ]}
            deleteAnswerName
          />
          <SubAnswerGroup
            caption="เบี้ยประกันสุขภาพพ่อแม่"
            answerName="deduct_parent_health_insurance"
            deleteAnswerName
          >
            <Currency
              answerName="deduct_parent_health_insurance_dad"
              caption="- เบี้ยประกันสุขภาพพ่อ"
              tooltip={['รวมทุกกรมธรรม์ที่จ่ายในปี 63']}
            />
            <Currency
              answerName="deduct_parent_health_insurance_mom"
              caption="- เบี้ยประกันสุขภาพแม่"
              tooltip={['รวมทุกกรมธรรม์ที่จ่ายในปี 63']}
            />
            <Currency
              answerName="deduct_parent_health_insurance_sdad"
              caption="- เบี้ยประกันสุขภาพพ่อคู่สมรส"
              tooltip={['รวมทุกกรมธรรม์ที่จ่ายในปี 63']}
            />
            <Currency
              answerName="deduct_parent_health_insurance_smom"
              caption="- เบี้ยประกันสุขภาพแม่คู่สมรส"
              tooltip={['รวมทุกกรมธรรม์ที่จ่ายในปี 63']}
            />
          </SubAnswerGroup>
          <Currency
            answerName="deduct_rmf"
            caption="กองทุนรวม RMF"
            tooltip={['รวมทั้งปี 63']}
            deleteAnswerName
          />
          <Currency
            answerName="deduct_ssf"
            caption="กองทุนรวมเพื่อการออม SSF"
            deleteAnswerName
          />
          <Currency
            answerName="deduct_ssf_extra"
            caption="กองทุนรวมเพื่อการออม SSFX"
            deleteAnswerName
          />
        </MainAnswerGroup>

        <MainAnswerGroup
          caption="เงินสะสมในกองทุนเพื่อการเกษียณ"
          answerName="group_deduct_annuity_saving"
        >
          <Currency
            answerName="deduct_sso"
            caption="เงินสมทบกองทุนประกันสังคม"
            tooltip={[
              'เฉพาะส่วนที่คุณจ่าย ไม่รวมส่วนที่นายจ้างสมทบ',
              'รวมทั้งปี 63',
              'เฉพาะปี 63 กรุณาตรวจสอบยอดเงินสมทบรวมทั้งปี 5,850 บาท',
            ]}
            deleteAnswerName
          />
          <Currency
            answerName="deduct_official_fund"
            caption="เงินสะสม กบข."
            tooltip={['รวมทั้งปี 63']}
            deleteAnswerName
          />
          <Currency
            answerName="deduct_teacher_fund"
            caption="เงินสะสมกองทุนครูเอกชน"
            tooltip={['รวมทั้งปี 63']}
            deleteAnswerName
          />
          <Currency
            answerName="deduct_provident_fund"
            caption="เงินสะสมกองทุนสำรองเลี้ยงชีพ"
            tooltip={[
              'เฉพาะส่วนที่คุณจ่าย ไม่รวมส่วนที่นายจ้างสมทบ',
              'รวมทั้งปี 63',
            ]}
            deleteAnswerName
          />
          <Currency
            answerName="deduct_nsf"
            caption="เงินสะสมกองทุนการออมแห่งชาติ"
            tooltip={['รวมทั้งปี 63']}
            deleteAnswerName
          />
        </MainAnswerGroup>

        <MainAnswerGroup caption="เงินบริจาค" answerName="group_deduct_donate">
          <Currency
            answerName="deduct_party_donate"
            caption="เงินบริจาคพรรคการเมือง"
            tooltip={['รวมทั้งปี 63']}
            deleteAnswerName
          />
          <Currency
            answerName="deduct_special_donate"
            caption="เงินบริจาคสนับสนุนการศึกษา/กีฬา"
            tooltip={['รวมทั้งปี 63', 'บริจาค COVID-19 ให้ รพ.รัฐ']}
            deleteAnswerName
          />
          <Currency
            answerName="deduct_general_donate"
            caption="เงินบริจาคอื่น"
            tooltip={[
              'รวมทั้งปี 63',
              'บริจาค COVID-19 ให้สำนักงานปลัดสำนักนายกรัฐมนตรี เท่านั้น',
            ]}
            deleteAnswerName
          />
        </MainAnswerGroup>

        <MainAnswerGroup
          caption="ค่าลดหย่อนจากการซื้อสินค้า/บริการ/ที่อยู่อาศัย"
          answerName="group_deduct_other"
        >
          <SubAnswerGroup
            answerName="deduct_shopping"
            caption="ค่าซื้อสินค้าหรือค่าบริการ ช้อปดีมีคืน (ตั้งแต่วันที่ 23 ต.ค. - 31 ธ.ค. 63)"
            deleteAnswerName
          >
            <Currency
              answerName="deduct_shopping_registered_vat"
              caption="- ค่าซื้อสินค้าและบริการในระบบภาษีมูลค่าเพิ่ม"
              tooltip={[
                'ช่วงวันที่ 23 ต.ค. - 31 ธ.ค. 63',
                'ค่าซื้อสินค้าหรือค่าบริการจากผู้ประกอบการที่จด VAT',
                'ค่าซื้อหนังสือ(รวมแบบ E-book) และสินค้า OTOP ที่ได้รับการจดทะเบียนจากผู้ประกอบการที่ไม่ได้จด VAT',
                'ยกเว้น เครื่องดื่มผสมแอลกอฮอล์ (สุรา เบียร์ และไวน์), ยาสูบ, น้ำมันและก๊าซสำหรับเติมยานพาหนะ',
                'ค่าซื้อพาหนะยานยนต์, หนังสือพิมพ์และนิตยสาร (รวมแบบ E-book), ค่าไกด์นำเที่ยว, ค่าที่พักโรงแรม',
                'ต้องไม่ได้ใช้สิทธิโครงการคนละครึ่ง',
                'ต้องไม่ได้รับสิทธิโครงการเพิ่มกำลังซื้อให้แก่ผู้มีบัตรสวัสดิการแห่งรัฐ',
              ]}
            />
            <Currency
              answerName="deduct_shopping_ebook"
              caption="- หนังสือและ หนังสือที่อยู่ในรูปของข้อมูลอิเล็กทรอนิกส์ผ่านอินเตอร์เน็ต"
              tooltip={['รวมตามในใบเสร็จ']}
            />
            <Currency
              answerName="deduct_shopping_otop"
              caption="- สินค้า OTOP"
              tooltip={['รวมตามในใบเสร็จ']}
            />
          </SubAnswerGroup>

          <Currency
            answerName="deduct_homeloan_interest"
            caption="ดอกเบี้ยกู้ยืมเพื่อที่อยู่อาศัย"
            tooltip={[
              'เฉพาะส่วนที่เป็นดอกเบี้ยตามเอกสารสรุปจากธนาคาร',
              'ถ้ามีการกู้ร่วมต้องหารเฉลี่ยตามจำนวนผู้กู้',
            ]}
            deleteAnswerName
          />
          <SubAnswerGroup
            answerName="deduct_first_house"
            caption="สิทธิลดหย่อนบ้านหลังแรก"
            deleteAnswerName
          >
            <Dropdown
              answerName="deduct_first_house_period"
              caption="- ช่วงเวลาที่ซื้อบ้าน"
            />
            <Currency
              answerName="deduct_first_house_cost"
              caption="- ราคาบ้าน"
            />
          </SubAnswerGroup>
        </MainAnswerGroup>
      </Body>
      <Footer>
        <NextButton onClick={props.next}>ต่อไป</NextButton>
      </Footer>

      <ErrorModal
        isShowModal={props.isShowErrorModal}
        setIsShowModal={props.setIsShowErrorModal}
        onConfirm={props.closeErrorModal}
        children={props.errorMessage}
      />
    </Layout>
  </SpidusManager>
)

const enhancer = compose(
  withRouter(),
  withState('isShowErrorModal', 'setIsShowErrorModal', false),
  withState('errorMessage', 'setErrorMessage', null),
  withAnswers('noontax_config_web_prepare_tax', {
    birthdate: 'personal_birthdate.value',
    marriageStatus: 'personal_marriage_status.value',
    totalIncome: 'income_40_1_salary_raw.value',
  }),
  withHandlers({
    closeErrorModal: props => () => {
      props.setIsShowErrorModal(false)
    },
    next: props => () => {
      dataLayer.push({ event: 'submit_personal_income_deduct' })

      const errors = []
      if (!props.totalIncome) {
        errors.push('กรุณาระบุเงินเดือน ค่าจ้าง')
      }
      if (!props.birthdate) {
        errors.push('กรุณาระบุวันเกิด')
      }
      if (!props.marriageStatus) {
        errors.push('กรุณาระบุสถานะสมรส')
      }

      if (errors.length > 0) {
        dataLayer.push({ event: 'submit_personal_income_deduct_failure' })

        props.setErrorMessage(errors.join(', '))
        props.setIsShowErrorModal(true)
      } else {
        dataLayer.push({ event: 'submit_personal_income_deduct_success' })

        props.history.push(paths.prepareTaxStep2Path())
      }
    },
  })
)

export default enhancer(Step1)
