import { injectGlobal } from 'emotion'
import React from 'react'
import styled, { css } from 'react-emotion'
import { connect } from 'react-redux'
import { compose, withState } from 'recompose'
import { TQM_SHOP_LINK } from '../../core/helper'
import { responsive } from '../../core/style'
import DesktopSize from './DesktopSize'
import ResponsiveSize from './ResponsiveSize'
import { useScrollPosition } from './userScrollPosition'

injectGlobal`
 .ant-popover{
  position:fixed;
 }
  .ant-popover-arrow{
    display: none;
  }

 .ant-popover-inner {
  border-radius: 6px;
    div > .ant-popover-inner-content {
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #265fa7;
      font-size: 16px;
      color: white;
      padding: 0;
      overflow: hidden;

      .userIcon{
        width: 142px;
      }

      .bellIcon{
        width: 300px;
        height: 150px;
        overflow: overlay;
        padding-top: 5px;
      }

      div{
       width:100%;
         .profile,.logout,.notificationContent{
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 50px;
            cursor:pointer;

            span{
              opacity: 0.5;
            }

            :hover{
               background-color: #6A94CB;
               color:white;

               span{
                 opacity: 1;
               }
            }
          }
          .notificationContent{
            height: 60px;

            span{
              opacity: 1;
            }

            :hover{
              background-color: #6A94CB;
            }
          }
      }
    }
  }
`
const Container = styled.header`
  display: ${props => (props.isNoHeader ? 'none' : 'flex')};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100vw;
  align-items: center;
  justify-content: space-between;
  z-index: 999;

  background-color: ${props =>
    props.noOpacityHeader
      ? 'rgba(255, 255, 255, 1)'
      : 'rgba(255, 255, 255, 0.8)'};
  color: black;
  transition: 0.5s;

  ${props =>
    responsive.mobile(css`
      top: ${props.top};
    `)};
`
const NavbarBase = styled.nav`
  line-height: 1;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  padding: 0px;
`
const WrapFixHeader = styled(NavbarBase)``
const FixedHeader = styled(WrapFixHeader)`
  display: none;
  visibility: visible;
  transition: 200ms ease-in;
  @media (max-width: 425px) {
    display: flex;
    transition: 200ms ${props => (props.show ? 'ease-in' : 'ease-out')};
    transform: ${props => (props.show ? 'none' : 'translate(0, -80px)')};
  }
`

const CoronaMenu = styled.b`
  > span {
    color: #d62c3b;
  }

  ${props =>
    responsive.tablet(css`
      > span {
        color: white;
      }
    `)};
`

const menus = [
  {
    link: '/smart',
    title: 'เช็คแบบประกัน',
    hover: 'NOON SMART',
    id: 'menu-noon-smart',
    submenu: [
      {
        link: '/smart/InsuranceCategory/life',
        title: 'ประกันชีวิต',
        id: 'menu-noon-smart-life',
      },
      {
        link: '/smart/InsuranceCategory/health/intermediate',
        title: 'ประกันสุขภาพ',
        id: 'menu-noon-smart-health',
      },
      {
        link: TQM_SHOP_LINK,
        title: 'ประกันรถยนต์',
        id: 'menu-noon-smart-motor',
      },
    ],
  },
  {
    link: TQM_SHOP_LINK,
    title: 'ซื้อออนไลน์',
    hover: 'BUY ONLINE',
    id: 'menu-tqm-shop',
  },
  {
    link: '/square',
    title: 'อ่านบทความ',
    hover: 'NOON BLOG',
    id: 'menu-noon-square',
  },
  {
    link: '/contact-us',
    title: 'ติดต่อเรา',
    hover: 'CONTACT US',
    id: 'menu-contact-us',
  },
]

const PROFILE_MENUS = user =>
  user.loginWith === 'email'
    ? [
        {
          title: 'ข้อมูลส่วนตัว',
          className: 'user-link-list profile',
          link: '/profile/user-profile',
          icon: 'edit',
        },
        {
          title: 'รายการโปรด',
          className: 'user-link-list favorite',
          link: '/profile/favorite',
          icon: 'heart',
        },
        {
          title: 'เปรียบเทียบแบบประกัน',
          className: 'user-link-list compare',
          link: '/profile/compare',
          icon: 'table',
        },
        {
          title: 'แบบประกันที่สนใจ',
          className: 'user-link-list interest',
          link: '/profile/interest',
          icon: 'folder-open',
        },
        {
          title: 'เปลี่ยนรหัสผ่าน',
          className: 'user-link-list password',
          link: '/profile/changed-password',
          icon: 'key',
        },
      ]
    : [
        {
          title: 'ข้อมูลส่วนตัว',
          className: 'user-link-list profile',
          link: '/profile/user-profile',
          icon: 'edit',
        },
        {
          title: 'รายการโปรด',
          className: 'user-link-list favorite',
          link: '/profile/favorite',
          icon: 'heart',
        },
        {
          title: 'เปรียบเทียบแบบประกัน',
          className: 'user-link-list compare',
          link: '/profile/compare',
          icon: 'table',
        },
        {
          title: 'แบบประกันที่สนใจ',
          className: 'user-link-list interest',
          link: '/profile/interest',
          icon: 'folder-open',
        },
      ]

const Header = ({
  user,
  isOnTop,
  noOpacityHeader,
  isNoHeader,
  className,
  hideOnScroll,
  setHideOnScroll,
}) => {
  const profileMenus = PROFILE_MENUS(user)

  useScrollPosition(
    ({ prevPos, currPos }) => {
      const isShow = currPos.y > prevPos.y
      if (isShow !== hideOnScroll) setHideOnScroll(isShow)
    },
    [hideOnScroll],
    null,
    false,
    300
  )
  return (
    <Container
      className={className}
      id="header"
      isOnTop={isOnTop}
      noOpacityHeader={noOpacityHeader}
      isNoHeader={isNoHeader}
    >
      <DesktopSize menus={menus} profileMenus={profileMenus} />
      <ResponsiveSize menus={menus} profileMenus={profileMenus} />
      <FixedHeader id="auto-header" show={hideOnScroll} />
    </Container>
  )
}

const enhancer = compose(
  connect(
    (state, props) => ({
      isNoHeader: state.notFound.isNoHeader,
      user: state.auth.user,
    }),
    {}
  ),
  withState('hideOnScroll', 'setHideOnScroll', true)
)

export default enhancer(Header)
