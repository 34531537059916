import React from 'react'
import { connect } from 'react-redux'
import {
  compose,
  lifecycle,
  withState,
  withHandlers,
  withProps,
  getContext,
} from 'recompose'
import CustomField from '../../share/Field'
import styled from 'react-emotion'
import { numberParser, displayNumber, isEmpty } from '../../../core/helper'
import EditModal from './EditModal'
import PolarChart from './polarChart'
import BuyLifeModal from './BuyLifeModal'
import BuyHealthModal from './BuyHealthModal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import windowSize from '../../../core/react-window-size'
import { API_HOST, httpPost } from '../../../core/httpClient'
import { withForm } from '../enhancers'
import { Field, Button, Currency, Icon as I } from '../components'
import { get } from 'lodash'
import LoginModal from './LoginModal'
import Loading from '../../share/Loading'
import { getNoonQuestionStorageKey, getSessionId } from '..'
import PropTypes from 'prop-types'

const Layout = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 80px;
`
const HeadContentTitle = styled.div`
  font-size: 28px;
  font-weight: 600;
  text-align: center;
  margin-top: -15px;
  margin-bottom: 30px;
  white-space: nowrap;
  @media (max-width: 425px) {
    font-size: 20px;
  }
`
const Contrainer = styled.div`
  width: 100%;
  border: 1px solid #6a94cb;
  border-radius: 5px;
  width: 1000px;
  @media (max-width: 1023px) {
    width: 650px;
  }
  @media (max-width: 689px) {
    max-width: calc(100vw - 10%);
  }
`
const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 46px;
  padding: 10px 30px;
  background: #6a94cb;
  color: white;
  @media (max-width: 689px) {
    height: 60px;
    padding: 0px 10px;
  }
`
const Body = styled.div`
  display: flex;
  min-height: 500px;
  @media (max-width: 1023px) {
    flex-direction: column;
  }
`
const Left = styled.div`
  width: 45%;
  display: ${props => (props.show ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-right: 1px solid #6a94cb;
  @media (max-width: 1023px) {
    width: 100%;
    border-right: none;
    margin-top: 40px;
    padding: 0 50px;
  }

  .highcharts-grid-line {
    stroke: #6a94cb !important;
  }

  @media (max-width: 689px) {
    .highcharts-container {
      text-align: center !important;
      height: 290px !important;
      margin-top: -50px !important;
      > svg {
        width: 360px;
        height: 360px;
      }
    }
  }
`
const Right = styled.div`
  padding: ${props => (props.show ? '20px' : '20px 0px')};
  width: 55%;
  font-size: 16px;
  @media (max-width: 1023px) {
    width: 100%;
    padding: 0 50px;
    margin-bottom: 40px;
  }
  @media (max-width: 689px) {
    padding: 0px;
  }
`
const Col = styled.div`
  // flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  :first-child {
    align-items: flex-start;
  }
`
const CustomButton = styled(Button)`
  border-radius: 20px;
  color: #333333;
  background: #ffb933;
  border: none;
  margin-top: 10px;

  &:hover,
  &:focus,
  &:active {
    color: #333333;
    background: #ffb933;
    border: none;
  }
  &:hover {
    color: white;
  }
`

const Row = styled.div`
  > div {
    flex: 1;
    :nth-child(1) {
      flex: 2;
    }
    :nth-child(2) {
      flex: 1;
      overflow: hidden;
      text-align: center;
      // width:
    }
    :nth-child(3) {
      flex: 1;
      text-align: center;
    }
    :nth-child(4) {
      flex: 1;
    }
  }
`
const WrapButton = styled.div`
  height: 50px;
  display: flex;
  align-items: center;
  margin: 3px 0;
`
const WrapTitle = styled(Row)`
  display: flex;
  @media (max-width: 689px) {
    flex-wrap: wrap;

    > :nth-of-type(1) {
      max-width: 100%;
      min-width: 100%;
    }

    > :nth-of-type(2),
    :nth-of-type(3) {
      flex: 1;
    }

    > :nth-of-type(4) {
      max-width: 100%;
      min-width: 100%;
      border-bottom: 1px solid #265fa7;
    }
  }
`
const WrapSubTitle = styled.div`
  display: flex;
  // flex: 2;
  width: 200px;
  @media (max-width: 689px) {
    border-bottom: 1px solid #ffb933;
  }
`
const Title = styled.div`
  font-weight: 600;
  // // flex: 1;
  // width: ${props => (props.show ? '250px' : '300px')};
  margin-bottom: 20px;
  margin-top: 20px;
  // @media (max-width: 1023px) {
  //   width: ${props => (props.show ? '290px' : '315px')};
  // }
  @media (max-width: 689px) {
    display: flex;
    height: 50px;
    justify-content: center;
    align-items: center;
    &.align-center {
      background: #ffb933;
      opacity: ${props => (props.isSelected ? '1' : '0.5')};
      color: white !important;
      border-radius: 5px 5px 0px 0px;
      margin: 0 5px;
    }
  }
`

const Lable = styled(Col)`
  align-items: flex-start;
  // flex: 1;
  width: 300px;
  @media (max-width: 689px) {
    min-width: 40%;
    /* display: ${props => (props.isSelected ? 'flex' : 'none')}; */
  }
`
const Should = styled(Col)`
  align-items: flex-end;
  margin-right: 10px;
  // width:
  @media (max-width: 689px) {
    display: ${props => (props.isSelected ? 'flex' : 'none')};
    min-width: 50%;
  }
`
const Have = styled(Col)`
  align-items: ${props => (props.show ? 'flex-start' : 'center')};
  @media (max-width: 689px) {
    display: ${props => (props.isSelected ? 'flex' : 'none')};
    min-width: 40%;
    > div {
      max-width: 100%;
      min-width: 100%;
      > div {
        width: unset;
        max-width: 100%;
        min-width: 100%;
      }
    }
  }
`
const RightSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`
const GraphTitle = styled.div``
const Span = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
  margin: 2px 0;
  height: 50px;
`
const TextInput = styled(Currency)`
  justify-content: space-between;
  align-items: center;
  width: 114px;
  margin: unset;
  .label {
    justify-content: flex-start;
    min-width: 250px;
  }
  input {
    text-align: center;
    flex: 1;
    @media (max-width: 689px) {
      border-radius: ${props => (props.show ? '10px 0 0 10px' : '10px')};
    }
  }

  @media (max-width: 689px) {
    input {
      border-radius: ${props => (props.show ? '10px 0 0 10px' : '10px')};
    }
  }
`
const BuyBtn = styled(CustomButton)`
  margin-left: 10px;
  margin-top: 0px;
  display: ${props => (props.show ? 'flex' : 'none')};
  width: 60px;
  justify-content: center;
  @media (max-width: 689px) {
    width: 40px;
    margin: 0;
    height: 40px;
    padding: 10px;
    border-radius: 0px 20px 20px 0px;
  }
`
const TextButton = styled.div`
  @media (max-width: 689px) {
    display: none;
  }
`
const DownloadText = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin-bottom: 8px;

  @media (max-width: 689px) {
    display: none;
  }
`
const EditButton = styled.div`
  background: unset;
  border: unset;
  text-decoration: underline;
  cursor: pointer;
`
const WrapFields = styled(Row)`
  display: flex;
  @media (max-width: 689px) {
    padding: 0 20px;
  }
`
const Icon = styled(FontAwesomeIcon)`
  display: none;
  @media (max-width: 689px) {
    display: block;
  }
`
const DivBtn = styled.div`
  display: ${props => (props.show ? 'block' : 'none')};
  @media (max-width: 689px) {
    display: ${props => (props.disabled ? 'none' : 'block')};
    max-width: fit-content;
  }
`
const HeaderTitle = styled.div``
const BuyMore = props => {
  return (
    <BuyBtn
      show={props.show}
      onClick={() => props.open(true)}
      disabled={props.disabled}
    >
      <TextButton>ซื้อเพิ่ม</TextButton>
      <Icon icon={['fa', 'shopping-cart']} />
    </BuyBtn>
  )
}
const lable = {
  female: 'หญิง',
  male: 'ชาย',
}

const renderField = props => {
  const {
    input: { value },
  } = props
  const keyLabels = {
    lifeSumInsuranced: 'ชีวิต',
    decrepitudeSumInsuranced: 'ทุพพลภาพ',
    roomOrFoodSumInsuranced: 'ค่าห้อง/ ค่าอาหาร',
    totalMedicalFeeSumInsuranced: 'ค่ารักษาพยาบาล เหมาจ่าย',
    diseaseSumInsuranced: 'โรคร้ายแรง',
    accidentSumInsuranced: 'อุบัติเหตุ',
    outpationSumInsuranced: 'ผู้ป่วยนอก',
    compensationSumInsuranced: 'ค่าชดเชยรายได้',
  }
  return (
    <>
      {Object.keys(keyLabels).map(key => {
        const money = parseInt(value[key]) < 0 ? 0 : value[key]
        return <Span key={key}>{displayNumber(parseInt(money))}</Span>
      })}
    </>
  )
}

const WrapperSaveButton = styled.div`
  width: 100%;
  margin-top: 5px;
`

const CalculateButton = styled(Button)`
  width: 100% !important;
  @media (max-width: 689px) {
    display: none;
  }
`

const MobileCalculateButton = styled(Button)`
  display: none;
  @media (max-width: 689px) {
    display: block;
  }
`

const Summary = props => (
  <>
    <Loading isLoading={props.isLoading}>
      <Layout>
        <HeadContentTitle>
          สรุปสิ่งที่คุณควรมี เมื่อคุณต้องการเกษียร อายุ{' '}
          {props.values.retireAge} ปี
        </HeadContentTitle>
        <Contrainer>
          <Header>
            <HeaderTitle>
              สำหรับเพศ {props.genderLabel} อายุ {props.values.age} ปี
              โดยวางแผนว่าจะเกษียณเมื่ออายุครบ {props.values.retireAge} ปี...
            </HeaderTitle>
            <EditButton onClick={props.openEditModal}>แก้ไข</EditButton>
          </Header>
          <Body>
            <Left show={props.calculated}>
              <GraphTitle>ระดับความคุ้มครองที่มีแล้ว</GraphTitle>
              <PolarChart data={props.values} />
            </Left>
            <Right show={props.calculated}>
              <WrapTitle>
                <Title show={props.calculated}>ความคุ้มครอง</Title>
                {/* <WrapSubTitle> */}
                <Title
                  className="align-center"
                  isSelected={props.tab === 'should'}
                  onClick={() =>
                    props.windowWidth < 450 && props.setTabSelect('should')
                  }
                >
                  ควรมี (บาท)
                </Title>
                <Title
                  className="align-center"
                  isSelected={props.tab === 'have'}
                  onClick={() =>
                    props.windowWidth < 450 && props.setTabSelect('have')
                  }
                >
                  มีแล้ว (บาท)
                </Title>
                <div />
              </WrapTitle>
              <WrapFields>
                <Lable>
                  <Span>
                    {props.windowWidth < 450 ? 'ชีวิต' : 'ความคุ้มครองชีวิต'}
                    <a
                      href="https://www.noon.in.th/blog/details/3-step-to-select-right-Sum-Assured"
                      target="_blank"
                    >
                      <I
                        icon="info-circle"
                        style={{ marginLeft: 5, color: '#7F7F7F' }}
                      />
                    </a>
                  </Span>
                  <Span>
                    {props.windowWidth < 450
                      ? 'ทุพพลภาพ'
                      : 'ความคุ้มครองทุพพลภาพ'}
                  </Span>
                  <Span>
                    {props.windowWidth < 450
                      ? 'ค่าห้อง/ ค่าอาหาร'
                      : 'ความคุ้มครองค่าห้อง/ ค่าอาหาร'}
                  </Span>
                  <Span>
                    {props.windowWidth < 450
                      ? 'ค่ารักษาพยาบาลเหมาจ่าย'
                      : 'ความคุ้มครองค่ารักษาพยาบาลเหมาจ่าย'}
                  </Span>
                  <Span>
                    {props.windowWidth < 450
                      ? 'โรคร้ายแรง'
                      : 'ความคุ้มครองโรคร้ายแรง'}
                  </Span>
                  <Span>
                    {props.windowWidth < 450
                      ? 'อุบัติเหตุ'
                      : 'ความคุ้มครองอุบัติเหตุ'}
                  </Span>
                  <Span>
                    {props.windowWidth < 450
                      ? 'ผู้ป่วยนอก'
                      : 'ความคุ้มครองผู้ป่วยนอก'}
                  </Span>
                  <Span>
                    {props.windowWidth < 450
                      ? 'ค่าชดเชยรายได้'
                      : 'ความคุ้มครองค่าชดเชยรายได้'}
                  </Span>
                </Lable>
                <Should isSelected={props.tab === 'should'}>
                  <Field name="recommend" type="text" component={renderField} />
                </Should>
                <Have isSelected={props.tab === 'have'} show={props.calculated}>
                  <Span>
                    <Field
                      name="lifeSumInsuranced"
                      component={TextInput}
                      placeholder="ระบุ"
                      show={process}
                    />
                  </Span>
                  <Span>
                    <Field
                      name="decrepitudeSumInsuranced"
                      component={TextInput}
                      placeholder="ระบุ"
                    />
                  </Span>
                  <Span>
                    <Field
                      name="roomOrFoodSumInsuranced"
                      component={TextInput}
                      placeholder="ระบุ"
                      show={process}
                    />
                  </Span>
                  <Span>
                    <Field
                      name="totalMedicalFeeSumInsuranced"
                      component={TextInput}
                      placeholder="ระบุ"
                    />
                  </Span>
                  <Span>
                    <Field
                      name="diseaseSumInsuranced"
                      component={TextInput}
                      placeholder="ระบุ"
                    />
                  </Span>
                  <Span>
                    <Field
                      name="accidentSumInsuranced"
                      component={TextInput}
                      placeholder="ระบุ"
                    />
                  </Span>
                  <Span>
                    <Field
                      name="outpationSumInsuranced"
                      component={TextInput}
                      placeholder="ระบุ"
                    />
                  </Span>
                  <Span>
                    <Field
                      name="compensationSumInsuranced"
                      component={TextInput}
                      placeholder="ระบุ"
                    />
                  </Span>
                  <WrapperSaveButton>
                    <CalculateButton onClick={props.saveOrOpenModal}>
                      <TextButton>บันทึก</TextButton>
                      <Icon icon={['fa', 'save']} />
                    </CalculateButton>
                  </WrapperSaveButton>
                </Have>
                {/* <RightSection> */}
                <DivBtn
                  show={
                    (props.calculated &&
                      props.lifeSumInsurancedRecommend > 0) ||
                    props.roomOrFoodSumInsurancedRecommend > 0
                  }
                  disabled={props.tab === 'should'}
                >
                  <WrapButton>
                    <BuyMore
                      open={props.setOpenBuyLifeModal}
                      show={
                        props.calculated && props.lifeSumInsurancedRecommend > 0
                      }
                      disabled={props.lifeSumInsurancedRecommendRemaining === 0}
                    />
                  </WrapButton>
                  <WrapButton />
                  <WrapButton>
                    <BuyMore
                      open={props.setOpenBuyHealthModal}
                      show={
                        props.calculated &&
                        props.roomOrFoodSumInsurancedRecommend > 0
                      }
                      disabled={
                        props.roomOrFoodSumInsurancedRecommendRemaining === 0
                      }
                    />
                  </WrapButton>
                </DivBtn>
                {/* <DownloadText>ดาวน์โหลด</DownloadText> */}
                {/* </RightSection> */}
              </WrapFields>
              <MobileCalculateButton onClick={props.saveOrOpenModal}>
                <TextButton>บันทึก</TextButton>
                <Icon icon={['fa', 'save']} />
              </MobileCalculateButton>
            </Right>
          </Body>
        </Contrainer>
      </Layout>
    </Loading>

    <EditModal
      show={props.isShowModal}
      initialValues={{ ...props.values }}
      onClose={props.setIsShowModal}
      onSubmit={props.calculate}
    />
    <BuyLifeModal
      isShowModal={props.openBuyLifeModal}
      setIsShowModal={props.setOpenBuyLifeModal}
      age={props.values.age}
      gender={props.values.gender}
      max={props.lifeSumInsurancedRecommendRemaining}
    />
    <BuyHealthModal
      isShowModal={props.openBuyHealthModal}
      setIsShowModal={props.setOpenBuyHealthModal}
      age={props.values.age}
      gender={props.values.gender}
      max={props.roomOrFoodSumInsurancedRecommendRemaining}
    />
    <LoginModal
      isShowModal={props.isShowLoginModal}
      setIsShowModal={props.setShowLoginModal}
    />
  </>
)

const enhancer = compose(
  withForm({
    form: 'NoonQuestion',
    // destroyOnUnmount: false,
    // enableReinitialize: true,
    // keepDirtyOnReinitialize: true,
  }),
  withState('isShowModal', 'setIsShowModal', false),
  withState('openSignInModal', 'setSignInModal', false),
  withState('openBuyLifeModal', 'setOpenBuyLifeModal', false),
  withState('openBuyHealthModal', 'setOpenBuyHealthModal', false),
  withState('isShowLoginModal', 'setShowLoginModal', false),
  withState('tab', 'setTabSelect', 'should'),
  withState('calculated', 'setCalculated', true),
  withState('isLoading', 'setLoading', false),
  getContext({
    saveValues: PropTypes.func,
  }),
  connect(
    state => ({ isLogin: state.auth.isLogin }),
    {}
  ),
  withHandlers({
    calculate: props => async values => {
      await props.initialize(values)

      const response = await httpPost(`noon_questions/calculate`, values)

      props.change('recommend', response.data.data.recommend)
      props.setCalculated(true)
    },
  }),
  withHandlers({
    openEditModal: props => () => props.setIsShowModal(true),
    closeEditModal: props => () => props.setIsShowModal(false),
    openLoginModal: props => () => props.setShowLoginModal(true),
    closeLoginModal: props => () => props.setShowLoginModal(false),
  }),
  withHandlers({
    saveOrOpenModal: props => () => {
      if (props.isLogin) {
        props.saveValues()
      } else {
        props.openLoginModal()
      }
    },
  }),
  withProps(props => ({
    lifeSumInsurancedRecommend: get(
      props.values,
      'recommend.lifeSumInsuranced',
      0
    ),
    roomOrFoodSumInsurancedRecommend: get(
      props.values,
      'recommend.roomOrFoodSumInsuranced',
      0
    ),
    genderLabel: { female: 'หญิง', male: 'ชาย' }[props.values.gender],
    recommend: props.values.recommend || {},
  })),
  withProps(props => ({
    lifeSumInsurancedRecommendRemaining: Math.max(
      (props.recommend.lifeSumInsuranced || 0) -
        (props.values.lifeSumInsuranced || 0),
      0
    ),
    roomOrFoodSumInsurancedRecommendRemaining: Math.max(
      (props.recommend.roomOrFoodSumInsuranced || 0) -
        (props.values.roomOrFoodSumInsuranced || 0),
      0
    ),
  })),
  lifecycle({
    componentDidMount() {
      let values = this.props.values
      if (isEmpty(values)) {
        values = JSON.parse(localStorage.getItem(getNoonQuestionStorageKey()))
      }
      this.props.calculate(values)
    },
  })
)

export default windowSize(enhancer(Summary))
