import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import styled, { css } from 'react-emotion'

import LoadingAnimation from '../../../static/images/loading.gif'
import Modal from '../../share/Modal'
import OICModalStep from './assets/images/oic_modal.png'
import { Breadcrumb, Button } from './components'
import MyPolicyTable from './MyPolicyTable'

import { isBlank } from '../../../core/helper'
import { responsive } from '../../../core/style'
import enhancer from './MyPolicy.compose'

const MyPolicy = ({
  isShowModal = false,
  isLoading = false,
  inputLink = '',
  policyList = [],
  onDelete = () => {},
  onInputChange = () => {},
  onSubmitLink = () => {},
  setIsShowModal = () => {},
}) => {
  return (
    <React.Fragment>
      <Container>
        <Header>
          <Breadcrumb
            items={[
              { label: 'ข้อมูลของฉัน', link: '/profile/user-profile' },
              { label: 'ข้อมูลกรมธรรม์ คปภ.' },
            ]}
          />
          <HeaderText>กรุณาแนบลิงก์ของคุณข้างล่างนี้</HeaderText>
          <InputContainer>
            <Input
              disabled={isLoading}
              onChange={onInputChange}
              placeholder="วางลิงก์ที่นี่"
              value={inputLink}
            />
            <CustomButton
              onClick={onSubmitLink}
              disabled={isBlank(inputLink) || isLoading}
            >
              <StyledIcon icon={['fas', 'link']} />
            </CustomButton>
          </InputContainer>
          <Note>หมายเหตุ : ระบบจะใช้รหัสผ่านจากข้อมูลที่คุณเคยให้ไว้</Note>
        </Header>
        <Wrapper>
          <TableHeader>
            <TableText>ข้อมูลกรมธรรม์ คปภ.</TableText>
            <Button
              title="ขั้นตอนการใช้งาน"
              type="secondary"
              onClick={() => setIsShowModal(true)}
            />
          </TableHeader>
          <TableContainer>
            {isLoading && (
              <ContainerLoading>
                <img src={LoadingAnimation} />
              </ContainerLoading>
            )}
            {policyList.length > 0 ? (
              <MyPolicyTable policyList={policyList} onDelete={onDelete} />
            ) : (
              <EmptyTable>ยังไม่มีข้อมูล</EmptyTable>
            )}
          </TableContainer>
        </Wrapper>
      </Container>
      <CustomModal isShowModal={isShowModal} setIsShowModal={setIsShowModal}>
        <Image
          style={{ marginBottom: '0px' }}
          src={OICModalStep}
          alt="show step to get your policy from oic line official"
        />
      </CustomModal>
    </React.Fragment>
  )
}

export default enhancer(MyPolicy)

const Container = styled.div`
  position: relative;
  width: 100%;
  margin-top: -30px;
`
const Header = styled.div`
  width: 100%;
  background-color: #0a2240;
  padding-bottom: 30px;
`
const HeaderText = styled.div`
  margin-bottom: 27px;
  font-size: 28px;
  font-weight: bold;
  line-height: 34px;
  text-align: center;
  color: #ffd900;
`
const InputContainer = styled.div`
  display: flex;
  direction: row;
  gap: 8px;
  width: 100%;
  max-width: 626px;
  margin: 0 auto 20px;
`
const Input = styled.input`
  display: flex;
  width: 100%;
  padding: 0 24px;
  font-size: 18px;
  line-height: 22px;
  border-radius: 4px;
  border: none;
`
const CustomButton = styled(Button)`
  width: 96px;
  height: 80px;
`
const StyledIcon = styled(FontAwesomeIcon)`
  font-size: 24 !important;
  color: #707070 !important;
`
const Note = styled.div`
  width: 100%;
  max-width: 626px;
  margin: 0 auto;
  font-size: 18px;
  line-height: 27px;
  color: #fff;
`
const Wrapper = styled.div`
  width: 100%;
  max-width: 1042px;
  margin: 0 auto;
  padding: 60px 16px 30px;
`
const TableHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
`
const TableText = styled.div`
  font-size: 28px;
  font-weight: bold;
  line-height: 34px;
  color: #101820;
`
const ContainerLoading = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 999;
  background: rgba(255, 255, 255, 0.8);
`
const TableContainer = styled.div`
  position: relative;
  width: 100%;
`
const EmptyTable = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 240px;

  font-size: 18px;
  line-height: 27px;
  text-align: center;
  color: #707070;
`
const Image = styled.img`
  display: block;
  width: 100%;
  margin: 0 auto;
  cursor: pointer;
`
const CustomModal = styled(Modal)`
  &.ant-modal {
    width: unset !important;
    display: flex;
    justify-content: center;
    margin: 0 calc((100vw - 800px) / 2);
  }
  .ant-modal-content {
    width: 100%;
    padding: 45px 0 35px 0;
  }

  ${props =>
    responsive.mobile(css`
      &.ant-modal {
        width: 100% !important;
        margin: unset;
      }
    `)};
`
