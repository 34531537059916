import React from 'react'
import styled, { css } from 'react-emotion'
import { compose, withHandlers } from 'recompose'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { unFavoriteWpBlog } from '../../../ducks/noonSquare'
import { connect } from 'react-redux'

import { responsive } from '../../../core/style'
const HeartIcon = styled(FontAwesomeIcon)`
  font-size: 20px;
  color: red;
  margin-left: 34px;
  cursor: pointer;

  ${props =>
    responsive.mobile(css`
      margin-left: 0;
    `)};
`

const Favorite = ({
  id,
  onUnFavoriteWpBlog
}) => (
    <React.Fragment>
      <HeartIcon
        id={`wpblog-favorite-${id}`}
        onClick={() => { onUnFavoriteWpBlog() }}
        icon={['fas', 'heart']}
      />
    </React.Fragment>
  )

const enhancer = compose(
  connect(null,
    { unFavoriteWpBlog },
  ),
  withHandlers({
    onUnFavoriteWpBlog: ({ id, unFavoriteWpBlog }) => () => {
      unFavoriteWpBlog(id)
      //window.location.reload()
    },
  }),
)

export default enhancer(Favorite)
