import React from 'react'
import styled from 'react-emotion'
import { connect } from 'react-redux'
import { navigate } from '@reach/router'
import { obj2Url, url2Obj } from '../../core/helper'
import { compose, lifecycle, withState } from 'recompose'

const ScrollWrapper = styled.div`
  overflow: overlay;
  width: 100vw;
  display: flex;
  align-items: center;
  ::-webkit-scrollbar {
    display: none;
  }
`

const Container = styled.div`
  z-index: 1;

  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
  margin-bottom: 20px;
  margin: 15px auto 20px;
`

const Tag = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  height: 25px;
  margin: 0 10px;
  padding: 0 17px;

  background-color: ${props =>
    props.selected ? props.theme.LIGHTORANGE : '#6A94CB'};
  border-radius: 5px;
  cursor: pointer;

  font-size: 12px;
  white-space: nowrap;
  color: ${props => (props.selected ? 'black' : 'white')};
`

const tagList = [
  { id: '11', tagName: 'โควิด19', analyticName: 'covid19' },
  { id: '3', tagName: 'ไลฟ์สไตล์', analyticName: 'lifestyle' },
  { id: '4', tagName: 'ข่าว', analyticName: 'news' },
  { id: '5', tagName: 'OIC', analyticName: 'oic' },
  { id: '9', tagName: 'ท่องเที่ยว', analyticName: 'travel' },
  { id: '2', tagName: 'ธุรกิจ', analyticName: 'business' },
  { id: '1', tagName: 'Fintech', analyticName: 'fintech' },
  { id: '6', tagName: 'CIT', analyticName: 'cit' },
  { id: '10', tagName: 'กฏหมาย', analyticName: 'law' },
  { id: '8', tagName: 'ตัวแทนขายประกัน', analyticName: 'agent' },
  { id: '7', tagName: 'โบรกเกอร์', analyticName: 'broker' },
]

const SuggestTag = ({
  fetchArticles,
  params,
  interestTagList,
  setInterestList,
}) => {
  return (
    <ScrollWrapper>
      <Container>
        {tagList.map(value => (
          <Tag
            id={`square-${value.analyticName}`}
            selected={interestTagList.find(val => val === value.id)}
            key={value.id}
            onClick={() => {
              const buffer = [...interestTagList]
              const checkMatch = buffer.find(val => val === value.id)
              if (!checkMatch) {
                buffer.push(value.id)
                setInterestList(buffer)
                setTimeout(
                  navigate(
                    `/square?${obj2Url({
                      ...params,
                      interestTags: buffer,
                    })}`
                  )
                )
                fetchArticles({ ...params, interestTags: buffer })
              } else if (checkMatch) {
                const deleteBuffer = buffer.filter(val => val !== value.id)
                setInterestList(deleteBuffer)
                setTimeout(
                  navigate(
                    `/square?${obj2Url({
                      ...params,
                      interestTags: deleteBuffer,
                    })}`
                  )
                )
                fetchArticles({ ...params, interestTags: deleteBuffer })
              }
            }}
          >
            {value.tagName}
          </Tag>
        ))}
      </Container>
    </ScrollWrapper>
  )
}
const enhancer = compose(
  connect(
    (state, props) => ({
      params: url2Obj(props.location.search) || null,
    }),
    {}
  ),
  withState('interestTagList', 'setInterestList', []),
  lifecycle({
    componentDidMount() {
      const {
        location: { search },
        setInterestList,
      } = this.props
      const interestTags = url2Obj(search).interestTags
      if (interestTags) {
        if (typeof interestTags === 'string') {
          const buffer = []
          buffer.push(interestTags)
          setInterestList(buffer)
        } else {
          setInterestList(interestTags)
        }
      }
    },
  })
)

export default enhancer(SuggestTag)
