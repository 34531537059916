import React from 'react'
import styled, { css } from 'react-emotion'
import { compose, withHandlers, lifecycle } from 'recompose'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import { navigate } from '@reach/router'

import Field from '../share/Field'
import Button from '../share/Button'
import Loading from '../share/Loading'
import noon from '../../static/images/Noon-prod-by-tqld.png'

import { isEmail } from '../../core/helper'
import { responsive } from '../../core/style'

import { setData } from '../../ducks/404'
import { register } from '../../ducks/eventRegistering'

const LANG = 'en'

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  margin-top: -80px;
`

const Form = styled.form`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  ${props =>
    responsive.mobile(css`
      width: 250px;
      margin: 0 auto;
    `)};
`

const Logo = styled.img`
  margin-bottom: 30px;
`

const GenderSelector = styled(Field)`
  width: 100%;
  > div {
    > div {
      justify-content: center;
      .male {
        width: 125px;
      }
      .female {
        width: 125px;
      }
    }
  }
  .label {
    min-width: 100px;
  }

  ${props =>
    responsive.mobile(css`
      margin-bottom: 10px;
    `)};
`

const GoogleInput = styled(Field)`
  margin: 15px 0;

  ${props =>
    responsive.mobile(css`
      margin: 10px 0;
    `)};
`

const SubmitButton = styled(Button)`
  width: 100px;
  height: 30px;
  margin: 20px 0;
  font-size: 12px;
`

const validate = values => {
  const errors = {}
  if (!values.name) {
    errors.name =
      LANG === 'th' ? 'ต้องใส่ข้อมูลในช่องนี้' : 'Please fill out this field.'
  }
  if (!values.email) {
    errors.email =
      LANG === 'th' ? 'ต้องใส่ข้อมูลในช่องนี้' : 'Please fill out this field.'
  } else if (!isEmail(values.email)) {
    errors.email =
      LANG === 'th' ? 'รูปแบบอีเมลไม่ถูกต้อง' : 'E-mail is invalid.'
  }
  return errors
}

const Register = ({ valid, isLoading, onSubmit }) => (
  <Container>
    <Loading isLoading={isLoading}>
      <Form onSubmit={onSubmit} noValidate>
        <Logo title="noon.in.th" src={noon} onClick={() => navigate('/')} />
        <GenderSelector name="gender" type="switch" lang={LANG} />
        <GoogleInput
          name="name"
          type="googleInput"
          placeholder={LANG === 'th' ? 'ชื่อ - นามสกุล' : 'Name'}
          hideError
        />
        <GoogleInput
          name="email"
          type="googleInput"
          placeholder={LANG === 'th' ? 'อีเมล' : 'E-mail'}
          hideError
        />
        <SubmitButton htmlType="submit" icons={['fa', 'pen']} disabled={!valid}>
          {LANG === 'th' ? 'ลงทะเบียน' : 'submit'}
        </SubmitButton>
      </Form>
    </Loading>
  </Container>
)

const enhancer = compose(
  connect(
    state => ({
      isLoading: state.eventRegistering.isLoading,
      initialValues: {
        gender: 'male',
      },
    }),
    { setData, register }
  ),
  reduxForm({ form: 'event_registering', validate }),
  withHandlers({
    onSubmit: ({ reset, register, handleSubmit }) =>
      handleSubmit(value => register(value, reset, LANG)),
  }),
  lifecycle({
    componentDidMount() {
      this.props.setData({ isNoHeader: true, isNoFooter: true })
    },
    componentWillUnmount() {
      this.props.setData({ isNoHeader: false, isNoFooter: false })
    },
  })
)

export default enhancer(Register)
