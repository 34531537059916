import React from 'react'
import {connect} from 'react-redux'
import styled from 'react-emotion'
import {compose, withState, lifecycle, withHandlers} from 'recompose'
import {getAllLeadCountLanding, setData} from '../../ducks/landing'

const BottomBanner = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  background-color: rgba(38, 95, 167, 0.8);
  height: 160px;
  color: white;
  margin-top: 5px;
`

const ContainerNumberInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Topic = styled.h1`
  margin: 0;

  font-size: 30px;
  color: white;
`

const Between = styled.div`
  width: 5px;
  height: 64px;
  background-color: white;
  border-radius: 5px;
  border-color: white;
`

const ContainerNumber = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 600px;
`

const Info = styled.h2`
  font-size: 12px;
  color: white;
  text-align: center;
  padding: 9px 0 9px 0;
  margin: 0;
`

const nFormatter = num => {
  if (num >= 1000000000) {
    return (num / 1000000000).toFixed(2).replace(/\.0$/, '') + ' G'
  }
  if (num >= 1000000) {
    return (num / 1000000).toFixed(2).replace(/\.0$/, '') + ' M'
  }
  if (num >= 100000) {
    return (num / 1000).toFixed(0).replace(/\.0$/, '') + ' K'
  }
  if (num >= 10000) {
    return (num / 1000).toFixed(1).replace(/\.0$/, '') + ' K'
  }
  if (num >= 1000) {
    return (num / 1000).toFixed(2).replace(/\.0$/, '') + ' K'
  }

  return parseInt(num)
}

const CountNumber = ({count1, count2, count3}) => (
  <BottomBanner>
    <ContainerNumber>
      <ContainerNumberInfo>
        <Topic>{nFormatter(count1)}</Topic>
        <Info>จำนวนแบบประกัน</Info>
      </ContainerNumberInfo>
      <ContainerNumberInfo>
        <Between />
      </ContainerNumberInfo>
      <ContainerNumberInfo>
        <Topic>{nFormatter(count2)}</Topic>
        <Info>ครั้งที่ลูกค้าให้ความสนใจ</Info>
      </ContainerNumberInfo>
      <ContainerNumberInfo>
        <Between />
      </ContainerNumberInfo>
      <ContainerNumberInfo>
        <Topic>{nFormatter(count3)}</Topic>
        <Info>มูลค่าเบี้ยประกันในระบบ</Info>
      </ContainerNumberInfo>
    </ContainerNumber>
  </BottomBanner>
)

const enhancer = compose(
  connect(
    (state, props) => ({
      lead: state.landing.lead,
      premium: state.landing.premium,
      policies: state.landing.policies,
    }),
    {getAllLeadCountLanding, setData},
  ),
  withState('count1', 'setCount1', 0),
  withState('count2', 'setCount2', 0),
  withState('count3', 'setCount3', 0),
  withState('runner', 'setRunner', {id: ''}),
  withHandlers({
    setNewCount: ({
      count1,
      count2,
      count3,
      setCount1,
      setCount2,
      setCount3,
      runner,
      lead,
      premium,
      policies,
    }) => () => {
      if (runner.id === '') {
        runner.id = setInterval(() => {
          if (count1.toFixed(2) === parseFloat(policies).toFixed(2)) {
            clearInterval(runner.id)
          } else {
            const num1 = (count1 += policies / 25)
            const num2 = (count2 += lead / 25)
            const num3 = (count3 += premium / 25)
            setCount1(num1.toFixed(2))
            setCount2(num2.toFixed(2))
            setCount3(num3.toFixed(2))
          }
        }, 25)
      }
    },
  }),
  lifecycle({
    componentWillReceiveProps(nextProps) {
      const {setNewCount, lead, premium, policies} = this.props
      if (
        lead !== nextProps.lead ||
        premium !== nextProps.premium ||
        policies !== nextProps.policies
      ) {
        this.props.runner.id = ''
        setNewCount()
      }
    },
    componentDidMount() {
      const {getAllLeadCountLanding, setData} = this.props
      this.props.runner.id = ''
      setData({lead: 0, premium: 0, policies: 0})
      getAllLeadCountLanding()
    },
  }),
)

export default enhancer(CountNumber)
