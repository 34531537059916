import React from 'react'
import {compose, withState} from 'recompose'
import styled from 'react-emotion'
import {connect} from 'react-redux'
import {API_HOST} from '../../../core/httpClient'

import {Upload, message, Modal} from 'antd'
import humps from 'humps'

const UploadContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`

const UploadButtonContainer = styled.div`
  position: relative;
`

const Uploading = styled.img`
  width: 32px;
  height: 32px;
  position: absolute;
  top: 0;
  right: 0;
`

const PolicyImage = styled.img`
  width: 200px;
  height: 300px;
  margin-bottom: 20px;
`

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}

const UploadImage = ({
  isUploading,
  setIsUploading,
  isUpload,
  setIsUpload,
  setFormData,
  setSearchKey,
  initializeFormData,
  insuranceCompanyName,
  onRemove,
}) => (
  <UploadContainer>
    <UploadButtonContainer>
      <Upload
        accept="image/*"
        name="file"
        action={API_HOST + 'insurance_ocr_images'}
        listType="picture-card"
        disabled={isUploading}
        onPreview={async file => {
          let src = file.url
          if (!src) {
            src = await new Promise(resolve => {
              const reader = new FileReader()
              reader.readAsDataURL(file.originFileObj)
              reader.onload = () => resolve(reader.result)
            })
          }
          const image = new Image()
          image.src = src
          const imgWindow = window.open(src)
          imgWindow.document.write(image.outerHTML)
        }}
        onRemove={file => {
          onRemove(file)
          setIsUpload(false)
          return true
        }}
        onChange={info => {
          if (info.file.status === 'uploading') {
            setIsUploading(true)
          }
          if (info.file.status === 'done') {
            setIsUploading(false)
            setIsUpload(true)
            message.success(`${info.file.name} file uploaded successfully`)
            const data = humps.camelizeKeys(info.file.response.data.result.data)
            console.log('response', data)
            const insurer = (
              insuranceCompanyName.find(
                c => c.referenceCode === info.file.response.data.result.insurer,
              ) || {}
            ).value
            setSearchKey(data.policyName)
            initializeFormData({
              ...data,
              policiesSearch: 'none',
              insuranceCompanyName: insurer,
            })
            setFormData('policiesSearch', 'none')
          } else if (info.file.status === 'error') {
            setIsUploading(false)
            setIsUpload(true)
            message.error(`${info.file.name} file upload failed.`)
          }
        }}>
        {!isUpload && !isUploading && 'อัพโหลดหน้ากรมธรรม์'}
      </Upload>
    </UploadButtonContainer>
  </UploadContainer>
)

const enhance = compose(
  withState('isUploading', 'setIsUploading', false),
  withState('isUpload', 'setIsUpload', false),
  connect(state => ({
    insuranceCompanyName: state.profile.insuranceCompany,
  })),
)

export default enhance(UploadImage)
