import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import styled from 'react-emotion'

const BUTTON_SIZE = {
  small: {
    padding: '12px 16px',
    fontSize: '14px',
    lineHeight: '18px',
  },
  medium: {
    padding: '14px 18px',
    fontSize: '16px',
    lineHeight: '21px',
  },
  large: {
    padding: '14px 20px',
    fontSize: '18px',
    lineHeight: '24px',
  },
}

const BUTTON_STYLE = {
  primary: {
    color: '#000',
    backgroundColor: '#FFD900',
    border: 'none',
    hoverColor: '#000',
    hoverBackgroundColor: '#FFE922',
    hoverBorder: 'none',
    disabledColor: '#64748B',
    disabledBackgroundColor: '#A7B0BF',
    disabledBorder: 'none',
  },
  secondary: {
    color: '#0a2240',
    backgroundColor: '#fff',
    border: '1px solid #0a2240',
    hoverColor: '#1054a9',
    hoverBackgroundColor: '#fff',
    hoverBorder: '1px solid #1054a9',
    disabledColor: '#a7b0bf',
    disabledBackgroundColor: '#a7b0bf',
    disabledBorder: '1px solid #a7b0bf',
  },
}

const Button = ({
  children = null,
  className = '',
  block = false,
  disabled = false,
  htmlType = 'button',
  icon = null,
  iconPosition = 'right',
  size = 'medium',
  title = '',
  type = 'primary',
  onClick = () => {},
}) => {
  const buttonSize = BUTTON_SIZE[size]
  const buttonStyle = BUTTON_STYLE[type]

  const render = children ? (
    children
  ) : (
    <React.Fragment>
      {title}
      {icon ? (
        <StyledIcon
          icon={icon}
          buttonSize={buttonSize}
          buttonStyle={buttonStyle}
        />
      ) : (
        <React.Fragment />
      )}
    </React.Fragment>
  )

  return (
    <StyledButton
      block={block}
      buttonSize={buttonSize}
      buttonStyle={buttonStyle}
      buttonType={type}
      className={className}
      disabled={disabled}
      iconPosition={iconPosition}
      size={size}
      type={htmlType}
      onClick={onClick}
    >
      {render}
    </StyledButton>
  )
}

export default Button

const StyledButton = styled.button`
  display: flex;
  flex-direction: ${props =>
    props.iconPosition === 'right' ? 'row' : 'row-reverse'};
  justify-content: center;
  align-items: center;
  gap: 8px;
  width: ${props => (props.block ? '100%' : 'fit-content')};
  padding: ${props => props.buttonSize.padding};

  font-size: ${props => props.buttonSize.fontSize};
  line-height: ${props => props.buttonSize.lineHeight};
  color: ${props => props.buttonStyle.color};
  background-color: ${props => props.buttonStyle.backgroundColor};
  border: ${props => props.buttonStyle.border};
  border-radius: 4px;

  * {
    color: ${props => props.buttonStyle.color};
  }

  :hover {
    color: ${props => props.buttonStyle.hoverColor};
    background-color: ${props => props.buttonStyle.hoverBackgroundColor};
    border: ${props => props.buttonStyle.hoverBorder};

    * {
      color: ${props => props.buttonStyle.hoverColor};
    }
  }

  :disabled {
    color: ${props => props.buttonStyle.disabledColor};
    background-color: ${props => props.buttonStyle.disabledBackgroundColor};
    border: ${props => props.buttonStyle.disabledBorder};
    cursor: not-allowed;

    * {
      color: ${props => props.buttonStyle.disabledColor};
    }
  }

  transition: all 0.3s;
`
const StyledIcon = styled(FontAwesomeIcon)`
  font-size: ${props => props.buttonSize.fontSize} !important;
  color: ${props => props.buttonStyle.color} !important;
`
