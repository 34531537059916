import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from '@reach/router'
import React from 'react'
import styled from 'react-emotion'

const Breadcrumb = ({ className = '', items = [] }) => {
  if (items.length > 0) {
    return (
      <Container className={className}>
        {items.map((item, idx) => {
          if (item.link) {
            return (
              <React.Fragment key={idx}>
                <StyledLink to={item.link}>
                  <TextLink>{item.label}</TextLink>
                </StyledLink>
                {idx !== items.length - 1 ? (
                  <>
                    <StyledIcon icon={['fas', 'chevron-right']} />
                  </>
                ) : (
                  <></>
                )}
              </React.Fragment>
            )
          }
          return (
            <React.Fragment key={idx}>
              <TextLink>{item.label}</TextLink>
            </React.Fragment>
          )
        })}
      </Container>
    )
  }
  return <React.Fragment />
}

export default Breadcrumb

const Container = styled.ul`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2px;
  padding: 16px;
  margin-bottom: 0;
`
const StyledLink = styled(Link)`
  color: #ff9015;
  :active,
  :hover {
    color: #ff9015;
  }
  :focus {
    color: #ff9015;
    text-decoration: none;
  }
`
const TextLink = styled.span`
  color: #ff9015;
  font-size: 18px
  line-height: 24px;
`
const StyledIcon = styled(FontAwesomeIcon)`
  font-size: 14px !important;
  width: 18px !important;
  height: 18px !important;
  color: #ff9015 !important;
`
