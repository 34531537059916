import React from 'react'
import styled, { css } from 'react-emotion'
import { responsive } from '../../core/style'

import ASSET_LOGO from '../../static/images/ASSET.png'
import VIRIYAH_LOGO from '../../static/images/VIRIYAH.png'
import DHIPAYA_LOGO from '../../static/images/DHIPAYA.png'
import BKI_LOGO from '../../static/images/BKI.png'

const BrMobile = styled.br`
  display: none;

  ${props =>
    responsive.mobile(css`
      display: block;
    `)};
`

export const ASSET = {
  company: {
    ref: 'ASSET',
    name: 'สินทรัพย์ประกันภัย',
    logo: ASSET_LOGO,
    subHeader: (
      <React.Fragment>
        จ่ายให้ทันทีหากถูกวินิจฉัยโดยแพทย์ว่าติดเชื้อ
        <BrMobile />
        ไวไรโคโรนาสายพันธุ์ใหม่
        <br />
        สูงสุดถึง 100,000 บาท ค่าเบี้ยประกันเริ่มต้นเพียง 99 บาท/ปี
      </React.Fragment>
    ),
  },
  color: {
    // primary: '#F9EFBE',
    // secondary: '#FCF9EC',
    // spare: '#FFFDF3',
    // button: '#98862D',
    primary: '#F9EFBE',
    secondary: '#FCF9EC',
    spare: '#FFFDF3',
    button: '#98862D',
  },
  packages: [
    {
      label: 'สินทรัพย์ประกันภัย 99 บาท/ปี',
      value: 29926,
      price: 99,
      details: {
        found: `15,000`,
        lost: `4,000`,
        age: `1-99 ปี`,
      },
    },
    {
      label: 'สินทรัพย์ประกันภัย 199 บาท/ปี',
      value: 29927,
      price: 199,
      details: {
        found: `30,000`,
        lost: `5,000`,
        age: `1-99 ปี`,
      },
    },
    {
      label: 'สินทรัพย์ประกันภัย 299 บาท/ปี',
      value: 29928,
      price: 299,
      details: {
        found: `50,000`,
        lost: `6,000`,
        age: `1-99 ปี`,
      },
    },
    {
      label: 'สินทรัพย์ประกันภัย 499 บาท/ปี',
      value: 29929,
      price: 499,
      details: {
        found: `100,000`,
        lost: `8,000`,
        age: `1-99 ปี`,
      },
    },
  ],
  condition: [
    'ผู้เอาประกันภัย มีอายุระหว่าง 1-99 ปี',
    'ไม่คุ้มครองสภาพที่เป็นมาก่อนการเอาประกันภัย (Pre-Existing Condition)',
    'รับเฉพาะสัญชาติไทย และอยู่ในอาณาเขตประเทศไทยเท่านั้น',
    'ไม่คุ้มครองอุบัติเหตุ ที่เกิดจากการขับขี่ และโดยสารรถจักรยานยนต์',
    'ไม่ต้องตรวจสุขภาพก่อนทำประกันภัย',
    'ระยะเวลารอคอย 14 วัน นับจากวันที่กรมธรรม์มีผลบังคับเป็นครั้งแรก',
    'ไม่รับประกันผู้เดินทางมาจากประเทศกลุ่มเสี่ยงตามประกาศของกระทรวงสาธารณสุข ในระยะ 14 วันก่อนวันเริ่มคุ้มครอง',
    'สงวนสิทธิ์ในการซื้อ 1 คน ต่อ 1 กรมธรรม์ ในทุกช่องทางการขายของบริษัทฯ (กรณีลูกค้าซื้อเกิน 1 กรมธรรม์ หรือมีการซื้อซ้ำทางบริษัทฯ ขอสงวนสิทธิ์ในการยกเลิกกรมธรรม์)',
    'ลูกค้าที่ซื้อประกัน Covid ของ AIC ไปแล้วใน Phase1 จะไม่สามารถซื้อใน Phase 2 นี้ได้',
    'ลูกค้าไม่สามารถซื้อทั้ง 2 package ใน Phase 2 พร้อมกันได้ เช่น จะซื้อเจอ จ่าย จบ และ ซื้อ ค่ารักษาด้วย ไม่ได้ จะต้องซื้อเพียง Package ใด Package หนึ่งเท่านั้น',
  ],
}

export const VIRIYAH = {
  company: {
    ref: 'VIRIYAH',
    name: 'วิริยะประกันภัย',
    logo: VIRIYAH_LOGO,
    subHeader: (
      <React.Fragment>
        จ่ายให้ทันทีเมื่อเจ็บป่วยขั้นวิกฤต(ภาวะโคม่า)
        <BrMobile />
        จากเชื้อไวรัสโคโรนา (COVID-19)
        <BrMobile />
        สูงสุด 50,0000 บาท
      </React.Fragment>
    ),
  },
  color: {
    // primary: '#FED7CE',
    // secondary: '#F8E9E6',
    // spare: '#FFEDE8',
    // button: '#C26149',
    // primary: '#F9EFBE',
    // secondary: '#FCF9EC',
    // spare: '#FFFDF3',
    // button: '#98862D',
    primary: '#CDDCFC',
    secondary: '#E3E8F3',
    spare: '#E3E8F3',
    button: '#4C6AAB',
  },
  packages: [
    // {
    //   label: 'วิริยะประกันภัย 455 บาท/ปี',
    //   value: 28726,
    //   price: 455,
    //   details: {
    //     found: `50,000`,
    //     admit: (
    //       <React.Fragment>
    //         500 บ./วัน
    //         <br />
    //         <span>(สูงสุด 14 วัน)</span>
    //       </React.Fragment>
    //     ),
    //     lost: `100,000`,
    //     age: `15 วัน - 99 ปี`,
    //   },
    // },
    // {
    //   label: 'วิริยะประกันภัย 555 บาท/ปี',
    //   value: 28727,
    //   price: 555,
    //   details: {
    //     found: `70,000`,
    //     admit: (
    //       <React.Fragment>
    //         700 บ./วัน
    //         <br />
    //         <span>(สูงสุด 14 วัน)</span>
    //       </React.Fragment>
    //     ),
    //     lost: `100,000`,
    //     age: `15 วัน - 99 ปี`,
    //   },
    // },
    {
      label: 'วิริยะประกันภัย 439 บาท/ปี',
      value: 28985,
      price: 439,
      details: {
        critical: `300,000`,
        hs: (
          <React.Fragment>
            30,000 บ.
            <br />
            <span>(เฉพาะผู้ป่วยใน)</span>
          </React.Fragment>
        ),
        admit: (
          <React.Fragment>
            500 บ./วัน
            <br />
            <span>(สูงสุด 14 วัน)</span>
          </React.Fragment>
        ),
        lost: `100,000`,
        age: `15 วัน - 75 ปี`,
      },
    },
    {
      label: 'วิริยะประกันภัย 599 บาท/ปี',
      value: 28986,
      price: 599,
      details: {
        critical: `500,000`,
        hs: (
          <React.Fragment>
            50,000 บ.
            <br />
            <span>(เฉพาะผู้ป่วยใน)</span>
          </React.Fragment>
        ),
        admit: (
          <React.Fragment>
            500 บ./วัน
            <br />
            <span>(สูงสุด 14 วัน)</span>
          </React.Fragment>
        ),
        lost: `50,000`,
        age: `15 วัน - 75 ปี`,
      },
    },
  ],
  condition: [
    'ช่วงอายุรับประกันภัย ตั้งแต่ 15 วัน - 75 ปี',
    'ผู้เอาประกันภัยต้องไม่มีการเดินทางเข้าหรือออกนอกราชอาณาจักรไทยเป็นระยะเวลา 14 วัน ก่อนวันที่ซื้อกรมธรรม์',
    'สงวนสิทธิ์รับประกันเฉพาะผู้ที่มีสัญชาติไทยและต้องพำนักอาศัยอยู่ในประเทศไทยเท่านั้น',
    'สงวนสิทธิ์ไม่รับประกันภัยสำหรับผู้ที่มี หรือเคยมี หรือเคยได้รับการวินิจฉัยว่ามีการเจ็บป่วยด้วยโรคติดเชื้อไวรัสโคโรนา 2019 (COVID-19)(รวมถึงภาวะแทรกซ้อน) ที่เกิดขึ้นก่อนการเอาประกันภัย',
    'สงวนสิทธิ์การซื้อ 1 ท่าน ต่อ 1 กรมธรรม์เท่านั้น (ให้นับรวมการซื้อจากทุกช่องทางและทุกโครงการที่ทำปรักันภัย เพื่อคุ้มครองการติดเชื้อไวรัสโคโรนา (โควิด-19) ผ่านทางบริษัทฯ) ในกรณีตรวจพบการทำประกันภัยไม่ตรงตามเงื่อนไข บริษัทขอสงวนสิทธิ์ในการยกเลิกกรมธรรม์โดยมีการคืนเบี้ยประกันภัยเต็มจำนวน',
    'ไม่คุ้มครองการเจ็บป่วยโดยโรคที่มีสาเหตุหรือภาวะแทรกซ้อนที่เกิดจากการติดเชื้อไวรัสโคโรนา (โควิด-19) ที่เกิดขึ้นในระยะเวลารอคอย 14 วันแรกนับจากวันที่กรมธรรม์เริ่มต้นความคุ้มครอง',
    'ไม่คุ้มครองการเจ็บป่วยโดยโรคที่มีสาเหตุหรือภาวะแทรกซ้อนที่เกิดจากการติดเชื้อไวรัสโคโรนา (โควิด-19) ที่เกิดขึ้นก่อนวันที่กรมธรรม์จะมีผลบังคับ (Pre-Existing)',
    'ข้อตกลงคุ้มครองกรณีการเสียชีวิตจากอุบัติเหตุจะไม่มีระยะเวลารอคอย',
  ],
}

export const DHIPAYA = {
  company: {
    ref: 'DHIPAYA',
    name: 'ทิพยประกันภัย',
    logo: DHIPAYA_LOGO,
    subHeader: (
      <React.Fragment>
        คุ้มครองค่ารักษาพยาบาลสูงสุด 100,000 บาท
        <BrMobile />
        และคุ้มครองคุณกรณีที่เจ็บป่วยขั้นวิกฤต
        <BrMobile />
        (ภาวะโคม่า) จากเชื้อไวรัสโคโรนา
        <BrMobile />
        (COVID-19) สูงสุดถึง 1 ล้านบาท
      </React.Fragment>
    ),
  },
  color: {
    // primary: '#E4DDFB',
    // secondary: '#ECEAF3',
    // spare: '#F4F2FB',
    // button: '#7262AB',
    primary: '#FED7CE',
    secondary: '#F8E9E6',
    spare: '#FFEDE8',
    button: '#C26149',
  },
  packages: [
    {
      label: 'ทิพยประกันภัย 450 บาท/ปี',
      value: 28746,
      price: 450,
      details: {
        hs: `30,000`,
        critical: `300,000`,
        admit: (
          <React.Fragment>
            300 บ./วัน
            <br />
            <span>(สูงสุด 30 วัน)</span>
          </React.Fragment>
        ),
        age: `1-99 ปี`,
      },
    },
    {
      label: 'ทิพยประกันภัย 950 บาท/ปี',
      value: 28747,
      price: 950,
      details: {
        hs: `70,000`,
        critical: `700,000`,
        admit: (
          <React.Fragment>
            700 บ./วัน
            <br />
            <span>(สูงสุด 30 วัน)</span>
          </React.Fragment>
        ),
        age: `1-99 ปี`,
      },
    },
    {
      label: 'ทิพยประกันภัย 1,250 บาท/ปี',
      value: 28748,
      price: 1250,
      details: {
        hs: `100,000`,
        critical: `1,000,000`,
        admit: (
          <React.Fragment>
            1,000 บ./วัน
            <br />
            <span>(สูงสุด 30 วัน)</span>
          </React.Fragment>
        ),
        age: `1-99 ปี`,
      },
    },
  ],
  condition: [
    'ผู้เอาประกันภัย มีอายุระหว่าง 1-99 ปี',
    'รับเฉพาะสัญชาติไทยและอยู่ในอาณาเขตประเทศไทยเท่านั้น',
    'กรมธรรม์ประกันภัยฉบับนี้ไม่คุ้มครองผู้ที่ติดเชื้อ หรือเป็นผู้ที่สงสัยว่าจะติดเชื้อ “โรคติดเชื้อไวรัสโคโรนา (Coronavirus (2019-nCoV))” (รวมถึงภาวะแทรกซ้อน) ที่เป็นอยู่ก่อนการเอาประกันภัยและยังมิได้รักษาให้หายขาด',
    'เจ็บป่วยระยะสุดท้าย เป็นการเจ็บป่วยระยะรุนแรงที่แพทย์ลงความเห็นว่าไม่มีวิธีการรักษาให้หายได้ และ/หรือ เป็นสาเหตุทำให้เสียชีวิตโดยมีสาเหตุหลักมาจากการติดเชื้อไวรัสโคโรนา',
    'สงวนสิทธิ การรับประกัน สำหรับผู้ที่เดินทางกลับจากประเทศจีน ไม่เกิน 14 วัน',
    'ความคุ้มครองเป็นไปตามข้อกำหนดและเงื่อนไขกรมธรรม์ประกันภัยไวรัสโคโรนา',
    'ขอสงวนสิทธิ์ 1 หมายเลขบัตรประชาชน ต่อ 1 กรมธรรม์ ต่อ 1 บริษัทประกันภัย',
    'ระยะรอคอย (waiting Period)14วันนับจากวันที่กรมธรรม์มีผลเริ่มคุ้มครอง',
  ],
}

// export const BKI = {
//   company: {
//     ref: 'BKI',
//     name: 'กรุงเทพประกันภัย',
//     logo: BKI_LOGO,
//     subHeader: (
//       <React.Fragment>
//         คุ้มครองคุณด้วยวงเงินค่ารักษาสูงสุด <BrMobile />
//         2,000,000 บาท
//         <br />
//         หากเข้าสู่ภาวะโคม่าที่มีสาเหตุหลักมาจาก
//         <BrMobile />
//         ติดเชื้อไวรัสโคโรนารับทันทีสูงสุด 100,000 บาท
//       </React.Fragment>
//     ),
//   },
//   color: {
//     primary: '#CDDCFC',
//     secondary: '#E3E8F3',
//     spare: '#E3E8F3',
//     button: '#4C6AAB',
//   },
//   packages: [
//     {
//       label: 'กรุงเทพประกันภัย 799 บาท/ปี',
//       value: 28572,
//       price: 799,
//       details: {
//         hs: `100,000`,
//         critical: `1,000,000`,
//         lost: `100,000`,
//         age: `1-99 ปี`,
//       },
//     },
//   ],
//   condition: [
//     'ไม่คุ้มครองสภาพที่เป็นมาก่อนการเอาประกันภัย (Pre-Existing Condition)',
//     'สำหรับบุคคลสัญชาติไทยและพักอาศัยอยู่ในประเทศไทยเท่านั้น',
//     'ไม่คุ้มครองผู้ที่มีประวัติการเดินทางในประเทศกลุ่มเสี่ยงตามประกาศของกระทรวงสาธารณะสุข ในระยะเวลา 14 วันก่อนวันเริ่มคุ้มครอง',
//     'ไม่คุ้มครองผู้ที่ถูกกักกัน หรือผู้ต้องสงสัยจากการติดเชื้อ COVID-19 ก่อนทำประกัน',
//     'ระยะเวลารอคอย 14 วัน นับจากวันที่กรมธรรม์เริ่มมีผลบังคับเป็นครั้งแรก',
//     'ไม่คุ้มครองการเสียชีวิต สูญเสียอวัยวะ สายตา  ทุพพลภาพสิ้นเชิงจากอุบัติเหตุขณะขับขี่ หรือโดยสารรถจักรยานยนต์',
//     'ขอสงวนสิทธิ์ในการเปลี่ยนแปลงและแก้ไข รายละเอียดแบบประกัน ซึ่งอาจจะมีการเปลี่ยนแปลงจากผู้รับประกันโดยตรง',
//   ],
// }

export const Products = [DHIPAYA]
