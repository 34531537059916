import React from 'react'
import styled, {css} from 'react-emotion'
import {reduxForm, formValueSelector} from 'redux-form'
import {compose, lifecycle, withState} from 'recompose'
import {connect} from 'react-redux'
import withSEO from '../share/withSEO'

import Button from '../share/Button'
import Field from '../share/Field'
import {FacebookLoginButton} from './FacebookAuth'
import GoogleAuth from './GoogleAuth'

import {login, setData, facebookLogin} from '../../ducks/auth'
import {isEmail} from '../../core/helper'
import {responsive} from '../../core/style'

const CustomButton = styled(Button)`
  min-width: 200px;
  height: 30px;
  margin-top: 20px;
  font-size: 12px;

  ${props =>
    responsive.tablet(css`
      height: 40px;
    `)}
`

const GoogleInput = styled(Field)`
  flex-direction: column;
  margin: 0 0 30px;
  .input-googleInput-container {
    input {
      width: 250px;
      height: 40px;
    }
  }
  &.password {
    .input-googleInput-container {
      input {
        font-size: 16px;
      }
    }
  }
`

const Password = styled(GoogleInput)`
  margin: 0 0 15px;
`

const ContainerField = styled.form`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const Footer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 38px 0 74px;
  font-size: 12px;
`

const CustomTagA = styled.a`
  color: ${props => props.theme.BLUE};
  text-decoration: underline;
`

const CutomResendEmail = styled(CustomTagA)`
  margin-left: 5px;
  font-size: 12px;
  margin-top: 10px;
`

const Login = ({
  className,
  handleSubmit,
  login,
  modal,
  errorMessage,
  closeModal,
  facebookLogin,
  valid,
}) => {
  return (
    <ContainerField
      className={className}
      onSubmit={handleSubmit(value => {
        login(value, modal, closeModal)
      })}
      noValidate>
      <GoogleInput
        name="email"
        type="googleInput"
        placeholder="อีเมล"
        hideError
      />
      <Password
        name="password"
        type="googleInput"
        mode="password"
        placeholder="รหัสผ่าน"
        className="password"
        hideError
      />
      {errorMessage === 'ยังไม่ได้ทำการยืนยันอีเมล' && (
        <CutomResendEmail href={`/authorize/resendEmail`} target="_blank">
          กรุณาทำการยืนยันอีเมล
        </CutomResendEmail>
      )}
      <CustomButton
        id="login-email"
        htmlType="submit"
        disabled={!valid}
        errorMessage={errorMessage}
        icons={['fa', 'user-circle']}>
        เข้าสู่ระบบ
      </CustomButton>
      <FacebookLoginButton
        id="login-facebook"
        name={'เข้าสู่ระบบด้วย Facebook'}
        facebookLogin={facebookLogin}
        modal={modal}
        closeModal={closeModal}
      />
      <GoogleAuth
        id="login-google"
        name="เข้าสู่ระบบด้วย Google"
        modal={modal}
        closeModal={closeModal}
      />
      <Footer>
        <span>
          ต้องการความช่วยเหลือ?&nbsp;
          <CustomTagA href={`/authorize/forgetPassword`} target="_blank">
            ลืมรหัสผ่าน
          </CustomTagA>
          &nbsp;|&nbsp;
          <CustomTagA href={`/contact-us`} target="_blank">
            ติดต่อเรา
          </CustomTagA>
        </span>
      </Footer>
    </ContainerField>
  )
}

const validate = values => {
  const errors = {}

  if (!values.email) {
    errors.email = 'ต้องใส่ข้อมูลในช่องนี้'
  } else if (!isEmail(values.email)) {
    errors.email = 'รูปแบบอีเมลไม่ถูกต้อง'
  }
  if (!values.password) {
    errors.password = 'ต้องใส่ข้อมูลในช่องนี้'
  }

  return errors
}

const selector = formValueSelector('login')

const enhancer = compose(
  withSEO(
    'เข้าสู่ระบบ - วางแผน คัดเลือก หาข้อมูลแบบประกันภัยเฉพาะคุณ',
    'ลงทะเบียนเพื่อเริ่มทำการวางแผนการเลือกซื้อแบบประกันที่เหมาะสมของคุณที่นี่',
    'ลงทะเบียน,เข้าสู่ระบบ,เลือกประกัน,ซื้อประกัน',
  ),
  connect(
    (state, props) => ({
      email: selector(state, 'email'),
      password: selector(state, 'password'),
      errorMessage: state.auth.errorMessage,
    }),
    {login, setData, facebookLogin},
  ),
  reduxForm({
    form: 'login',
    validate,
    onChange: (values, _, props) => {
      props.errorMessage !== '' && props.setData({errorMessage: ''})
    },
  }),
  lifecycle({
    componentDidMount() {
      this.props.setData({errorMessage: ''})
    },
    componentWillUnmount() {
      this.props.setData({errorMessage: ''})
    },
  }),
)

export default enhancer(Login)
