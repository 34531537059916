import {takeLatest, put, takeEvery} from 'redux-saga/effects'
import {createReducer, Creator} from '../helper'
import {httpPost, httpGet, API_HOST} from '../../core/httpClient'
import {change} from 'redux-form'

import {setDataProfile} from './index'

const SET_OCR_IMAGE_LOADING = 'SET_OCR_IMAGE_LOADING'
const PRE_FILL_FORM_DATA = 'PREFILL_FORM_DATA'
const MATCH_INSURANCE_POLICY_ID = 'MATCH_INSURANCE_POLICY_ID'

export const setLoading = Creator(SET_OCR_IMAGE_LOADING, 'isLoading')
export const preFillFormData = Creator(PRE_FILL_FORM_DATA, 'form', 'data')
export const matchInsurancePolicyId = Creator(
  MATCH_INSURANCE_POLICY_ID,
  'companyId',
  'policyName',
  'form',
)

export function* preFillFormDataSaga({payload: {form, data}}) {
  console.log('prefill', form, data)
  yield Object.keys(data).map(key => put(change(form, key, data[key])))
  yield put(
    matchInsurancePolicyId(data.insuranceCompanyName, data.policyName, form),
  )
}

export function* getInsurancePolicySaga({
  payload: {companyId, policyName, form},
}) {
  try {
    const policiyList = yield httpGet(`${API_HOST}insurance_policies/search/`, {
      insuranceCompanyId: companyId,
      type: 'life',
    })
    const policiyListData = policiyList.data.data.insurancePolicies
    let policyId = null
    let policyType = null
    policiyListData.forEach(policy => {
      if (policy.name === policyName) {
        policyId = policy.insurancePolicyId
        policyType = policy.insuranceType
      }
    })
    if (policyId) {
      yield put(change(form, 'insuranceType', policyType))
      yield put(
        setDataProfile({
          insuranceSearchList: [{label: policyName, value: policyId}],
        }),
      )
      yield put(change(form, 'policiesSearch', policyId))
    }
  } catch (e) {
    console.log(e)
  }
}

export function* watchOcrPortSaga() {
  yield takeEvery(PRE_FILL_FORM_DATA, preFillFormDataSaga)
  yield takeLatest(MATCH_INSURANCE_POLICY_ID, getInsurancePolicySaga)
}

const initial = {
  isLoading: false,
}

export default createReducer(initial, state => ({
  [SET_OCR_IMAGE_LOADING]: ({isLoading}) => ({...state, isLoading}),
}))
