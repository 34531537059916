import React from 'react'
import styled, { css } from 'react-emotion'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { compose, withState, lifecycle, withHandlers } from 'recompose'

import Button from '../share/Button'
import DidYouKnow from './DidYouKnow'
import Circle from '../share/Circle'
import AppearanceComponent from '../share/AppearanceComponent'
import people1 from '../../static/images/people_action_1landing.svg'
import people2 from '../../static/images/people_action_2landing.svg'
import people3 from '../../static/images/people_action_3landing.svg'
import people4 from '../../static/images/people_action_4landing.svg'

import { responsive } from '../../core/style'

const Container = styled.div`
  max-width: 600px;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  ${props =>
    responsive.mobile(css`
      background-color: white;
      max-width: unset;
      width: 100%;
    `)};
`

const Img = styled.img`
  position: absolute;
  height: 200px;
  width: 200px;
`

const Img2 = styled(Img)`
  height: 250px;
  width: 245px;
  top: -70px;
`

const Img3 = styled(Img2)`
  top: -50px;

  ${props =>
    responsive.mobile(css`
      top: -22px;
      height: 200px;
    `)};
`

const Img4 = styled(Img)`
  top: -5px;
  height: 185px;
`

const ContainerImg = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 200px;
  width: 200px;

  ${props =>
    responsive.mobile(css`
      margin: 0 auto;
    `)};
`

const Topic = styled.h1`
  width: 100%;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  margin: 93px 0 0;
`

const TopicLeft = styled(Topic)`
  text-align: left;
  margin-top: 0;

  ${props =>
    responsive.mobile(css`
      text-align: center;
      margin: 15px 0;
    `)};
`

const TopicRight = styled(TopicLeft)`
  width: unset;
  text-align: right;
  margin-right: 180px;

  ${props =>
    responsive.mobile(css`
      text-align: center;
      margin-right: 0;
    `)};
`

const Info = styled.p`
  width: 100%;
  margin: 5px 0 0;

  ${props =>
    responsive.mobile(css`
      width: 300px;
    `)};
`

const InfoLeft = styled.p`
  width: 315px;
  margin: 5px 0 0;

  ${props =>
    responsive.mobile(css`
      width: 300px;
    `)};
`

const InfoRight = styled(InfoLeft)``

const ReadMore = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 30px;
  margin-top: 10px;
`

const CustomIcon = styled(FontAwesomeIcon)`
  color: ${props => props.theme.YELLOW};
`

const ContainerNoon = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 70px;
  .noonscore {
    margin-top: 15px;
  }

  ${props =>
    responsive.mobile(css`
      flex-direction: column-reverse;

      max-width: 285px;
      width: 100%;
      margin: 20px auto;
    `)};
`

const ContainerNoonReverse = styled(ContainerNoon)`
  ${props =>
    responsive.mobile(css`
      flex-direction: column;
    `)};
`

const ContainerNoonInfo = styled.div`
  display: flex;
  flex-direction: column;

  ${props =>
    responsive.mobile(css`
      max-width: 285px;
      width: 100%;
      margin: 0 auto;
    `)};
`

const Content = ({ count, setCount, setNewCount, runner, setRunner }) => (
  <Container>
    <ContainerNoonInfo>
      <Topic>ทำไมต้อง NOON?</Topic>
      <Info>
        เพราะเรารู้ว่าแบบประกันที่ใช่ใครๆก็อยากได้
        เราจึงพยายามทำความรู้จักคุณผ่านคำถามต่างๆ
        เพื่อคัดสรรแบบประกันที่เหมาะสมกับคุณมากที่สุด
        ยิ่งคุณตอบรายละเอียดตรงกับความเป็นจริงมากเท่าไร
        เราก็สามารถแนะนำสิ่งที่เหมาะสมให้กับคุณได้มากขึ้นเท่านั้น
      </Info>
    </ContainerNoonInfo>
    <ContainerNoon>
      <ContainerNoonInfo>
        <TopicLeft>NOON SMART</TopicLeft>
        <InfoLeft>
          ระบบที่จะช่วยคุณคัดสรรและวางแผนเรื่องประกัน
          อีกทั้งยังได้รวบรวมแบบประกันมากมายไว้ให้คุณได้ค้นหา คัดเลือก
          และเปรียบเทียบ ว่าสิ่งไหนที่ใช่ที่สุดสำหรับคุณ
        </InfoLeft>
      </ContainerNoonInfo>
      <ContainerImg>
        <Img src={people1} />
      </ContainerImg>
    </ContainerNoon>
    <ContainerNoonReverse>
      <ContainerImg>
        <Img2 src={people3} />
      </ContainerImg>
      <ContainerNoonInfo>
        <TopicRight>NOON TAX</TopicRight>
        <InfoRight>
          เครื่องมือที่จะช่วยคุณวางแผนภาษี และเตรียมข้อมมูลยื่นภาษีให้ง่ายขึ้น
          ครบถ้วนทุกสิทธิประโยชน์ลดหย่อนสูงสุดตามที่สรรพากรกำหนด
        </InfoRight>
      </ContainerNoonInfo>
    </ContainerNoonReverse>
    <ContainerNoon>
      <ContainerNoonInfo>
        <TopicLeft>NOON BLOG</TopicLeft>
        <InfoLeft>
          Blog รวบรวมบทความเกี่ยวกับประกัน และการเงินที่คุณไม่ควรพลาด
          เปลี่ยนเรื่องยากให้เข้าใจง่าย ครบทุกสาระสำคัญ พร้อมบทความอื่นๆ
          ที่เข้ากับทุกไลฟ์สไตล์เครื่องมือที่จะช่วยคุณวางแผนภาษี
          และเตรียมข้อมมูลยื่นภาษีให้ง่ายขึ้น
          ครบถ้วนทุกสิทธิประโยชน์ลดหย่อนสูงสุดตามที่สรรพากรกำหนด
        </InfoLeft>
      </ContainerNoonInfo>
      <ContainerImg>
        <Img3 src={people2} />
      </ContainerImg>
    </ContainerNoon>
    {/* <ContainerNoonReverse>
      <ContainerImg>
        <Img4 src={people4} />
      </ContainerImg>
      <ContainerNoonInfo>
        <TopicRight>NOON SEARCH</TopicRight>
        <InfoRight>
          ตัวช่วยให้คุณได้เจอสิ่งที่สนใจง่ายๆ เพียงแค่ใส่ keyword
          ที่คุณต้องการทราบ แต่หากหัวข้อไหนที่เรายังไม่มี คุณสามารถกด ”ฝากถาม”
          แล้วใส่ชื่อกับอีเมลไว้ เราจะรีบหาคำตอบแล้วส่งไปให้คุณโดยเร็ว
        </InfoRight>
      </ContainerNoonInfo>
    </ContainerNoonReverse> */}
    <ContainerNoon>
      <ContainerNoonInfo>
        <TopicLeft>
          NOON STAR <CustomIcon icon={['fas', 'star']} /> คืออะไร?
        </TopicLeft>
        <Info>
          ระบบอันชาญฉลาดที่จะช่วยบอกระดับความเหมาะสมของแบบประกันสำหรับตัวคุณ
        </Info>
        {/* <ReadMore type="border">อ่านเพิ่มเติม</ReadMore> */}
      </ContainerNoonInfo>
    </ContainerNoon>
    <ContainerNoon>
      <ContainerNoonInfo className="noonscore">
        <TopicLeft>NOON SCORE</TopicLeft>
        <InfoLeft>
          นวัตกรรมทางด้านประกัน ที่บอกรายละเอียดเชิงลึกของแบบประกัน
          โดยข้อมูลในมิติอื่นๆ มาใช้ในการคำนวณ
          เพื่อเป็นตัวช่วยในการตัดสินใจซื้อประกันของคุณได้อย่างละเอียดยิ่งขึ้น
        </InfoLeft>
        {/* <ReadMore type="border">อ่านเพิ่มเติม</ReadMore> */}
      </ContainerNoonInfo>
      <AppearanceComponent
        componentDidAppear={() => {
          setNewCount()
        }}
        componentDidDisappear={() => {
          setCount(0)
          clearInterval(runner)
          setRunner({ id: '' })
        }}
      >
        <Circle
          count={count}
          radius={60 * 6.28}
          score={`${count.toFixed(1)}/10`}
          topic="NOON SCORE"
        />
      </AppearanceComponent>
    </ContainerNoon>
    <DidYouKnow />
  </Container>
)

const enhancer = compose(
  withState('count', 'setCount', 0),
  withState('runner', 'setRunner', { id: '' }),
  withHandlers({
    setNewCount: ({ count, setCount, runner }) => () => {
      if (runner.id === '') {
        runner.id = setInterval(() => {
          if (parseFloat(count).toFixed(1) === '6.8') {
            clearInterval(runner.id)
          } else {
            setCount((count += 0.068))
          }
        }, 10)
      }
    },
  }),
  lifecycle({
    componentWillUnmount() {
      clearInterval(this.props.runner.id)
    },
  })
)

export default enhancer(Content)
