import { message } from 'antd'
import React from 'react'
import styled from 'react-emotion'

import CustomField from '../../share/Field'

const getBase64 = (file, callback) => {
  const fileSize = file.size / 1048576
  if (fileSize > 2) {
    message.error('ขนาดไฟล์ภาพไม่ควรเกิน 2 MB', 5)
  } else {
    const reader = new FileReader()
    reader.addEventListener('load', () => callback(reader.result))
    reader.readAsDataURL(file)
  }
}

const UploadAvatar = ({
  avartars,
  setAvatarId,
  setIsShowModal,
  onAvatarChange,
}) => (
  <ChooseLogoContainer>
    <Header>เลือก/ อัพโหลดรูปภาพ</Header>
    <ChooseLogoListContainer>
      <SelectFile
        name="avatarImage"
        type="file"
        didChange={file => {
          getBase64(file, item => {
            onAvatarChange(item)
          })
          setAvatarId('')
          setIsShowModal(false)
        }}
      />
      {avartars.map(value => (
        <LogoInList
          key={value.id}
          src={value.avatar}
          onClick={() => {
            onAvatarChange(value.avatar)
            setAvatarId(value.id)
            setIsShowModal(false)
          }}
        />
      ))}
    </ChooseLogoListContainer>
  </ChooseLogoContainer>
)

export default UploadAvatar

const Header = styled.div`
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 30px;
`
const ChooseLogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
const ChooseLogoListContainer = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 360px;
  flex-wrap: wrap;
`
const LogoInList = styled.img`
  width: 50px;
  height: 50px;
  margin: 10px 5px;

  cursor: pointer;
  border-radius: 50%;
  object-fit: cover;

  :hover {
    box-shadow: 0 0 15px 3px ${props => props.theme.ORANGE};
  }
`
const SelectFile = styled(CustomField)`
  width: 50px;
  height: 50px;
  margin: 10px 5px;
`
