import React from 'react'
import styled, { css } from 'react-emotion'
import { compose, withHandlers, lifecycle } from 'recompose'
import { withRouter } from '../NoonTax/enhances'
import { reduxForm } from 'redux-form'
import { Creator } from '../../ducks/helper'
import { url2Obj } from '../../core/helper'
import { connect } from 'react-redux'

import Button from '../share/Button.js'
import { getTopic } from './utils'

import Field from '../share/Field'
import LifeFields from './LifeFields'
import HealthFields from './HealthFields'
import { getDiseasesOption } from '../../ducks/options'
import { HOST } from '../../core/httpClient'
import {
  displayNumber,
  numberParser,
} from '../../core/helper'

export const breakpoints = {
  mobile: 598,
}

export const responsive = Object.keys(breakpoints).reduce(
  (accumulator, label) => {
    let prefix = typeof breakpoints[label] === 'string' ? '' : 'max-width:'
    let suffix = typeof breakpoints[label] === 'string' ? '' : 'px'
    accumulator[label] = cls =>
      css`
        @media (${prefix + breakpoints[label] + suffix}) {
          ${cls};
        }
      `
    return accumulator
  },
  {}
)

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  text-align: center;

  margin-top: 39px;

  border: 1px solid #E6E6E6;
  border-radius: 5px;
  opacity: 1;

  ${props =>
    responsive.mobile(css`
    margin-top: 33px;
    `)};

`
const FieldContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  span {
    min-width: 150px !important;
  }

  div:nth-child(3) {
    max-width: 400px;
    min-width: 400px;
  }

  ${props =>
    responsive.mobile(css`
      > div{
        display: flex;
        flex-direction: column;

        >div {
          margin-top: 15px;
          justify-content: center;
          > div {
            > div {
              width: 140px;
              font-size: 16px;
            }
          }
        }
      }
      span {
        padding-left: 47px;
        font-size: 16px;
        justify-content: flex-start;
      }

      div:nth-child(3) {
        max-width: 200px;
        min-width: 200px;
      }
    `)};
`
const Unit = styled.div`
  height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 15px;

  ${props =>
    responsive.mobile(css`
      display: none !important;
    `)};
`
const CustomField = styled(Field)`
  input[type='text'] {
    text-align: center
  }
`
const CustomFieldAge = styled(CustomField)`
  width: fit-content;
  input {
    width: 100px;
  }

  ${props =>
    responsive.mobile(css`
      width: 100%;
      input {
        width: 280px;
        font-size: 16px;
      }
    `)};
`
const Topic = styled.h1`
  font-size: 20px;
  font-weight: bold;
`

const SubTopic = styled.h2`
  font-size: 16px;

  ${props =>
    responsive.mobile(css`
      font-size: 14px;
    `)};
`
const CustomButton = styled(Button)`
  width: 300px !important;
  height: 40px;
  margin-bottom: 20px;
  margin-top: 20px;
  font-size: 16px;

  ${props =>
    responsive.mobile(css`
      font-size: 16px;
      margin-top: 10px;
      width: 280px !important;
    `)};
`
const FormContianer = styled.div`
  margin-top: 20px;
  width: 500px;

  ${props =>
    responsive.mobile(css`
      width: 375px;
  `)};
`

const Form = props => {
  let category = props.location.query.category
  let insuranceType = props.location.query.insuranceType
  let searchBy = props.location.query.searchBy
  let ciRoles = props.location.query.ciRoles
  let ciOption = props.location.query.ciOption
  let hsGoal = props.location.query.hsGoal

  return (
    <Container>
      <Topic>
        {getTopic(category, insuranceType)}
      </Topic>
      <SubTopic>กรอกข้อมูลเพื่อค้นหาแบบประกันที่ใช่</SubTopic>
      <form>
        <FormContianer>
          <FieldContainer>
            <Field name='gender' label='เพศ' type='switch' />
          </FieldContainer>
          <FieldContainer>
            <CustomFieldAge name='age'
              label={(window.innerWidth > breakpoints.mobile ? 'อายุ' : 'อายุ (ปี)')}
              type='text'
              autoComplete='off'
              placeholder='อายุ'
              format={displayNumber}
              parse={numberParser}
            />
            <Unit>ปี</Unit>
          </FieldContainer>

          {category === 'life' && (
            <LifeFields
              searchBy={searchBy}
            />
          )}
          {category === 'health' && (
            <HealthFields
              insuranceType={insuranceType}
              hsGoal={hsGoal}
              ciRoles={ciRoles}
              ciOption={ciOption}
              diseasesOptions={props.diseasesOptions}
            />
          )}
          <CustomButton id="embed-search-insurance-product" onClick={props.handleSubmit(props.submit)}>
            เลือกแบบประกัน
          </CustomButton>
        </FormContianer>
      </form>
    </Container>
  )
}

const embedForm = Creator('EMBED_FORM', 'data', 'callback')

const validate = values => {
  const errors = {}

  if (!values.gender) {
    errors.gender = 'กรุณาเลือกเพศ'
  }
  if (!values.age) {
    errors.age = 'กรุณากรอกอายุ'
  } else if (values.age > 100) {
    values.age = 100
  }

  return errors
}

const enhacer = compose(
  connect(
    (state, props) => ({
      isLoading: state.contactUs.isLoading,
      initialValues: {
        category: (url2Obj(props.location.search) || null).category,
        weightType: 1,
        ciRoles: (url2Obj(props.location.search) || null).ciRoles,
        insuranceType: (url2Obj(props.location.search) || null).insuranceType,
        searchBy: (url2Obj(props.location.search) || null).searchBy,
        hsGoal: (url2Obj(props.location.search) || null).hsGoal
      },
      diseasesOptions: state.options.diseasesOptions
    }),
    { getDiseasesOption, embedForm }
  ),
  withRouter(),
  reduxForm({
    form: 'EmbedForm',
    validate,
    enableReinitialize: true,
  }),
  lifecycle({
    componentDidMount() {
      this.props.getDiseasesOption()
    },
  }),
  withHandlers({
    submit: props => values => {
      dataLayer.push({
        event: 'embed_search_insurance_product',
        ...url2Obj(props.location.search),
        ...values,
      })
      if (values.category === 'life') {
        const url = `${HOST}/smart/${values.category}/intermediate/product?age=${values.age}&gender=${values.gender}&insuranceType=${values.insuranceType}&noonStar=3&searchBy=${values.searchBy}&searchValue=${values.searchValue}&sortBy=min_premium&weightType=${values.weightType}`
        window.open(url, "_blank")
      }
      else if (values.category === 'health') {
        const url = `${HOST}/smart/${values.category}/intermediate/product?age=${values.age}&gender=${values.gender}&insuranceType=${values.insuranceType}&noonStar=3&searchValue=${values.searchValue}&sortBy=min_premium&hsGoal=${values.hsGoal}&ciRoles=${values.ciRoles}&ciDiseases=${values.ciDiseases}`
        window.open(url, "_blank")
      }
    }
  })
)

export default enhacer(Form)
