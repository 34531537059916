import React from 'react'
import styled from 'react-emotion'
import { connect } from 'react-redux'

import CloudFooter from '../../static/images/Footer Cloud.svg'
import Cloud1Svg from '../../static/images/1cloud-orange.svg'
import Cloud2Svg from '../../static/images/2cloud-orange.svg'

const Container = styled.div`
  overflow: hidden;
  position: absolute;
  height: 100%;
  top: 0;
  width: 100vw;
  z-index: -1;
`

const BackgroundFooter = styled.img`
  position: absolute;
  width: 100vw;
  bottom: 0;
  z-index: -1;
`

const Cloud1 = styled.img`
  position: absolute;
  top: 220px;
  left: -80px;
  width: 80px;
  height: 30px;
  animation-iteration-count: infinite;
  animation-duration: 60s;
  animation-name: cloud1;
  animation-timing-function: linear;
  z-index: -1;
  animation-delay: 1s;

  @keyframes cloud1 {
    0% {
      left: -80px;
    }
    100% {
      left: 100vw;
    }
  }
`

const Cloud2 = styled(Cloud1)`
  top: 270px;
  animation-duration: 40s;
  animation-delay: -25s;
`
const Cloud3 = styled(Cloud1)`
  top: 180px;
  animation-duration: 50s;
  animation-delay: -35s;
`
const Cloud4 = styled(Cloud1)`
  width: 60px;
  height: 36px;
  top: 180px;
  animation-duration: 80s;
  animation-delay: -50s;
`
const Background = props => {
  if (props.isNoBackground) return <div />
  return (
    <Container id="background">
      <Cloud1 src={Cloud1Svg} />
      <Cloud2 src={Cloud2Svg} />
      <Cloud3 src={Cloud2Svg} />
      <Cloud4 src={Cloud1Svg} />
      <BackgroundFooter src={CloudFooter} />
    </Container>
  )
}

const enhancer = connect(
  state => ({
    isNoBackground: state.notFound.isNoBackground,
  }),
  {}
)

export default enhancer(Background)
