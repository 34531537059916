import React from 'react'
import {compose, lifecycle, withState} from 'recompose'
import {connect} from 'react-redux'
import styled, {css} from 'react-emotion'
import {reduxForm, formValueSelector} from 'redux-form'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {searchGeneral, setData} from '../../ducks/landing'
import Field from '../share/Field'
import Button from '../share/Button'
import {responsive} from '../../core/style'

const ContainerGeneralSearch = styled.form`
  position: relative;
  margin-bottom: 66px;
`

const GeneralSearch = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;
  width: 100%;
  height: 54px;
  background-color: ${props => props.theme.BLUE};
  border-radius: ${props =>
    !props.initialSearch && props.valueField === undefined
      ? '0 0 5px 5px'
      : '0 0 0 0'};
  color: #ffffff;
`

const ContainerAfterSearch = styled.div`
  position: absolute;
  width: 100%;
  display: none;
  flex-direction: column;
  z-index: 2;
`
const ClearText = styled.div`
  display: ${props => (props.clear ? 'flex' : 'none')};
  position: absolute;
  color: #6a94cb;
  font-size: 14px;
  z-index: 3;
  right: 20px;
  top: 15px;
  cursor: pointer;
`

const AfterSearch = styled.div`
  font-size: 16px;
  margin: 0 10px;
  padding: 0 10px;
  flex: 1;
`

const CustomField = styled(Field)`
  margin: 0 10px;
  input {
    font-size: 16px;
    color: white;
    text-align: left;
    background-color: ${props => props.theme.BLUE};
    border: unset;
    height: 24px;

    ::-webkit-input-placeholder {
      color: white;
      opacity: 0.6;
    }
    :-ms-input-placeholder {
      color: white;
      opacity: 0.6;
    }
    ::-moz-placeholder {
      color: white;
      opacity: 0.6;
    }
    :-moz-placeholder {
      color: white;
      opacity: 0.6;
    }
  }
`
const CustomIcon = styled(FontAwesomeIcon)`
  font-size: 16px;
  color: white;
  margin-left: 30px;
`

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;

  width: 100%;

  .label {
    color: white;
    font-size: 18px;
  }
`
const ContainerValueSearch = styled(GeneralSearch)`
  border-radius: ${props => (props.isLast ? '0 0 5px 5px' : '0 0 0 0')};
  :hover {
    background-color: ${props => props.theme.LIGHTBLUE};
    cursor: pointer;
  }
`
const ContainerValueSearchNotFound = styled(ContainerValueSearch)`
  ${props =>
    responsive.mobile(css`
      flex-direction: column;
      height: 105px;
    `)};
`

const MobileNotFound = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`

const CustomButton = styled(Button)`
  min-width: 150px;
  height: 30px;
  font-size: 12px;
  margin-right: 15px;

  :hover {
    color: black;
    background-color: ${props => props.theme.DARKORANGE};
    border: 'unset';
  }

  ${props =>
    responsive.mobile(css`
      margin-right: 0;
    `)};
`

const Filter = ({className, timeOut, initTimeOut, searchGeneral}) => (
  <Container className={className}>
    <CustomIcon icon={['fas', 'search']} />
    <CustomField
      className="search"
      name="search"
      placeholder="พิมพ์เพื่อค้นหา"
      didChange={value => {
        if (timeOut) clearTimeout(timeOut)
        initTimeOut(
          setTimeout(() => {
            searchGeneral(value)
          }, 500),
        )
      }}
    />
  </Container>
)

const GeneralSearchBox = ({
  timeOut,
  initTimeOut,
  searchGeneral,
  arSearch,
  change,
  setData,
  valueField,
  initialSearch,
}) => (
  <ContainerGeneralSearch
    tabIndex="-1"
    autoComplete="off"
    onBlur={e => {
      document.getElementById('resultSearch').style.display = 'none'
    }}
    onFocus={() =>
      (document.getElementById('resultSearch').style.display = 'flex')
    }>
    <GeneralSearch initialSearch={initialSearch} valueField={valueField}>
      <Filter
        searchGeneral={searchGeneral}
        timeOut={timeOut}
        initTimeOut={initTimeOut}
      />
    </GeneralSearch>
    <ClearText
      clear={arSearch.length !== 0}
      onClick={() => {
        change(`search`, '')
        setData({arSearch: []})
      }}>
      clear
    </ClearText>
    <ContainerAfterSearch id="resultSearch">
      {arSearch.length !== 0
        ? arSearch.map((value, index) => (
            <div
              aid="universal-search"
              key={index}
              onClick={() => {
                dataLayer.push({
                  event: 'search_universal_item',
                  universalSearchKeyword: valueField,
                  universalSearchTarget: value.description,
                })
                return window.open(`${value.url}`, '_blank')
              }}>
              <ContainerValueSearch
                isLast={arSearch.length === index + 1}
                key={index}>
                <CustomIcon icon={['fas', `${value.icon}`]} />
                <AfterSearch>{value.description}</AfterSearch>
              </ContainerValueSearch>
            </div>
          ))
        : initialSearch &&
          valueField !== undefined && (
            <div
              onClick={() =>
                window.open(`/contact-us?subject=ทิ้งคำถามไว้กับนูน`, '_blank')
              }>
              <ContainerValueSearchNotFound isLast>
                <MobileNotFound>
                  <CustomIcon icon={['far', `sad-tear`]} />
                  <AfterSearch>{`${valueField} - แย่จัง ไม่มีหัวข้อนี้ในระบบ`}</AfterSearch>
                </MobileNotFound>
                <CustomButton
                  onMouseDown={e => {
                    e.preventDefault()
                    window.open(
                      `/contact-us?subject=ทิ้งคำถามไว้กับนูน`,
                      '_blank',
                    )
                  }}
                  icons={['fa', 'pen']}>
                  ทิ้งคำถามไว้กับนูนสิจ๊ะ
                </CustomButton>
              </ContainerValueSearchNotFound>
            </div>
          )}
    </ContainerAfterSearch>
  </ContainerGeneralSearch>
)

const selector = formValueSelector('general_search')

const enhancer = compose(
  connect(
    (state, props) => ({
      arSearch: state.landing.arSearch,
      initialSearch: state.landing.initialSearch,
      valueField: selector(state, 'search'),
    }),
    {searchGeneral, setData},
  ),
  withState('timeOut', 'initTimeOut', null),
  reduxForm({form: 'general_search'}),
  lifecycle({
    componentDidMount() {
      this.props.setData({arSearch: []})
    },
  }),
)
export default enhancer(GeneralSearchBox)
