import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Dropdown, Menu, Modal, Table } from 'antd'
import React from 'react'
import styled from 'react-emotion'

import { compose, withHandlers, withState } from 'recompose'
import { getThaiDate } from '../../../core/helper'
import Button from './components/Button'

const PolicyStatus = ({ status }) => {
  const statusText = status === 'หมดอายุ' ? status : 'มีผลใช้งาน'
  const statusColor = status === 'หมดอายุ' ? '#dc2626' : '#16b74d'
  return (
    <Tag color={statusColor} key={statusText}>
      {statusText}
    </Tag>
  )
}

const MyPolicyTable = ({
  visible = false,
  policyList = [],
  selectedPolicy = null,
  onSubmit = () => {},
  showModal = () => {},
  handleCancel = () => {},
}) => {
  const columns = [
    {
      title: 'วันที่เพิ่ม',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: text => (text ? getThaiDate(text) : '-'),
    },
    {
      title: 'ชื่อบริษัทประกัน',
      dataIndex: 'insuranceCompanyName',
      key: 'insuranceCompanyName',
    },
    {
      title: 'เลขที่กรมธรรม์',
      dataIndex: 'policyNo',
      key: 'policyNo',
      render: text => text,
    },
    {
      title: 'สถานะ',
      dataIndex: 'policyStatus',
      key: 'policyStatus',
      render: (text, record) => (
        <TagContainer>
          <PolicyStatus status={text} />
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item
                  key="0"
                  onClick={() => {
                    showModal(record.policyNo)
                  }}
                >
                  ลบ
                </Menu.Item>
              </Menu>
            }
          >
            <StyledIcon icon={['fas', 'pen']} />
          </Dropdown>
        </TagContainer>
      ),
    },
  ]
  return (
    <React.Fragment>
      <StyledTable
        columns={columns}
        dataSource={policyList}
        pagination={false}
      />
      <Modal
        width={365}
        title={null}
        footer={null}
        visible={visible}
        onCancel={handleCancel}
      >
        <ModalHeader>ยืนยันการลบกรมธรรม์</ModalHeader>
        <ModalInfo>
          ยืนยันที่จะลบรายการกรมธรรม์
          <br />
          เลขที่ <b>{selectedPolicy || '-'}</b> หรือไม่
        </ModalInfo>
        <ButtonContainer>
          <Button
            block
            size="large"
            type="secondary"
            title="ยืนยัน"
            onClick={onSubmit}
          />
          <Button
            block
            size="large"
            type="primary"
            title="ยกเลิก"
            onClick={handleCancel}
          />
        </ButtonContainer>
      </Modal>
    </React.Fragment>
  )
}

const enhancer = compose(
  withState('visible', 'setVisible', false),
  withState('selectedPolicy', 'setPolicy', {}),
  withHandlers({
    onSubmit: ({ selectedPolicy, setVisible, onDelete = () => {} }) => () => {
      onDelete(selectedPolicy, () => {
        setVisible(false)
      })
    },
    showModal: ({ setVisible, setPolicy }) => policyNo => {
      setVisible(true)
      setPolicy(policyNo)
    },
    handleCancel: ({ setVisible, setPolicy }) => () => {
      setVisible(false)
      setPolicy(null)
    },
  })
)

export default enhancer(MyPolicyTable)

const StyledTable = styled(Table)`
  width: 100%;
  .ant-table-thead {
    tr {
      th {
        background-color: transparent;
      }
    }
  }
`
const TagContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`
const Tag = styled.div`
  width: fit-content;
  padding: 4px 16px;
  border-radius: 16px;
  color: ${props => props.color};
  background-color: ${props => (props.color ? `${props.color}25` : '#fff')};
`
const StyledIcon = styled(FontAwesomeIcon)`
  font-size: 24 !important;
  color: #707070 !important;
  cursor: pointer;
`
const ModalHeader = styled.p`
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  color: black;
`
const ModalInfo = styled.p`
  font-size: 18px;
  text-align: center;
  color: black;
`
const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`
