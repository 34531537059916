import React from 'react'
import styled from 'react-emotion'
import { Router } from '@reach/router'
import Landing from './Landing'
import NoonSmart from './NoonSmart'
import NoonFund from './NoonFund'
import FundDetail from './NoonFund/Detail'
import FundCompare from './NoonFund/Compare'
import NoonTax from './NoonTax'
import SubCategory from './NoonSmart/SubCategory'
import Product from './NoonSmart/Product/index'
import ProductDetail from './NoonSmart/Product/ProductDetail/index'
import Compare from './NoonSmart/Compare'
import NoonSquare from './NoonSquare'
import Deatils from './NoonSquare/Details'
import Auth from './Auth'
import ContactUs from './ContactUs'
import ContactUsSaleRep from './ContactUs/SaleRep'
import NotFound from './404'
import NoonLab from './NoonLab'
import AboutUs from './AboutUs'
import Career from './Career'
import Policy from './Policy'
import Profile from './Profile'
import FreeForm from './Landing/FreeForm'
import FreeFormConfirm from './Landing/FreeFormConfirm'
import SuggestionProductDetails from './Landing/SuggestionProductDetails'
import EventRegistering from './EventRegistering'
import InsuranceCategory from './NoonSmart/InsuranceCategory'
import MotorIntermediate from './NoonSmart/Motor/Intermediate/index'
import HealthIntermediate from './NoonSmart/Health/Intermediate/index'
import LifeIntermediate from './NoonSmart/Life/Intermediate/index'
import NoonQuestion from './NoonQuestion'
import Corona from './Corona'
import Embed from './Embed'

const CustomRouter = styled(Router)`
  width: 100%;
  min-height: calc(100vh - 214px);
  padding-top: 80px;
`

const Route = ({ isOnTop, isFooterAppear }) => (
  <CustomRouter>
    <Auth path="/authorize/:category" />
    <Landing path="/" isOnTop={isOnTop} />
    <SuggestionProductDetails path="/suggestionProductDetails/:productCategory" />
    <NoonSmart path="/smart" />
    <InsuranceCategory path="/smart/InsuranceCategory" />
    <NoonSquare path="/square" />
    <NoonLab path="/lab" />
    {/* <NoonFund path="/fund" />
    <FundCompare path="/fund/compare" />
    <FundDetail path="/fund/products/:product_id" /> */}
    <AboutUs path="/about-us" />
    <Career path="/career" />
    <Policy path="/policy" />
    <Profile path="/profile" />
    <Profile path="/profile/:menu" />
    <SubCategory path="/smart/InsuranceCategory/:category" />
    <SubCategory path="/smart/InsuranceCategory/:category/:level" />
    <MotorIntermediate path="/smart/InsuranceCategory/:category/:level/motorForm" />
    <HealthIntermediate path="/smart/InsuranceCategory/:category/:level/Healthform/:formType" />
    <LifeIntermediate path="/smart/InsuranceCategory/:category/:level/Lifeform/:formType/:weightType" />
    <Product
      path="/smart/:category/:level/product"
      isFooterAppear={isFooterAppear}
    />
    <ProductDetail path="/smart/:category/:level/product/:id" />
    <Compare path="/smart/:category/:level/product/compare" />
    <Deatils path="/square/details/:id" />
    <ContactUs path="/contact-us" />
    <ContactUsSaleRep path="contact-us-salerep" />
    <FreeForm path="free-form" />
    <FreeFormConfirm path="free-form-confirm" />
    <EventRegistering path="cebit_2019" />
    <NotFound default />
    <NoonQuestion path="/noon-question/*" />
    <NoonTax path="/tax/*" />
    {/* <Corona path="/corona" /> */}
    <Embed path="/embed/*" />
  </CustomRouter>
)

export default Route
