import React from 'react'
import styled, { css } from 'react-emotion'
import { connect } from 'react-redux'
import { compose, lifecycle } from 'recompose'
import { responsive } from '../../core/style'
import Loading from '../share/Loading'

import ChangePassword from './ChangePassword'
import CompareInsurance from './CompareInsurance'
import Favorite from './Favorite'
import InsurancePort from './InsurancePort'
import Lead from './Lead'
import MyPolicy from './MyPolicy'
import ProfileMenu from './ProfileMenu'
import UserProfile from './UserProfile'

const Container = styled.div`
  display: flex;
  flex-direction: row;
  background-color: white;
  overflow: auto;

  ${props =>
    responsive.mobile(css`
      position: relative;
    `)};
`
const ContentContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: flex-start;
  /* background-color: rgba(106, 148, 203, 0.1); */
  padding: 30px 0;
  min-height: calc(100vh - 269px);

  ${props =>
    responsive.mobile(css`
      min-height: 500px;
    `)};
`

const Profile = ({ menu = 'user-profile', isLoading, initialized, user }) =>
  initialized && (
    <Loading isLoading={isLoading}>
      <Container>
        <ProfileMenu activeMenu={menu} user={user} />
        <ContentContainer>
          {menu === 'changed-password' && user.loginWith === 'email' && (
            <ChangePassword />
          )}
          {menu === 'user-profile' && <UserProfile />}
          {menu === 'favorite' && <Favorite />}
          {menu === 'interest' && <Lead />}
          {menu === 'compare' && <CompareInsurance />}
          {menu === 'port' && <InsurancePort />}
          {menu === 'my-policy' && <MyPolicy />}
        </ContentContainer>
      </Container>
    </Loading>
  )

const enhancer = compose(
  connect(
    state => ({
      isLoading: state.profile.isLoading,
      initialized: state.auth.initialized,
      isLogin: state.auth.isLogin,
      user: state.auth.user,
    }),
    {}
  ),
  lifecycle({
    componentWillReceiveProps(np) {
      if (np.initialized && !np.isLogin) {
        np.navigate('/')
      }
    },
  })
)

export default enhancer(Profile)
