import React from 'react'
import styled from 'react-emotion'
import {BrowserRouter, Switch, Route, Redirect} from 'react-router-dom'
import {
  compose,
  lifecycle,
  withHandlers,
  withState,
  withContext,
} from 'recompose'
import {withForm} from './enhancers/index'
import {Header} from './components/index'
import Questions1 from './Questions1'
import Questions2 from './Questions2'
import Questions3 from './Questions3'
import Summary from './Summary'
import * as path from './common/path'
import {safe} from './common/helper'
import {httpPut, httpGet, HOST} from '../../core/httpClient'
import PropTypes from 'prop-types'
import Canonical from '../share/Canonical'

const Layout = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #ffffff;
  min-height: 736px;
`
const Body = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 510px;
  width: 900px;
  margin-top: 45px;
  margin-bottom: 80px;

  @media (max-width: 768px) {
    min-width: 510px;
  }
  @media (max-width: 425px) {
    min-width: 278px;
  }
`

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  -webkit-animation: conditionalOpen 0.5s normal forwards ease-in-out;
  -moz-animation: conditionalOpen 0.5s normal forwards ease-in-out;
  animation: conditionalOpen 0.5s normal forwards ease-in-out;
  -webkit-transform-origin: 50% 0%;
  -moz-transform-origin: 50% 0%;
  transform-origin: 50% 0%;
  @-webkit-keyframes conditionalOpen {
    from {
      transform: scaleY(0);
    }
    to {
      transform: scaleY(1);
    }
  }
  @-moz-keyframes conditionalOpen {
    from {
      transform: scaleY(0);
    }
    to {
      transform: scaleY(1);
    }
  }
  @keyframes conditionalOpen {
    from {
      transform: scaleY(0);
    }
    to {
      transform: scaleY(1);
    }
  }
`

const NoonQuestion = props => (
  <BrowserRouter>
    <Layout>
      <Canonical
        withRouter
        href={props => `${HOST}${props.location.pathname}`}
      />
      <Header show />
      <Wrapper>
        <Body>
          <Switch>
            <Route path={path.questions1Path()} component={Questions1} />
            <Route path={path.questions2Path()} component={Questions2} />
            <Route path={path.questions3Path()} component={Questions3} />
            <Route path={path.summaryPath()} component={Summary} />
            <Redirect to={path.questions1Path()} />
          </Switch>
        </Body>
      </Wrapper>
    </Layout>
  </BrowserRouter>
)

export const getSessionId = () => window.localStorage.getItem('Noon-Auth-Token')
export const getNoonQuestionStorageKey = () =>
  `NoonQuestionForm-${getSessionId()}`

const enhancer = compose(
  withForm({
    form: 'NoonQuestion',
    // destroyOnUnmount: false,
    // enableReinitialize: true,
    // keepDirtyOnReinitialize: true,
  }),
  withHandlers({
    loadValues: props => async () => {
      const response = await httpGet('noon_questions/answers', {
        sessionId: getSessionId(),
      })
      const resumedValues = response.data.data.answers // || JSON.parse(localStorage.getItem(getNoonQuestionStorageKey())) || {}
      props.initialize(resumedValues)
    },
    saveValues: props => () => {
      // localStorage.setItem(getNoonQuestionStorageKey(), JSON.stringify(props.values))
      dataLayer.push({
        event: 'noon_questions_answer',
        ...props.values,
      })
      console.log('saveValuse')
      try {
        httpPut(`noon_questions/answers`, {
          sessionId: getSessionId(),
          ...props.values,
        })
      } catch (e) {
        console.log(e)
      }
    },
  }),
  withContext(
    {
      saveValues: PropTypes.func,
    },
    ({saveValues}) => ({saveValues}),
  ),
  lifecycle({
    async componentDidMount() {
      this.props.loadValues()
      window.addEventListener(
        'beforeunload',
        (window.beforeunloadCallback = this.props.saveValues),
      )
    },
    componentWillUnmount() {
      //setTimeout(this.props.saveValues)
      window.removeEventListener('beforeunload', window.beforeunloadCallback)
    },
  }),
)

export default enhancer(NoonQuestion)
