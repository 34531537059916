import React from 'react'
import { connect } from 'react-redux'
import { compose, withState } from 'recompose'
import styled, { css } from 'react-emotion'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Modal from '../share/Modal'
import Button from '../share/Button'
import Field from '../share/Field'
import Loading from '../share/Loading'
import { reduxForm } from 'redux-form'
import { responsive } from '../../core/style'
import { displayNumber, numberParser } from '../../core/helper'
import { getSuggestionProductDetails } from '../../ducks/product'

import Box1 from '../../static/images/Product-Online-Fullframe-1.jpg'
import Box2 from '../../static/images/Product-Online-Fullframe-2.jpg'
import Box3 from '../../static/images/Product-Online-Fullframe-3.jpg'
import Box4 from '../../static/images/Product-Online-Fullframe-4.jpg'
import Box5 from '../../static/images/Product-Online-Fullframe-5.jpg'
import Box6 from '../../static/images/Product-Online-Fullframe-6.jpg'

const Container = styled.form``

const BackgroundImage = styled.div`
  position: relative;
  background-size: cover;
  background-position-x: center;
  background-image: url(${props => props.background});
  width: 100%;
  height: calc(100vh - 213px);
  overflow: scroll;
  ${props =>
    responsive.mobile(css`
      display: flex;
      align-items: center;
      justify-content: center;
      background-position-x: calc(30%);
    `)};
`

const BoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: calc(50% - (450px / 2));
  left: calc(53.2%);
  background-color: white;
  width: 400px;
  height: 450px;

  ${props =>
    responsive.tablet(css`
      left: unset;
      right: 30px;
    `)};

  ${props =>
    responsive.mobile(css`
      width: 312px;
      background-color: rgba(255, 255, 255, 0.95);

      position: relative;
      left: unset;
      right: unset;
      top: unset;
    `)};
`

const BoxHeader = styled.div`
  font-size: 20px;
  font-weight: bold;
  color: black;
  margin-bottom: 10px;
  text-align: center;
  white-space: pre-wrap;
`

const BoxSubHeader = styled.div`
  font-size: 14px;
  color: black;
  margin-bottom: 40px;
`

const GenderButton = styled(Field)`
  width: 100%;
  height: unset;
  margin-bottom: 7.5px;
  > div {
    > div {
      justify-content: center;
      .male {
        width: 125px;
      }
      .female {
        width: 125px;
      }
    }
  }
  .label {
    min-width: 100px;
  }

  ${props =>
    responsive.mobile(css`
      .input-switch-container {
        justify-content: center;
      }
    `)};
`

const GoogleInput = styled(Field)`
  margin: 7.5px 0;
  height: unset;
`

const Selector = styled(Field)`
  margin: 7.5px 0;
  width: 250px;
  height: unset;

  .input-googleSelector-container {
    margin: 0;

    .Select {
      border-radius: 5px;
      text-align: center;
      font-size: 16px;

      > .Select-menu-outer {
        width: 250px;
        margin-top: 11px;
        left: -1px;
        .Select-menu {
          border-radius: 0 0 5px 5px;
          background-color: white;
          border: 1px solid ${props => props.theme.EXTRALIGHTORANGE};
          border-top: 0;
          padding: 0;

          .Select-option {
            color: rgba(51, 51, 51, 0.5);
            font-size: 16px;
            padding: 8px 15px 8px 15px;
            width: 250px;
            height: 40px;
            justify-content: center;
            :hover {
              background-color: rgba(255, 212, 130, 0.5);
              color: black;
            }
          }
        }
      }
    }
  }
`

const SubmitButton = styled(Button)`
  min-width: 250px;
  height: 30px;
  margin-top: 25px;
  font-size: 12px;
`

const payPeriodOptions = [
  { label: 'ไม่ระบุ', value: '' },
  { label: '1 ปี', value: '1' },
  { label: 'ไม่เกิน 5 ปี', value: '5' },
  { label: '6 - 10 ปี', value: '6' },
  { label: '11 - 15 ปี', value: '11' },
  { label: '16 - 20 ปี', value: '16' },
  { label: '20 ปีขึ้นไป', value: '20' },
]

const coverageOptions = [
  { label: 'ไม่ระบุ', value: '' },
  { label: '10 ปี', value: 10 },
  { label: '15 ปี', value: 15 },
  { label: '20 ปี', value: 20 },
  { label: '25 ปี', value: 25 },
  { label: '30 ปี', value: 30 },
]

const yearOptions = [
  { label: '55 ปี', value: '55' },
  { label: '60 ปี', value: '60' },
  { label: '65 ปี', value: '65' },
]

const productSuggestList = {
  'saving-tax': {
    background: Box1,
    header: 'สะสมทรัพย์ดีๆ ลดหย่อนภาษีสุดคุ้ม',
    subHeader: 'กรอกข้อมูลเพื่อค้นหาแบบประกันที่ใช่',
    fields: [
      { name: 'age', placeholder: 'อายุ' },
      {
        name: 'searchValue',
        placeholder: 'เบี้ย(จำนวนที่ต้องจ่าย)',
        format: displayNumber,
        parse: numberParser,
      },
      {
        name: 'payPeriodMax',
        placeholder: 'ระยะเวลาจ่ายเบี้ย',
        options: payPeriodOptions,
        isSelector: true,
      },
    ],
  },
  'term-life': {
    background: Box2,
    header: 'คุ้มครองภาระหนี้สินได้ เบี้ยจ่ายไม่แพง',
    subHeader: 'กรอกข้อมูลเพื่อค้นหาแบบประกันที่ใช่',
    fields: [
      { name: 'age', placeholder: 'อายุ' },
      {
        name: 'searchValue',
        placeholder: 'ภาระหนี้ทั้งหมด',
        format: displayNumber,
        parse: numberParser,
      },
      {
        name: 'protectionPeriodMax',
        placeholder: 'ระยะเวลาที่ต้องจ่ายหนี้(ปี)',
        options: coverageOptions,
        isSelector: true,
      },
    ],
  },
  'tax-annuity': {
    background: Box3,
    header: 'ลดหย่อนสองแสนหลัง ด้วยแบบบำนาญ',
    subHeader: 'กรอกข้อมูลเพื่อค้นหาแบบประกันที่ใช่',
    fields: [
      { name: 'age', placeholder: 'อายุ' },
      {
        name: 'searchValue',
        placeholder: 'เบี้ย(จำนวนที่ต้องจ่าย)',
        format: displayNumber,
        parse: numberParser,
      },
      {
        name: 'minAgeAnnuity',
        placeholder: 'ปีที่อยากเกษียณ',
        options: yearOptions,
        isSelector: true,
      },
    ],
  },
  'saving-annuity': {
    background: Box4,
    header: 'เกษียณสบาย มีเงินใช้แน่นอน',
    subHeader: 'กรอกข้อมูลเพื่อค้นหาแบบประกันที่ใช่',
    fields: [
      { name: 'age', placeholder: 'อายุ' },
      {
        name: 'searchValue',
        placeholder: 'เงินบำนาญรายปีที่อยากได้',
        format: displayNumber,
        parse: numberParser,
      },
      {
        name: 'minAgeAnnuity',
        placeholder: 'ปีที่อยากเกษียณ',
        options: yearOptions,
        isSelector: true,
      },
    ],
  },
  'whole-life': {
    background: Box5,
    header: 'ส่งต่อมรดก ด้วยประกันชีวิต',
    subHeader: 'กรอกข้อมูลเพื่อค้นหาแบบประกันที่ใช่',
    fields: [
      { name: 'age', placeholder: 'อายุ' },
      {
        name: 'searchValue',
        placeholder: 'มรดกที่อยากส่งต่อ',
        format: displayNumber,
        parse: numberParser,
      },
    ],
  },
  'scholarship-saving': {
    background: Box6,
    header: `สร้างทุนการศึกษามหาลัย
    พร้อมความคุ้มครอง`,
    subHeader: 'กรอกข้อมูลเพื่อค้นหาแบบประกันที่ใช่',
    fields: [
      { name: 'age', placeholder: 'อายุ' },
      {
        name: 'searchValue',
        placeholder: 'ค่าเล่าเรียนที่ต้องจ่าย',
        format: displayNumber,
        parse: numberParser,
      },
      {
        name: 'protectionPeriodMax',
        placeholder: 'จำนวนปีกว่าบุตรจะเข้าเรียน',
      },
    ],
  },
}

const NotFound = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;

  font-size: 20px;
  text-align: center;
`

const SuggestionProductDetails = ({
  isLoading,
  isShowModal,
  setShowModal,
  handleSubmit,
  productCategory,
  getSuggestionProductDetails,
}) => (
  <Loading isLoading={isLoading}>
    <Container
      onSubmit={handleSubmit(value => {
        dataLayer.push({
          // event: 'search_insurance_plan_ads',
          event: 'landingpage_wholelife',
          adsPlanGender: value.gender,
          adsPlanAge: value.age,
          adsPlanSearchValue: value.searchValue,
          adsPlanpayPeriodMax: value.payPeriodMax,
          adsPlanCategory: productCategory,
        })
        getSuggestionProductDetails({ ...value }, productCategory, () =>
          setShowModal(true)
        )
      })}
    >
      <BackgroundImage
        background={productSuggestList[productCategory].background}
      >
        <BoxContainer>
          <BoxHeader>{productSuggestList[productCategory].header}</BoxHeader>
          <BoxSubHeader>
            {productSuggestList[productCategory].subHeader}
          </BoxSubHeader>
          <GenderButton name="gender" type="switch" />
          {productSuggestList[productCategory].fields.map(value =>
            value.isSelector ? (
              <Selector type="googleSelector" hideError {...value} />
            ) : (
              <GoogleInput type="googleInput" hideError {...value} />
            )
          )}
          <SubmitButton htmlType="submit" icons={['fa', 'search']}>
            ค้นหาแบบประกัน
          </SubmitButton>
        </BoxContainer>
      </BackgroundImage>
    </Container>
    <Modal isShowModal={isShowModal} setIsShowModal={setShowModal}>
      <NotFound>ไม่พบแบบประกันที่ใช่สำหรับคุณ</NotFound>
    </Modal>
  </Loading>
)

const enhancer = compose(
  connect(
    (state, props) => ({
      isLoading: state.product.isLoading,
      initialValues: {
        gender: 'male',
      },
    }),
    { getSuggestionProductDetails }
  ),
  reduxForm({
    form: 'suggestionProduct',
    destroyOnUnmount: false,
    enableReinitialize: true,
  }),
  withState('isShowModal', 'setShowModal', false)
)

export default enhancer(SuggestionProductDetails)
