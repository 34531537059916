import { takeLatest, put, select } from 'redux-saga/effects'

import { createReducer, Creator } from '../helper'
import fundeeApi from '../../core/fundeeApi'
import { httpGet, httpDelete, httpPost } from '../../core/httpClient'
import { setDataProduct } from '../../ducks/product'

const FETCH_FUNDS = 'FETCH_FUNDS'
const FETCH_FUND_COMPANIES = 'FETCH_FUND_COMPANIES'
const FETCH_FUND_COMPANY_OPTIONS = 'FETCH_FUND_COMPANY_OPTIONS'
const FETCH_FUND_CATEGORY_OPTIONS = 'FETCH_FUND_CATEGORY_OPTIONS'
const FETCH_ADDITIONAL_FUNDS = 'FETCH_ADDITIONAL_FUNDS'
const FETCH_FAVORITE_FUND_REF_IDS = 'FETCH_FAVORITE_FUND_REF_IDS'
const FETCH_FAVORITE_FUNDS = 'FETCH_FAVORITE_FUNDS'
const PUST_TO_COMPARE_FUNDS = 'PUST_TO_COMPARE_FUNDS'
const REMOVE_FROM_FUNDS_COMPARE = 'REMOVE_FROM_FUNDS_COMPARE'
const TOGGLE_FUND_FAVORITE = 'TOGGLE_FUND_FAVORITE'

const SET_FUND_LIST = 'SET_FUND_LIST'
const SET_FUND_COMPANY_LIST = 'SET_FUND_COMPANY_LIST'
const SET_FUND_COMPANY_OPTIONS = 'SET_FUND_COMPANY_OPTIONS'
const SET_FUND_CATEGORY_OPTIONS = 'SET_FUND_CATEGORY_OPTIONS'
const SET_ADDITIONAL_FUND_LIST = 'SET_ADDITIONAL_FUND_LIST'
const SET_FAVORITE_FUND_REF_IDS = 'SET_FAVORITE_FUND_REF_IDS'
const SET_FAVORITE_FUNDS = 'SET_FAVORITE_FUNDS'
const SET_FUND_FAVORITED = 'SET_FUND_FAVORITED'
const SET_ALL_FETCHING = 'SET_ALL_FETCHING'
const SET_ADDITIONAL_FETCHING = 'SET_ADDITIONAL_FETCHING'
const SET_FUNDS_COMPARE = 'ADD_FUNDS_COMPARE'
const SET_IS_SHOW_MODAL_FAVORITE = 'SET_IS_SHOW_MODAL_FAVORITE'

export const fetchFunds = Creator(FETCH_FUNDS, 'params', 'refIds')
export const fetchFundCompanies = Creator(FETCH_FUND_COMPANIES, 'params')
export const fetchFundCompanyOptions = Creator(
  FETCH_FUND_COMPANY_OPTIONS,
  'params'
)
export const fetchFundCategoryOptions = Creator(FETCH_FUND_CATEGORY_OPTIONS)
export const fetchAdditionalFunds = Creator(FETCH_ADDITIONAL_FUNDS, 'params')
export const fetchFavoriteFundRefIds = Creator(FETCH_FAVORITE_FUND_REF_IDS)
export const fetchFavoriteFunds = Creator(FETCH_FAVORITE_FUNDS, 'favoriteFunds')
export const pushToFundsCompare = Creator(PUST_TO_COMPARE_FUNDS, 'refId')
export const removeFromFundsCompare = Creator(
  REMOVE_FROM_FUNDS_COMPARE,
  'refId'
)
export const toggleFundFavorite = Creator(
  TOGGLE_FUND_FAVORITE,
  'refId',
  'favorited'
)
export const setIsShowModalFavorite = Creator(
  SET_IS_SHOW_MODAL_FAVORITE,
  'isShowModalFavorite'
)

const setFundList = Creator(SET_FUND_LIST, 'funds', 'paging')
const setFundCompanyList = Creator(SET_FUND_LIST, 'fundCompanies')
const setFundCompanyOptions = Creator(
  SET_FUND_COMPANY_OPTIONS,
  'fundCompanyOptions'
)
const setFundCategoryOptions = Creator(
  SET_FUND_CATEGORY_OPTIONS,
  'fundCategoryOptions'
)
const setAdditionalFundList = Creator(
  SET_ADDITIONAL_FUND_LIST,
  'funds',
  'paging'
)
const setFavoriteFundRefIds = Creator(
  SET_FAVORITE_FUND_REF_IDS,
  'favoriteRefIds'
)
const setFavoriteFunds = Creator(SET_FAVORITE_FUNDS, 'favoriteFunds')
const setFundFavorited = Creator(SET_FUND_FAVORITED, 'refId', 'favorited')
const setAllFetching = Creator(SET_ALL_FETCHING, 'status')
const setAdditionalFetching = Creator(SET_ADDITIONAL_FETCHING, 'status')
const setFundsCompare = Creator(SET_FUNDS_COMPARE, 'fundsCompare')

function* getFunds({ payload: { params, refIds } }) {
  try {
    const defaultParams = {
      pricesSamplingSize: 100,
      returnPeriod: '1y',
    }
    yield put(setAllFetching(true))
    const response = yield fundeeApi.get('/funds', {
      ...defaultParams,
      ...params,
    })
    const funds = response.data.funds
    const paging = response.data.meta.paging
    yield put(setFundList(funds, paging))
    yield put(setAllFetching(false))
  } catch (error) {
    switch (error.response.status) {
      default:
        yield put(setDataProduct({ errorMessage: 'เซิฟเวอร์มีปัญหา' }))
        break
    }
  }
}

function* getAdditionalFunds({ payload: { params } }) {
  try {
    const defaultParams = {
      pricesSamplingSize: 100,
      returnPeriod: '1y',
    }
    yield put(setAdditionalFetching(true))
    const response = yield fundeeApi.get('/funds', {
      ...defaultParams,
      page: params.nextPage,
      perPage: params.page,
      ...params,
    })
    const funds = response.data.funds
    const paging = response.data.meta.paging
    yield put(setAdditionalFundList(funds, paging))
    yield put(setAdditionalFetching(false))
  } catch (error) {
    switch (error.response.status) {
      default:
        yield put(setDataProduct({ errorMessage: 'เซิฟเวอร์มีปัญหา' }))
        break
    }
  }
}

function* sagaGetFavoriteFundRefIds() {
  try {
    const response = yield httpGet('/favorite_funds')
    const refIds = response.data.data.favoriteFunds.map(f => f.refId)
    yield put(setFavoriteFundRefIds(refIds))
  } catch (error) {
    switch (error.response.status) {
      default:
        yield put(setDataProduct({ errorMessage: 'เซิฟเวอร์มีปัญหา' }))
        break
    }
  }
}

function* sagaGetFavoriteFunds() {
  try {
    const response = yield httpGet('/favorite_funds')
    const refIds = response.data.data.favoriteFunds.map(f => f.refId)
    yield put(setFavoriteFundRefIds(refIds))

    const params = {
      pricesSamplingSize: 100,
      returnPeriod: '1y',
      refIds,
      paging: false,
      include: ['prices', 'return_period'],
    }

    if (refIds.length > 0) {
      const fundeeResponse = yield fundeeApi.get('/funds', params)
      const funds = fundeeResponse.data.funds
      yield put(setFavoriteFunds(funds))
    } else {
      yield put(setFavoriteFunds([]))
    }
  } catch (error) {
    console.log('error', error)
    yield put(setDataProduct({ errorMessage: 'เซิฟเวอร์มีปัญหา' }))
  }
}

function* sagaToggleFundFavorite({ payload: { refId, favorited } }) {
  try {
    yield put(setFundFavorited(refId, favorited))
    if (favorited) {
      yield httpPost('/favorite_funds', { refId: refId })
    } else {
      yield httpDelete(`/favorite_funds/${refId}`)
    }
  } catch (error) {
    yield put(setFundFavorited(refId, !favorited))
    switch (error.response.status) {
      default:
        yield put(setDataProduct({ errorMessage: 'เซิฟเวอร์มีปัญหา' }))
        break
    }
  }
}

function* getFundCompanies({ payload: { params } }) {
  try {
    const response = yield fundeeApi.get('/fund_companies', { perPage: 20 })
    const fundCompanies = response.data.fundCompanies
    yield put(setFundCompanyList(fundCompanies))
  } catch (error) {
    switch (error.response.status) {
      default:
        yield put(setDataProduct({ errorMessage: 'เซิฟเวอร์มีปัญหา' }))
        break
    }
  }
}

function* getFundCompanyOptions() {
  try {
    const response = yield fundeeApi.get('/fund_companies', { paging: false })
    const fundCompanies = response.data.fundCompanies
    const fundCompanyOptions =
      fundCompanies && fundCompanies.length !== 0
        ? fundCompanies.map(fundCompany => ({
            label: fundCompany.shortNameTh,
            value: fundCompany.symbol,
            logo: fundCompany.logoThumb,
          }))
        : []
    yield put(setFundCompanyOptions(fundCompanyOptions))
  } catch (error) {
    switch (error.response.status) {
      default:
        yield put(setDataProduct({ errorMessage: 'เซิฟเวอร์มีปัญหา' }))
        break
    }
  }
}

function* getFundCategoryOptions() {
  try {
    const response = yield fundeeApi.get('/funds/categories')
    const { categories, subCategories } = response.data.categories
    let subCategoriesObj = {}
    subCategories.forEach(subCategory => {
      subCategoriesObj = {
        ...subCategoriesObj,
        [subCategory.symbol]: subCategory,
      }
    })

    const categoryOptions = categories.map(
      ({ name, symbol, visibleSubcategories }) => {
        let subCategoryOptions = []
        visibleSubcategories.forEach(symbol => {
          const subCategory = subCategoriesObj[symbol]
          if (subCategory) {
            subCategoryOptions = [
              ...subCategoryOptions,
              { label: subCategory.name, value: subCategory.symbol },
            ]
          }
        })
        return { label: name, value: symbol, subCategoryOptions }
      }
    )
    yield put(setFundCategoryOptions(categoryOptions))
  } catch (error) {
    switch (error.response.status) {
      default:
        yield put(setDataProduct({ errorMessage: 'เซิฟเวอร์มีปัญหา' }))
        break
    }
  }
}

function* addFundToFundsCompare({ payload: { refId } }) {
  const fundList = yield select(state => state.fund.all)
  const paging = yield select(state => state.fund.paging)
  const oldFundsCompare = yield select(state => state.fund.fundsCompare)
  if (fundList.length === 0) return
  const fundIndex = fundList.findIndex(fund => fund.refId === refId)
  if (oldFundsCompare.length === 5 || fundIndex === -1) return
  const newFund = { ...fundList[fundIndex], isCompare: true }
  fundList[fundIndex] = newFund
  yield put(setFundList([...fundList], paging))
  yield put(setFundsCompare([...oldFundsCompare, { ...newFund }]))
}

function* deleteFromFundsCompare({ payload: { refId } }) {
  const fundList = yield select(state => state.fund.all)
  const paging = yield select(state => state.fund.paging)
  const oldFundsCompare = yield select(state => state.fund.fundsCompare)
  if (fundList.length === 0 || oldFundsCompare.length === 0) return
  const fundIndex = fundList.findIndex(fund => fund.refId === refId)
  if (oldFundsCompare.length === 0) return
  if (fundIndex !== -1) {
    const newFund = { ...fundList[fundIndex], isCompare: false }
    fundList[fundIndex] = newFund
    yield put(setFundList([...fundList], paging))
  }
  const newFundsCompare = oldFundsCompare.filter(fund => fund.refId !== refId)
  yield put(setFundsCompare([...newFundsCompare]))
}

export function* watchFundSaga() {
  yield takeLatest(FETCH_FUNDS, getFunds)
  yield takeLatest(FETCH_FUND_COMPANIES, getFundCompanies)
  yield takeLatest(FETCH_FUND_COMPANY_OPTIONS, getFundCompanyOptions)
  yield takeLatest(FETCH_FUND_CATEGORY_OPTIONS, getFundCategoryOptions)
  yield takeLatest(FETCH_ADDITIONAL_FUNDS, getAdditionalFunds)
  yield takeLatest(FETCH_FAVORITE_FUND_REF_IDS, sagaGetFavoriteFundRefIds)
  yield takeLatest(FETCH_FAVORITE_FUNDS, sagaGetFavoriteFunds)

  yield takeLatest(PUST_TO_COMPARE_FUNDS, addFundToFundsCompare)
  yield takeLatest(REMOVE_FROM_FUNDS_COMPARE, deleteFromFundsCompare)
  yield takeLatest(TOGGLE_FUND_FAVORITE, sagaToggleFundFavorite)
}

const initial = {
  all: [],
  favoriteRefIds: [],
  favoriteFunds: [],
  isShowModalFavorite: false,
  allFetching: false,
  additionalFetching: false,
  paging: {},
  fundCompanies: [],
  fundCompanyOptions: [],
  fundCategoryOptions: [],
  fundsCompare: [],
}

export default createReducer(initial, state => ({
  [SET_FUND_LIST]: ({ funds, paging }) => ({
    ...state,
    all: funds,
    paging: paging,
  }),
  [SET_ADDITIONAL_FUND_LIST]: ({ funds, paging }) => ({
    ...state,
    all: [...(state.all || []), ...funds],
    paging: paging,
  }),
  [SET_FAVORITE_FUND_REF_IDS]: ({ favoriteRefIds }) => ({
    ...state,
    favoriteRefIds,
  }),
  [SET_FUND_FAVORITED]: ({ refId, favorited }) => ({
    ...state,
    favoriteRefIds: favorited
      ? state.favoriteRefIds.concat([refId])
      : state.favoriteRefIds.filter(favoriteRefId => favoriteRefId !== refId),
  }),
  [SET_FAVORITE_FUNDS]: ({ favoriteFunds }) => ({
    ...state,
    favoriteFunds,
  }),
  [SET_IS_SHOW_MODAL_FAVORITE]: ({ isShowModalFavorite }) => ({
    ...state,
    isShowModalFavorite,
  }),
  [SET_FUND_COMPANY_LIST]: ({ fundCompanies }) => ({
    ...state,
    fundCompanies,
  }),
  [SET_FUND_COMPANY_OPTIONS]: ({ fundCompanyOptions }) => ({
    ...state,
    fundCompanyOptions,
  }),
  [SET_FUND_CATEGORY_OPTIONS]: ({ fundCategoryOptions }) => ({
    ...state,
    fundCategoryOptions,
  }),
  [SET_ALL_FETCHING]: ({ status }) => ({
    ...state,
    allFetching: status,
  }),
  [SET_ADDITIONAL_FETCHING]: ({ status }) => ({
    ...state,
    additionalFetching: status,
  }),
  [SET_FUNDS_COMPARE]: ({ fundsCompare }) => ({
    ...state,
    fundsCompare,
  }),
}))
