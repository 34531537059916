import React from 'react'
import Field from '../share/Field'
import styled, { css } from 'react-emotion'
import { breakpoints, responsive } from './Form'
import {
  displayNumber,
  numberParser,
} from '../../core/helper'

const Container = styled.div``
const FieldContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
    span {
      min-width: 150px;
    }

  ${props =>
    responsive.mobile(css`
      width: 100%;
      justify-content: center;

      > div{
        display: flex;
        flex-direction: column;

        >div {
          margin-top: 15px;
          justify-content: center;
          > div {
            > div {
              width: 150px;
            }
          }
        }
      }
      span {
        padding-left: 47px;
        font-size: 16px;
        justify-content: flex-start;
      }

    `)};
`
const Unit = styled.div`
  height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 15px;

  ${props =>
    responsive.mobile(css`
      display: none !important;
    `)};
`
const CustomField = styled(Field)`
  width: fit-content;
  input[type='text'] {
    text-align: center;
    width: 200px;
  }

  ${props =>
    responsive.mobile(css`
      width: 100% !important;
      input {
        width: 280px !important;
        font-size: 16px;
      }
    `)};
`
const ContainerSlider = styled.div`
  height: 65px;
  display: flex;
  align-items: center;

  ${props =>
    responsive.mobile(css`
      margin-left: 0;
      flex-direction: column;
      min-width: 320px;
      padding: 0 47px;
      margin-top: 15px;
      margin-bottom: 40px;
    `)};
`
const ContainerSliderInfo = styled.div`
  width: 200px;
`
const HeaderSlider = styled.div`
  display: flex;
  justify-content: space-between;
`
const HeaderSmall = styled.div`
  font-size: 12px;
`
const LabelSlider = styled.div`
  font-size: 16px;
  text-align: right;
  min-width: 150px;
  margin-right: 25px;

  ${props =>
    responsive.mobile(css`
      text-align: left;
      font-size: 16px;
      margin-right: 0;
      width: 100%;
      margin-bottom: 15px;
    `)};
 `
const Slider = styled(Field)`
  .ant-slider {
    margin: 14px 0 0;
    padding: 0;
  }
`

const searchBylabel = (searchBy) => {
  switch (searchBy) {
    case 'premium':
      return 'เบี้ยประกันที่สนใจ';
    case 'sum_insured':
      return 'ทุนประกันที่สนใจ';
    case 'annuity_refund_total':
      return 'เงินคืนทั้งหมดที่สนใจ';
    case 'annuity_refund':
      return 'บำนาญที่สนใจ';
  }
}

const LifeFields = props => {
  return (
    <Container>
      <ContainerSlider>
        <LabelSlider>เน้นด้าน</LabelSlider>
        <ContainerSliderInfo>
          <HeaderSlider>
            <HeaderSmall>ความคุ้มค่า</HeaderSmall>
            <HeaderSmall>ความคุ้มครอง</HeaderSmall>
          </HeaderSlider>
          <Slider type="slider" name="weightType" min={1} max={5} value={1} />
        </ContainerSliderInfo>
      </ContainerSlider>
      <FieldContainer>
        <CustomField
          name='searchValue'
          label={(window.innerWidth > breakpoints.mobile ? searchBylabel(props.searchBy) : `${searchBylabel(props.searchBy)} (บาท)`)}
          type='text'
          autoComplete='off'
          placeholder='จำนวนเงิน'
          format={displayNumber}
          parse={numberParser}
        />
        <Unit>บาท/ ปี</Unit>
      </FieldContainer>
    </Container>
  )
}

export default LifeFields