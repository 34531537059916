import React from 'react'
import styled from 'react-emotion'
import {reduxForm} from 'redux-form'
import {connect} from 'react-redux'
import {compose} from 'recompose'
import Button from '../share/Button'
import Field from '../share/Field'
import {isPasswordNoon, url2Obj} from '../../core/helper'
import {resetPassword} from '../../ducks/auth'

const Container = styled.form`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 50px;
`

const CustomButton = styled(Button)`
  min-width: 100px;
  height: 30px;
  font-size: 12px;
  margin-top: 20px;
`

const Header = styled.div`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
`

const GoogleInput = styled(Field)`
  flex-direction: column;
  margin: 0 0 30px;
  .input-googleInput-container {
    input {
      width: 250px;
      height: 40px;
    }
  }
  &.password {
    .input-googleInput-container {
      input {
        font-size: 16px;
      }
    }
  }
`

const ResetPassword = ({handleSubmit, resetPassword, location: {search}}) => (
  <Container
    onSubmit={handleSubmit(value => resetPassword(value, url2Obj(search)))}
    noValidate>
    <Header>ตั้งรหัสผ่านใหม่</Header>
    <GoogleInput
      name="password"
      type="googleInput"
      mode="password"
      placeholder="รหัสผ่าน"
      className="password"
      hideError
    />
    <GoogleInput
      name="passwordConfirmation"
      type="googleInput"
      mode="password"
      placeholder="ยืนยันรหัสผ่าน"
      className="password"
      hideError
    />
    <CustomButton htmlType="submit" icons={['fa', 'check-circle']}>
      ยืนยัน
    </CustomButton>
  </Container>
)

const validate = values => {
  const errors = {}

  if (!values.password) {
    errors.password = 'ต้องใส่ข้อมูลในช่องนี้'
  } else if (!isPasswordNoon(values.password)) {
    errors.password = 'ต้องมีความยาวไม่ต่ำกว่า 6 ตัวอักษร'
  }
  if (!values.passwordConfirmation) {
    errors.passwordConfirmation = 'ต้องใส่ข้อมูลในช่องนี้'
  } else if (values.passwordConfirmation !== values.password) {
    errors.passwordConfirmation = 'รหัสผ่านไม่ตรงกัน'
  } else if (!isPasswordNoon(values.passwordConfirmation)) {
    errors.passwordConfirmation = 'ต้องมีความยาวไม่ต่ำกว่า 6 ตัวอักษร'
  }

  return errors
}

const enhancer = compose(
  connect(
    (state, props) => ({}),
    {resetPassword},
  ),
  reduxForm({
    form: 'resetPassword',
    validate,
  }),
)

export default enhancer(ResetPassword)
