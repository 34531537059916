import React from 'react'
import styled from 'react-emotion'

const InfoRow = ({ label = '', value = '' }) => {
  return (
    <Container>
      <Label>{label}</Label>
      <Value>{value}</Value>
    </Container>
  )
}

export default InfoRow

const Container = styled.div`
  display: flex;
  gap: 8px;
  width: 100%;
  padding: 8px 0;

  font-size: 18px;
  line-height: 24px;
  border-bottom: 1px solid #d2d4db;
`
const Label = styled.div`
  width: 50%;
  font-weight: bold;
  text-overflow: ellipsis;
  overflow: hidden;
`
const Value = styled.div`
  width: 50%;
  text-align: right;
  text-overflow: ellipsis;
  overflow: hidden;
`
