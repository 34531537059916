import { applyMiddleware, combineReducers, compose, createStore } from 'redux'
import { reducer as formReducer } from 'redux-form'
import createSagaMiddleware from 'redux-saga'
import { all } from 'redux-saga/effects'

import notFound, { watch404Saga } from './404'
import auth, { watchAuthSaga } from './auth'
import contactUs, { watchContactUsSaga } from './contactUs'
import eventRegistering, { watchEventRegisteringSaga } from './eventRegistering'
import fund, { watchFundSaga } from './fund'
import landing, { watchLandingSaga } from './landing'
import myPolicy, { watchMyPolicySaga } from './myPolicy'
import noonQuestion, { watchNoonQuestionSaga } from './noonQuestion'
import noonSquare, { watchNoonSquareSaga } from './noonSquare'
import options, { watchOptionsSaga } from './options'
import product, { watchProductSaga } from './product'
import profile, { watchProfileSaga } from './profile'
import ocrPort, { watchOcrPortSaga } from './profile/ocrPort'
import taxcal, { watchTaxcalSaga } from './taxcal'

const saga = createSagaMiddleware()

export function createMainReducer(asyncReducers) {
  return combineReducers({
    auth,
    landing,
    product,
    noonSquare,
    notFound,
    contactUs,
    options,
    profile,
    fund,
    taxcal,
    form: formReducer,
    eventRegistering,
    noonQuestion,
    ocrPort,
    myPolicy,
    ...asyncReducers,
  })
}

const composeEnhancers =
  typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose

const store = createStore(
  createMainReducer(),
  composeEnhancers(applyMiddleware(saga))
)

function* rootSaga() {
  yield all([
    watchAuthSaga(),
    watchLandingSaga(),
    watchProductSaga(),
    watchNoonSquareSaga(),
    watch404Saga(),
    watchContactUsSaga(),
    watchOptionsSaga(),
    watchProfileSaga(),
    watchFundSaga(),
    watchEventRegisteringSaga(),
    watchTaxcalSaga(),
    watchNoonQuestionSaga(),
    watchOcrPortSaga(),
    watchMyPolicySaga(),
  ])
}

saga.run(rootSaga)

export default store
