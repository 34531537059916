import React from 'react'
import styled, { css } from 'react-emotion'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import withSEO from '../share/withSEO'

import AboutUsImg from '../../static/images/About_us/About-us-1920-1080-Optimized.jpg'
import TQDLogo from '../../static/images/TQLD_Logo.gif'
import {
  OurServiceIconList,
  NoonAppContentDetails,
  TeamNoonMoblieDetails,
} from './AboutUsDetails'
import iphoneCar from '../../static/images/car.jpg'
import oic from '../../static/images/OIC-01.svg'
import Button from '../share/Button'
import { navigate } from '@reach/router'
import { responsive } from '../../core/style'

import { Carousel } from 'antd'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
`

const AboutUsImage = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  background-image: url(${AboutUsImg});
  width: 100%;
  height: 45vw;
  background-size: cover;
  background-position-x: 100vw;

  ${props =>
    responsive.mobile(css`
      height: 45vw;
      background-position-x: 100vw;
    `)};

  ${props =>
    responsive.tablet(css`
      height: 45vw;
      background-position-x: 100vw;
    `)};
`

const AboutUsImageTextContainer = styled.div`
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 365px;
  height: 118px;
  border-radius: 0 5px 5px 0;
  background-color: rgba(255, 255, 255, 0.5);
  padding-left: 50px;

  ${props =>
    responsive.mobile(css`
      width: 240px;
      height: 80px;
      padding-left: 34px;
    `)};
`

const AboutUsImageText = styled.div`
  font-size: 16px;
  &.aboutUs {
    font-weight: bold;
    font-size: 20px;
  }
  ${props =>
    responsive.mobile(css`
      font-size: 12px;

      &.aboutUs {
        font-weight: bold;
        font-size: 12px;
      }
    `)};
`

const WhoAreWeContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 288px;
  background-color: white;

  ${props =>
    responsive.mobile(css`
      height: 100%;
      padding: 30px 0;
    `)};
`

const TeamContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 50px 0px;
  align-items: center;
  width: 100%;
  height: fit-content;
  background-color: white;

  ${props =>
    responsive.mobile(css`
      height: 100%;
      padding: 30px 0;
    `)};
`

const WhoAreWeHeader = styled.div`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 30px;
`

const WhoAreWeContentContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;

  ${props =>
    responsive.mobile(css`
      flex-direction: column;
    `)};
`

const WhoAreWeContentLogo = styled.img`
  width: 135px;
  height: 50px;

  ${props =>
    responsive.mobile(css`
      margin-bottom: 30px;
    `)};
`

const WhoAreWeContentText = styled.div`
  max-width: 559px;
  font-size: 16px;
  margin-left: 35px;

  ${props =>
    responsive.mobile(css`
      margin-left: 0;
      width: 100%;
      padding: 0 30px;
    `)};
`

const OurServiceContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: rgba(106, 148, 203, 0.1);
  padding: 30px;
`

const OurServiceHeader = styled(WhoAreWeHeader)``

const OurServiceText = styled.div`
  width: 700px;
  font-size: 16px;
  text-align: center;
  margin-bottom: 40px;

  ${props =>
    responsive.mobile(css`
      width: 100%;
      text-align: center;
      max-width: 514px;
    `)};
`

const IconContentContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;

  ${props =>
    responsive.mobile(css`
      width: 100%;
      flex-wrap: wrap;
    `)};
`

const IconDetailsContentContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 21.5px;

  ${props =>
    responsive.tablet(css`
      margin: 0;
    `)};

  ${props =>
    responsive.mobile(css`
      flex-direction: column;
      justify-content: flex-start;

      width: 130px;
    `)};
`

const IconContent = styled(FontAwesomeIcon)`
  font-size: 40px;
  margin-bottom: 21px;
  color: #265fa7;
`

const AddingIcon = styled(FontAwesomeIcon)`
  position: absolute;
  font-size: 20px;
  top: -10px;
  right: 65px;
  color: #265fa7;
  ${props =>
    responsive.mobile(css`
      right: 38px;
    `)};
`

const IconText = styled.div`
  width: 181px;
  font-size: 16px;
  text-align: center;
  color: #6a94cb;
  ${props =>
    responsive.mobile(css`
      font-size: 14px;
      text-align: center;
      width: 125px;
    `)};
`

const OurProductPanel = styled.div`
  display: none;
  width: 100%;
  height: 604px;
  justify-content: center;
  background-color: white;

  ${props =>
    responsive.mobile(css`
      flex-direction: column;
      height: 100%;
    `)};
`

const OurProductImage = styled.div`
  position: relative;
  background-image: url(${iphoneCar});
  width: 370px;
  height: 604px;
  background-size: cover;

  ${props =>
    responsive.mobile(css`
      width: 100%;
      height: 34vw;
      background-position-y: -34px;
    `)};
`

const OurProductContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background-color: white;
  padding-top: 30px;
  padding: 0 70px;

  ${props =>
    responsive.tablet(css`
      padding: 0 30px;
      width: 570px;
    `)};

  ${props =>
    responsive.mobile(css`
      width: 100%;
    `)};
`

const OurProductHeader = styled(WhoAreWeHeader)`
  text-align: center;
  width: 100%;

  ${props =>
    responsive.tablet(css`
      margin-top: 30px;
    `)};
`

const OurProductContentText = styled(WhoAreWeContentText)`
  margin-bottom: 30px;
  margin-left: unset;

  ${props =>
    responsive.tablet(css`
      width: 100%;
      padding: 0;
    `)};
`

const WhatIsUBIContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 96px;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 30px;

  ${props =>
    responsive.mobile(css`
      height: 100%;
    `)};
`

const WhatIsUBIHeader = styled.div`
  font-size: 16px;
  font-weight: bold;

  ${props =>
    responsive.mobile(css`
      width: 100%;
      text-align: center;
      margin-bottom: 30px;
    `)};
`

const WhatIsUBIText = styled.div`
  font-size: 16px;
`

const NoonAppContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  ${props =>
    responsive.mobile(css`
      width: 100%;
    `)};
`

const NoonAppHeader = styled(WhatIsUBIHeader)`
  margin-bottom: 30px;

  ${props =>
    responsive.mobile(css`
      width: 100%;
    `)};
`

const NoonAppContentContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 30px;
`

const CheckIcon = styled(FontAwesomeIcon)`
  font-size: 20px;
  margin-right: 15px;
`

const NoonAppText = styled.div`
  font-size: 16px;
`

const SupporterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 171px;
  background-color: rgba(106, 148, 203, 0.1);
  padding: 30px 0;

  ${props =>
    responsive.mobile(css`
      height: 100%;
    `)};
`

const SupporterHeader = styled(WhoAreWeHeader)``

const Oic = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
  color: #333333;
  width: 100%;
  height: 80px;
  font-size: 12px;

  ${props =>
    responsive.mobile(css`
      flex-direction: column-reverse;
    `)};
`

const Img = styled.img`
  width: 170px;
  height: 125px;
  margin-left: 20px;

  ${props =>
    responsive.mobile(css`
      width: 135px;
      height: 75px;
      margin-left: 0;
    `)};
`

const FooterContactUsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 81px;
  background-color: #6a94cb;

  ${props =>
    responsive.mobile(css`
      flex-direction: column;
      height: 100%;
      padding: 30px 0;
    `)};
`

const FooterContactUsText = styled.div`
  font-size: 16px;
  color: white;
  margin-right: 30px;

  ${props =>
    responsive.mobile(css`
      margin-right: 0;
      margin-bottom: 15px;
    `)};
`

const CustomButton = styled(Button)`
  min-width: 100px;
  height: 30px;
  margin-left: 10px;
  span {
    font-size: 12px;
  }
`

const Space = styled.br`
  ${props =>
    responsive.tablet(css`
      display: none;
    `)};
`
const TeamNoonContainerAll = styled.div`
  width: 800px;
  height: fit-content;
  display: flex;
  flex-wrap: wrap;
  ${props =>
    responsive.mobile(css`
      display: none;
    `)};
`

const TeamNoonContainerMoblieAll = styled.div`
  display: none;
  ${props =>
    responsive.mobile(css`
      display: flex;
      width: 100%;
      justify-content: center;
    `)};
`

const CustomCarousel = styled(Carousel)`
  display: flex;
  height: 550px;
  width: 300px;

  .slick-dots {
    height: 20px;
    > li {
      > button {
        border-radius: 100%;
        height: 10px;
        width: 10px;
        background-color: #e5e5e5;
        opacity: 0.3;
      }
    }
  }
  .slick-dots li.slick-active button {
    height: 10px;
    width: 10px;
    background-color: #e5e5e5;
    opacity: 1;
  }
`

const TeamNoonContainer = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: row;
  height: 100%;
  :hover {
    div {
      display: flex;
      background-color: #ffd900;
    }
    img {
      filter: none;
    }
  }

  ${props =>
    responsive.mobile(css`
      display: flex;
      align-self: center;
      flex-direction: row;
      width: 100%;
      img {
        filter: none;
      }
      div {
        display: flex;
        background-color: #ffd900;
      }
    `)};

  :nth-child(4n),
  :nth-child(4n-1) {
    display: flex;
    flex-direction: row-reverse;
  }
`
const TeamNoonDetailContainer = styled.div`
  width: 200px;
  height: 250px;
  padding: 25px 5px 24px;
  background-color: #e5e5e5;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  ${props =>
    responsive.mobile(css`
      width: 150px;
      height: 250px;
      align-items: center;
      justify-content: center;
      /* padding: 20px 20px; */
    `)};
`
const TeamNoonName = styled.div`
  text-align: center;
  font: Bold 20px/24px Roboto;
`

const TeamNoonPosition = styled.div`
  text-align: center;
  font: Bold 14px/17px Roboto;
  margin-top: 10px;
`

const TeamNoonPhrase = styled.div`
  text-align: center;
  font: Italic 16px/24px Prompt;
  margin-top: 30px;
`

const TeamNoonImage = styled.img`
  filter: grayscale(1);
  width: 200px;
  height: 250px;

  ${props =>
    responsive.mobile(css`
      object-fit: cover;
      width: 150px;
      height: 250px;
    `)};
`

const AboutUs = () => (
  <Container>
    <AboutUsImage>
      <AboutUsImageTextContainer>
        <AboutUsImageText className="aboutUs">เกี่ยวกับเรา</AboutUsImageText>
        <AboutUsImageText>
          ครบและจบในที่เดียว
          <br /> สำหรับการค้นหาประกันที่เหมาะสมให้คุณ
        </AboutUsImageText>
      </AboutUsImageTextContainer>
    </AboutUsImage>
    <WhoAreWeContainer>
      <WhoAreWeHeader>เราคือใคร</WhoAreWeHeader>
      <WhoAreWeContentContainer>
        <WhoAreWeContentLogo src={TQDLogo} />
        <WhoAreWeContentText>
          TQD เป็น บริษัท InsureTech ที่มุ่งเน้นไปที่สองสิ่ง.. <br />
          หนึ่งคือให้คำแนะนำแก่ลูกค้า ว่าจะได้รับผลิตภัณฑ์ประกันภัยที่เหมาะสม{' '}
          <br />
          สองคือการวิเคราะห์ข้อมูล
          และการเก็บรวบรวมข้อมูลโดยใช้ผลิตภัณฑ์ที่เป็นนวัตกรรม
        </WhoAreWeContentText>
      </WhoAreWeContentContainer>
    </WhoAreWeContainer>
    <OurServiceContainer>
      <OurServiceHeader>บริการของเรา</OurServiceHeader>
      <OurServiceText>
        หมดปัญหากับการเลือกซื้อประกันอีกต่อไป เรามี ADVISORY PLATFORM
        ที่ช่วยวิเคราะห์หาประกันพร้อมแนะนำผลิตภัณฑ์ BEST-VALUE
        ที่เหมาะสมกับโปรไฟล์และคุณลักษณะของลูกค้าในทุกขั้นตอนของชีวิต
      </OurServiceText>
      <IconContentContainer>
        {OurServiceIconList.map(value => (
          <IconDetailsContentContainer key={value.id}>
            <IconContent icon={['fas', value.icon]} />
            {value.moreIcon && <AddingIcon icon={['fas', value.moreIcon]} />}
            <IconText>{value.text}</IconText>
          </IconDetailsContentContainer>
        ))}
      </IconContentContainer>
    </OurServiceContainer>
    <OurProductPanel>
      <OurProductImage />
      <OurProductContainer>
        <OurProductHeader>ผลิตภัณฑ์ของเรา</OurProductHeader>
        <OurProductContentText>
          TQD เริ่มต้นด้วยแพลตฟอร์มใหม่เพื่อเปลี่ยนอุตสาหกรรมประกันภัยรถยนต์ที่
          <br /> เรียกว่า 'เที่ยง'
          เที่ยงจะช่วยให้ผู้คนประหยัดค่าเบี้ยประกันภัยโดยใช้ข้อมูลเกี่ยว
          <br />
          กับพฤติกรรมของคนขับ (แนวคิด UBI)
        </OurProductContentText>
        <WhatIsUBIContainer>
          <WhatIsUBIHeader>UBI คืออะไร</WhatIsUBIHeader>
          <WhatIsUBIText>
            UBI (Usage-based insurance) หรือประกันการใช้งาน เป็นประเภทของการ
            <Space />
            ประกันภัยรถยนต์ที่ใช้พฤติกรรมการขับขี่เพื่อกำหนดเบี้ยประกันภัย
          </WhatIsUBIText>
        </WhatIsUBIContainer>
        <NoonAppContainer>
          <NoonAppHeader>ฟังก์ชั่นของแอพพลิเคชั่นนูน</NoonAppHeader>
          {NoonAppContentDetails.map(value => (
            <NoonAppContentContainer key={value.id}>
              <CheckIcon icon={['fas', 'check-circle']} />
              <NoonAppText>{value.text}</NoonAppText>
            </NoonAppContentContainer>
          ))}
        </NoonAppContainer>
      </OurProductContainer>
    </OurProductPanel>{' '}
    <TeamContainer>
      <WhoAreWeHeader>ทีมงาน</WhoAreWeHeader>

      <TeamNoonContainerAll>
        {TeamNoonMoblieDetails.map((value, index) => (
          <>
            <TeamNoonContainer>
              <TeamNoonImage
                src={require('../../static/images/About_us/' +
                  value[0].imageColor)}
              />
              <TeamNoonDetailContainer>
                <TeamNoonName>{value[0].name}</TeamNoonName>
                <TeamNoonPosition>{value[0].position}</TeamNoonPosition>
                <TeamNoonPhrase>{value[0].phrase}</TeamNoonPhrase>
              </TeamNoonDetailContainer>
            </TeamNoonContainer>
            {value.length === 2 && (
              <TeamNoonContainer>
                <TeamNoonImage
                  src={require('../../static/images/About_us/' +
                    value[1].imageColor)}
                />
                <TeamNoonDetailContainer>
                  <TeamNoonName>{value[1].name}</TeamNoonName>
                  <TeamNoonPosition>{value[1].position}</TeamNoonPosition>
                  <TeamNoonPhrase>{value[1].phrase}</TeamNoonPhrase>
                </TeamNoonDetailContainer>{' '}
              </TeamNoonContainer>
            )}
          </>
        ))}{' '}
      </TeamNoonContainerAll>

      <TeamNoonContainerMoblieAll>
        <CustomCarousel autoplay autoplaySpeed={4000}>
          {TeamNoonMoblieDetails.map((value, index) => (
            <>
              <TeamNoonContainer>
                <TeamNoonImage
                  src={require('../../static/images/About_us/' +
                    value[0].imageColor)}
                />
                <TeamNoonDetailContainer>
                  <TeamNoonName>{value[0].name}</TeamNoonName>
                  <TeamNoonPosition>{value[0].position}</TeamNoonPosition>
                  <TeamNoonPhrase>{value[0].phrase}</TeamNoonPhrase>
                </TeamNoonDetailContainer>
              </TeamNoonContainer>
              {value.length === 2 && (
                <TeamNoonContainer>
                  <TeamNoonDetailContainer>
                    <TeamNoonName>{value[1].name}</TeamNoonName>
                    <TeamNoonPosition>{value[1].position}</TeamNoonPosition>
                    <TeamNoonPhrase>{value[1].phrase}</TeamNoonPhrase>
                  </TeamNoonDetailContainer>{' '}
                  <TeamNoonImage
                    src={require('../../static/images/About_us/' +
                      value[1].imageColor)}
                  />
                </TeamNoonContainer>
              )}
            </>
          ))}{' '}
        </CustomCarousel>
      </TeamNoonContainerMoblieAll>
    </TeamContainer>
    <SupporterContainer>
      <SupporterHeader>ผู้สนับสนุน และรางวัลที่ได้รับ</SupporterHeader>
      <Oic>
        <Img src={oic} />
        ภายใต้การดูแลของ Insurance Regulatory Sandbox
      </Oic>
    </SupporterContainer>
    <FooterContactUsContainer>
      <FooterContactUsText>ต้องการสอบถามเพิ่มเติม?</FooterContactUsText>
      <CustomButton
        onClick={() => navigate('/contact-us')}
        icons={['fa', 'pen']}
      >
        ติดต่อเรา
      </CustomButton>
    </FooterContactUsContainer>
  </Container>
)

export default withSEO(
  'เกี่ยวกับเรา - Noon รับประกันความเที่ยงตรง',
  'noon อ่านว่า นูน มาจากคำในภาษาอังกฤษที่แปลว่า เที่ยง ดังสโลแกนของเราคือ นูน รับประกันความเที่ยงตรง',
  'นูน,ประกัน,คัดเลือก,เที่ยงตรง,บริษัท,noon,ที่มา,ประวัติ,ความเป็นมา'
)(AboutUs)
