import { compose, lifecycle, withState } from 'recompose'
import { isBlank } from '../../../core/helper'

const enhancer = compose(
  withState('initialized', 'setIntilized', false),
  withState('isFirstTime', 'setFirstTime', false),
  lifecycle({
    componentDidMount() {
      const isFirstTime = window.localStorage.getItem('NoonImportOICFirstTime')
      if (isBlank(isFirstTime)) {
        this.props.setFirstTime(true)
      }
      this.props.setIntilized(true)
    },
  })
)

export default enhancer
