import React from 'react'
import styled, {css} from 'react-emotion'
import {reduxForm} from 'redux-form'
import {compose, lifecycle, withState, withHandlers} from 'recompose'
import {connect} from 'react-redux'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {Link} from '@reach/router'

import {login, setData, facebookLogin} from '../../../ducks/auth'
import Modal from '../../share/Modal'
import Field from '../../share/Field'
import Button from '../../share/Button'
import {isEmail} from '../../../core/helper'
import {responsive} from '../../../core/style'
import {FacebookLoginButton} from '../../../components/Auth/FacebookAuth'
import Loading from '../../share/Loading'
import GoogleAuth from '../../../components/Auth/GoogleAuth'

const Container = styled.form`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: ${props => props.theme.GREY80};
`

const Header = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  font-size: 20px;
  margin: 92px 0 0;
`
const HeartIcon = styled(FontAwesomeIcon)`
  font-size: 20px;
  color: ${props => props.theme.PINKRED};
  margin: 0 5px;
`

const NotiIcon = styled(FontAwesomeIcon)`
  font-size: 20px;
  margin: 0 5px;
`

const TextHeartIcon = styled.span`
  font-size: 20px;
  font-weight: bold;
  color: ${props => props.theme.PINKRED};
`
const TextBellIcon = styled.span`
  font-size: 20px;
  font-weight: bold;
`

const Rowline = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;

  ${props =>
    responsive.mobile(css`
      flex-direction: column;
    `)};
`

const GoogleInput = styled(Field)`
  flex-direction: column;
  margin: 10px 0 30px;
  .input-googleInput-container {
    input {
      width: 250px;
      height: 50px;
    }
  }
  &.password {
    .input-googleInput-container {
      input {
        font-size: 16px;
      }
    }
  }
`
const Password = styled(GoogleInput)`
  margin: 0 0 15px;
`

const CustomButton = styled(Button)`
  width: 200px;
  height: 30px;
  margin-top: 20px;
  font-size: 12px;

  min-width: 188.56px;
`

const Footer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 38px 0 74px;
  font-size: 12px;
`

const CustomLink = styled(Link)`
  color: ${props => props.theme.BLUE};
  text-decoration: underline;
`

const ResponsiveContainer = styled.div`
  display: none;
  ${props =>
    responsive.mobile(css`
      display: flex;
    `)};
`

const CustomTagA = styled.a`
  color: ${props => props.theme.BLUE};
  text-decoration: underline;
`

const validate = values => {
  const errors = {}

  if (!values.email) {
    errors.email = 'ต้องใส่ข้อมูลในช่องนี้'
  } else if (!isEmail(values.email)) {
    errors.email = 'รูปแบบอีเมลไม่ถูกต้อง'
  }
  if (!values.password) {
    errors.password = 'ต้องใส่ข้อมูลในช่องนี้'
  }

  return errors
}

const LoginModal = ({
  isShowModal,
  setIsShowModal,
  handleSubmit,
  login,
  errorMessage,
  facebookLogin,
  isLoading,
  valid,
  callback = () => setIsShowModal(false),
  isReload = true,
}) => {
  return (
    <Modal isShowModal={isShowModal} setIsShowModal={setIsShowModal}>
      <Loading isLoading={isLoading}>
        <Container
          onSubmit={handleSubmit(value => {
            login(value, true, callback, isReload)
          })}
          noValidate>
          <Header>
            <Rowline>
              <ResponsiveContainer>
                รับ <NotiIcon icon={['far', 'bell']} />
                <TextBellIcon>การแจ้งเตือน</TextBellIcon>
              </ResponsiveContainer>
              &nbsp;จากแท็กที่คุณสนใจ!
            </Rowline>
          </Header>
          <GoogleInput
            name="email"
            type="googleInput"
            placeholder="อีเมล"
            errorMessage={errorMessage.email}
            hideError
          />
          <Password
            name="password"
            type="googleInput"
            mode="password"
            placeholder="รหัสผ่าน"
            className="password"
            errorMessage={errorMessage.password}
            hideError
          />
          <CustomButton
            htmlType="submit"
            disabled={!valid}
            errorMessage={errorMessage}
            icons={['fa', 'user-circle']}>
            เข้าสู่ระบบ
          </CustomButton>
          <FacebookLoginButton
            name={'เข้าสู่ระบบด้วย Facebook'}
            facebookLogin={facebookLogin}
            modal
            closeModal={() => setIsShowModal(false)}
          />
          <GoogleAuth
            name="เข้าสู่ระบบด้วย Google"
            modal
            closeModal={() => setIsShowModal(false)}
          />
          <Footer>
            <span>
              ต้องการความช่วยเหลือ?&nbsp;
              <CustomTagA href={`/authorize/forgetPassword`} target="_blank">
                ลืมรหัสผ่าน
              </CustomTagA>
              &nbsp;|&nbsp;
              <CustomTagA href={`/contact-us`} target="_blank">
                ติดต่อเรา
              </CustomTagA>
            </span>
            <span>
              ยังไม่มีบัญชีผู้ใช้?&nbsp;
              <CustomTagA href={`/authorize/register`} target="_blank">
                สมัครเลย
              </CustomTagA>
            </span>
          </Footer>
        </Container>
      </Loading>
    </Modal>
  )
}

const enhancer = compose(
  connect(
    (state, props) => ({
      errorMessage: state.auth.errorMessage,
      isLoading: state.auth.isLoading,
    }),
    {login, setData, facebookLogin},
  ),
  reduxForm({
    form: 'FavModal',
    validate,
    onChange: (values, _, props) => {
      props.errorMessage !== '' && props.setData({errorMessage: ''})
    },
  }),
  lifecycle({
    componentDidMount() {
      this.props.setData({errorMessage: ''})
    },
    componentWillUnmount() {
      this.props.setData({errorMessage: ''})
    },
  }),
)

export default enhancer(LoginModal)
