import React from 'react'
import styled, { css } from 'react-emotion'
import Slider from 'react-slick'

import Product from './ProductCard'

import { responsive } from '../../core/style'

const Container = styled.div`
  display: none;
  margin-bottom: 200px;

  ${props =>
    responsive.tablet(css`
      display: block;
      margin-bottom: 100px;
    `)};
`

const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 50px;
  padding: 0 20px;

  text-align: center;
  font-size: 24px;
  line-height: 36px;

  b {
    font-size: 30px;
    line-height: 48px;
  }

  img {
    width: 150px;
    height: 150px;
    margin-bottom: 50px;
    object-fit: contain;

    border-radius: 50%;
  }

  ${props =>
    responsive.tablet(css`
      font-size: 16px;
      line-height: 24px;

      b {
        font-size: 20px;
        line-height: 30px;
      }

      img {
        width: 75px;
        height: 75px;
        margin-bottom: 30px;
      }
    `)};
`

const Note = styled.div`
  width: 100%;
  max-width: 850px;
  margin: 20px auto;
  padding: 0 22px;

  font-family: 'Sarabun', sans-serif;
  font-size: 12px;
  line-height: 26px;

  > b {
    font-family: 'Sarabun', sans-serif;
    font-size: 16px;
  }

  ul {
    padding-inline-start: 20px;
    li {
      font-family: 'Sarabun', sans-serif;
    }
  }
`

const SliderContainer = styled.div`
  display: none;
  height: 600px;

  ${props =>
    responsive.tablet(css`
      display: block;
    `)};
`

const Slick = styled(Slider)`
  height: 550px;

  > .slick-list {
    height: 550px;
  }

  > .slick-dots {
    bottom: -35px;
    > li {
      background-color: rgba(255, 185, 51, 0.3);
      border-radius: 50%;
      > button {
        :before {
          display: none;
        }
      }
    }
    > li.slick-active {
      background-color: ${props => props.theme.ORANGE};
    }
  }
`

const ModifiedProductCard = styled(Product)`
  margin: 0 auto;
`

const BrMobile = styled.br`
  display: none;

  ${props =>
    responsive.mobile(css`
      display: block;
    `)};
`

const CompanyTable = props => {
  return (
    <Container>
      <Header>
        <img src={props.company.company.logo} alt={props.company.company.ref} />
        <b>
          ประกันภัยไวรัสโคโรนา <BrMobile />
          รับประกันภัยโดย{props.company.company.name}
        </b>
        <div>{props.company.company.subHeader}</div>
      </Header>
      <SliderContainer>
        {props.company.packages.length > 1 ? (
          <Slick
            dots
            autoplay={false}
            centerMode
            slidesToShow={1}
            variableWidth
          >
            {props.company.packages.map((p, index) => {
              return (
                <Product
                  aid="corona-insurance-goto-buy-on-mobile corona-insurance-detail-link-on-mobile"
                  key={index}
                  index={index}
                  data={{ ...p, ...props.company }}
                  onDetailClick={() => {
                    props.onOpenPolicyDetailModal(p.label, 'mobile')
                    props.setDetailIndex(p.value)
                  }}
                  onBuyClick={() => {
                    props.onClickGotoBuyPolicy(p.label, 'mobile')
                    props.change('corona_form', 'product', p.value)
                    props.go2Buy()
                  }}
                />
              )
            })}
          </Slick>
        ) : (
          <ModifiedProductCard
            aid="corona-insurance-goto-buy-on-mobile corona-insurance-detail-link-on-mobile"
            key={0}
            index={0}
            data={{ ...props.company.packages[0], ...props.company }}
            onDetailClick={() => {
              props.onOpenPolicyDetailModal(
                props.company.packages[0].label,
                'mobile'
              )
              props.setDetailIndex(props.company.packages[0].value)
            }}
            onBuyClick={() => {
              props.onClickGotoBuyPolicy(
                props.company.packages[0].label,
                'mobile'
              )
              props.change(
                'corona_form',
                'product',
                props.company.packages[0].value
              )
              props.go2Buy()
            }}
          />
        )}
      </SliderContainer>
      <Note>
        <b>หมายเหตุ</b>
        <ul>
          <li>
            **ไม่คุ้มครองการเสียชีวิต สูญเสียอวัยวะ สายตา
            ทุพพลภาพสิ้นเชิงจากอุบัติเหตุขณะขับขี่ หรือโดยสารรถจักรยานยนต์
          </li>
          <li>
            ขอสงวนสิทธิ์ในการเปลี่ยนแปลงและแก้ไข รายละเอียดแบบประกัน
            ซึ่งอาจจะมีการเปลี่ยนแปลงจากผู้รับประกันโดยตรง
          </li>
        </ul>
        <br />
        <b>
          เงื่อนไขการรับประกันภัยและข้อยกเว้นที่สำคัญของ
          {props.company.company.name}
        </b>
        <ul>
          {props.company.condition.map((c, index) => (
            <li key={index}>{c}</li>
          ))}
        </ul>
      </Note>
    </Container>
  )
}

export default CompanyTable
