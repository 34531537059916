import React from 'react'
import styled, { css } from 'react-emotion'

import Button from '../share/Button'
import { Products } from './utils'

import { displayNumber } from '../../core/helper'
import { responsive } from '../../core/style'

const Header = styled.div`
  margin: 60px 0;

  font-size: 30px;
  font-weight: bold;
  line-height: 46px;
  text-align: center;
  font-family: Prompt, Roboto, sans-serif;

  ${props =>
    responsive.tablet(css`
      display: none;
    `)};
`

const Container = styled.div`
  width: 100%;
  padding: 0 22px;
  overflow: hidden;
  overflow-x: auto;

  ${props =>
    responsive.tablet(css`
      display: none;
    `)};
`

const Table = styled.table`
  border-collapse: collapse;
  width: 1341px;
  margin: 0 auto 10px;

  border-radius: 5px;
  overflow: hidden;

  * {
    font-family: Sarabun, Prompt, Roboto, sans-serif;
    font-size: 20px;
  }

  img {
    width: 75px;
    height: 75px;
    margin-bottom: 10px;
    object-fit: contain;

    border-radius: 50%;
  }

  th {
    padding: 15px 10px;

    text-align: center;
    white-space: pre-wrap;
    font-weight: normal;

    background-color: #f8f8f8;
    border-left: 3px solid white;
    border-right: 3px solid white;
  }
  th:first-child {
    border-left: none;
    border-right: none;
    background-color: #e6e6e6;
  }
  th:last-child {
    border-right: none;
  }

  td {
    width: 141px;
    padding: 15px 0;

    text-align: center;
    white-space: pre-wrap;

    border-left: 3px solid white;
    border-right: 3px solid white;

    > span {
      font-size: 16px;
      line-height: 21px;
    }
  }
  td:first-child {
    border-left: none;
    border-right: none;
  }
  td:last-child {
    border-right: none;
  }
`

const BuyButton = styled(Button)`
  height: 48px;

  color: white;
  font-size: 20px;
  font-weight: normal;

  border-radius: 5px;

  :hover {
    color: white;
  }
`

const Note = styled.div`
  width: 100%;
  max-width: 1385px;
  margin: 20px auto 150px;
  padding: 0 22px;

  font-family: 'Sarabun', sans-serif;
  font-size: 12px;
  line-height: 26px;

  > b {
    font-family: 'Sarabun', sans-serif;
    font-size: 16px;
  }

  ul {
    padding-inline-start: 20px;
    li {
      font-family: 'Sarabun', sans-serif;
    }
  }

  ${props =>
    responsive.tablet(css`
      display: none;
    `)};
`

const CoverageTable = props => {
  const packages = Products.map(product => product.packages.map(p => p)).flat()
  let minPrice = 99999
  packages.forEach(p => {
    if (p.price < minPrice) minPrice = p.price
  })
  return (
    <React.Fragment>
      <Header>
        ประกันภัยไวรัสโคโรนา
        {/* เบี้ยเริ่มต้น {displayNumber(minPrice)} บาท/ปี */}
      </Header>
      <Container>
        <Table>
          <thead>
            <tr>
              <th style={{ fontWeight: 'bold' }}>บริษัท</th>
              <th style={{ fontWeight: 'bold', borderLeft: 'none' }}>
                เบี้ยประกัน (บาท/ปี)
              </th>
              <th>ตรวจพบเชื้อไวรัสโคโรนา (COVID-19) จ่ายทันที (บาท)</th>
              <th>ค่ารักษาพยาบาล (บาท)</th>
              <th>เจ็บป่วยขั้นวิกฤตจากเชื้อไวรัสโคโรนา (COVID-19) (บาท)</th>
              <th>
                เงินชดเชยกรณีนอนโรงพยาบาล เมื่อติดเชื้อไวรัสโคโรนา (COVID-19)
                (บาท)
              </th>
              <th>
                **เสียชีวิต สูญเสียอวัยวะ สายตา ทุพพลภาพสิ้นเชิงจากอุบัติเหตุ
                (อบ.1) (บาท)
              </th>
              <th>อายุที่สามารถซื้อได้</th>
              <th>เลือกแผน</th>
            </tr>
          </thead>
          <tbody>
            {Products.map(product => {
              return product.packages.map((p, index) => {
                return (
                  <tr
                    key={index}
                    style={{
                      backgroundColor:
                        index % 2 === 0
                          ? product.color.secondary
                          : product.color.spare,
                    }}
                  >
                    {index === 0 && (
                      <td
                        rowSpan={product.packages.length}
                        style={{
                          fontWeight: 'bold',
                          backgroundColor: product.color.primary,
                        }}
                      >
                        <img
                          src={product.company.logo}
                          alt={product.company.ref}
                        />
                        <div>{product.company.name}</div>
                      </td>
                    )}
                    <td
                      style={{
                        fontWeight: 'bold',
                        fontSize: '26px',
                        borderLeft: 'none',
                      }}
                    >
                      {displayNumber(p.price)}
                    </td>
                    <td>{p.details.found || '-'}</td>
                    <td>{p.details.hs || '-'}</td>
                    <td>{p.details.critical || '-'}</td>
                    <td>{p.details.admit || '-'}</td>
                    <td>{p.details.lost || '-'}</td>
                    <td>{p.details.age || '-'}</td>
                    <td>
                      <BuyButton
                        style={{ backgroundColor: product.color.button }}
                        aid="corona-insurance-goto-buy-on-web"
                        onClick={() => {
                          props.onClickGotoBuyPolicy(p.label, 'web')
                          props.change('corona_form', 'product', p.value)
                          props.go2Buy()
                        }}
                      >
                        ซื้อเลย
                      </BuyButton>
                    </td>
                  </tr>
                )
              })
            })}
          </tbody>
        </Table>
      </Container>
      <Note>
        <b>หมายเหตุ</b>
        <ul>
          <li>
            **ไม่คุ้มครองการเสียชีวิต สูญเสียอวัยวะ สายตา
            ทุพพลภาพสิ้นเชิงจากอุบัติเหตุขณะขับขี่ หรือโดยสารรถจักรยานยนต์
          </li>
          <li>
            ขอสงวนสิทธิ์ในการเปลี่ยนแปลงและแก้ไข รายละเอียดแบบประกัน
            ซึ่งอาจจะมีการเปลี่ยนแปลงจากผู้รับประกันโดยตรง
          </li>
        </ul>
        {Products.map((product, pIndex) => (
          <React.Fragment key={pIndex}>
            <br />
            <b>
              เงื่อนไขการรับประกันภัยและข้อยกเว้นที่สำคัญของ
              {product.company.name}
            </b>
            <ul>
              {product.condition.map((c, index) => (
                <li key={index}>{c}</li>
              ))}
            </ul>
          </React.Fragment>
        ))}
      </Note>
    </React.Fragment>
  )
}

export default CoverageTable
