import React from 'react'
import styled, { css } from 'react-emotion'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Modal from '../share/Modal'
import Button from '../share/Button'
import { Products } from './utils'

import { responsive } from '../../core/style'
import { displayNumber } from '../../core/helper'

const CustomModal = styled(Modal)`
  top: 50px;
`

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 40px;

  font-family: Prompt, Roboto, sans-serif;

  ${props =>
    responsive.mobile(css`
      padding: 40px 20px;
    `)};
`
const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  margin: 0 0 56px;

  font-size: 30px;
  line-height: 50px;
  text-align: center;

  img {
    width: 75px;
    height: 75px;
    margin-bottom: 25px;
    object-fit: contain;

    border-radius: 50%;
  }

  span {
    font-weight: normal;
  }

  b {
    font-size: 50px;
  }

  ${props =>
    responsive.mobile(css`
      margin: 0 0 47px;

      font-size: 20px;
      font-weight: bold;
      line-height: 30px;

      b {
        font-size: 30px;
      }
    `)};
`

const CoverageTable = styled.table`
  border-collapse: collapse;
  width: 100%;
  max-width: 600px;
  margin-bottom: 53px;

  th {
    width: 50%;
    padding: 15px;
    font-size: 20px;
    font-weight: bold;
  }
  td {
    width: 50%;
    padding: 15px;
    font-family: 'Sarabun', sans-serif;
    font-size: 20px;
    line-height: 30px;
  }

  tr:nth-child(odd) {
    background-color: rgba(255, 212, 130, 0.1);
  }
  tr:first-child {
    background-color: rgba(255, 185, 51, 0.5);
  }

  ${props =>
    responsive.mobile(css`
      th:first-child,
      td:first-child {
        font-size: 16px;
        width: 60%;
      }
      th:last-child,
      td:last-child {
        font-size: 16px;
        width: 40%;
      }
    `)};
`

const Note = styled.div`
  margin-bottom: 54px;

  font-family: 'Sarabun', sans-serif;
  font-size: 14px;
  line-height: 30px;

  > b {
    font-family: 'Sarabun', sans-serif;
    font-size: 16px;
  }

  ul {
    padding-inline-start: 20px;
    li {
      font-family: 'Sarabun', sans-serif;
    }
  }
`

const Condition = styled.div`
  width: 100%;

  font-size: 16px;
  line-height: 30px;

  > b {
    font-family: 'Sarabun', sans-serif;
  }
`

const Row = styled.div`
  display: flex;
`

const Check = styled(FontAwesomeIcon)`
  margin-top: 6px;
  margin-right: 15px;

  font-size: 16px;
  color: ${props => props.theme.GREEN};
`

const Text = styled.div`
  font-family: 'Sarabun', sans-serif;
`

const CloseButton = styled(Button)`
  margin-top: 30px;
  min-width: 200px;
  height: 38px;
`

const Desktop = styled.span`
  display: flex;

  ${props =>
    responsive.mobile(css`
      display: none;
    `)};
`

const Mobile = styled.span`
  display: none;

  ${props =>
    responsive.mobile(css`
      display: flex;
    `)};
`

const DetailModal = props => {
  const packages = Products.map(product =>
    product.packages.map(p => ({
      ...p,
      company: product.company,
      condition: product.condition,
    }))
  ).flat()
  const data = packages.find(p => p.value === props.index)
  return (
    <CustomModal
      width="700px"
      isShowModal={props.isShow}
      onCancel={props.onClose}
      setIsShowModal={() => {}}
    >
      {data && (
        <Container>
          <Header>
            <img src={data.company.logo} alt={data.company.ref} />
            ประกันภัยไวรัสโคโรนา
            <br />
            รับประกันภัยโดย
            <br />
            {data.company.name}
            <br />
            <br />
            <span>
              เบี้ยประกัน <b>{displayNumber(data.price)}</b> บาท/ปี
            </span>
          </Header>
          <CoverageTable>
            <tbody>
              <tr>
                <th>ข้อตกลงความคุ้มครอง</th>
                <th>
                  <Desktop>
                    วงเงินคุ้มครอง หรือจำนวน เงินประกันภัย (บาท)
                  </Desktop>
                  <Mobile>จำนวนเงิน เอาประกันภัย (บาท)</Mobile>
                </th>
              </tr>
              {data.details.found && (
                <tr>
                  <td>ตรวจพบเชื้อไวรัสโคโรนา (COVID-19) จ่ายทันที</td>
                  <td>{data.details.found || '-'}</td>
                </tr>
              )}
              {data.details.hs && (
                <tr>
                  <td>ค่ารักษาพยาบาล</td>
                  <td>{data.details.hs || '-'}</td>
                </tr>
              )}
              {data.details.critical && (
                <tr>
                  <td>เจ็บป่วยขั้นวิกฤตจากเชื้อไวรัสโคโรนา (COVID-19)</td>
                  <td>{data.details.critical || '-'}</td>
                </tr>
              )}
              {data.details.admit && (
                <tr>
                  <td>
                    เงินชดเชยกรณีนอนโรงพยาบาล เมื่อติดเชื้อไวรัสโคโรนา
                    (COVID-19)
                  </td>
                  <td>{data.details.admit || '-'}</td>
                </tr>
              )}
              {data.details.lost && (
                <tr>
                  <td>
                    เสียชีวิต สูญเสียอวัยวะ สายตา ทุพพลภาพสิ้นเชิงจากอุบัติเหตุ
                    (อบ.1)
                  </td>
                  <td>{data.details.lost || '-'}</td>
                </tr>
              )}
            </tbody>
          </CoverageTable>
          <Condition>
            <b>
              เงื่อนไขการรับประกันภัยและ
              <br />
              ข้อยกเว้นที่สำคัญ
            </b>
            {(data.condition || []).map((con, index) => (
              <Row key={index}>
                <Check icon={['fas', 'check-circle']} />
                <Text>{con}</Text>
              </Row>
            ))}
          </Condition>
          <CloseButton onClick={props.onClose}>ปิด</CloseButton>
        </Container>
      )}
    </CustomModal>
  )
}

export default DetailModal
