import React from 'react'
import {compose, withState, withHandlers} from 'recompose'
import {
  Layout,
  Header,
  HeaderTitle,
  Body,
  BodyTitle,
  BodyDescription,
  Footer,
  NextButton,
} from '../Step1'
import {TextInput} from '../../components'
import State, {getMaxStep} from '../Step1/State'
import {BackButton, Space} from '../Step2'
import * as paths from '../../common/paths'
import AnswerGroup from '../Step1/AnswerGroup'
import {Dropdown} from '../../components'
import TermAndConditionModal from './TermAndConditionModal'
import ErrorModal from './ErrorModal'
import {withRouter} from '../../enhances'
import {spidusStore, SpidusManager} from '../../core/spidus'
import {httpPost} from '../../../../core/httpClient'
import styled from 'react-emotion'
import {range} from 'lodash'
import moment from 'moment'

const MainAnswerGroup = styled(AnswerGroup)`
  margin-top: 48px;

  ${AnswerGroup.Body} {
    > * {
      margin-top: 16px;
    }
  }
`

const SubAnswerGroup = styled(AnswerGroup)`
  margin-top: 48px;

  ${AnswerGroup.Body} {
    > * {
      margin-top: 16px;
    }
  }
`

const Info = styled.div`
  font-size: 16px;
  font-family: 'Sarabun', sans-serif;
  color: #333333;
`

const AddressAnswerGroup = styled(MainAnswerGroup)`
  @media screen and (max-width: 550px) {
    ${AnswerGroup.Body} {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      > * {
        width: 48%;
      }
    }
  }
`

const Step3 = props => (
  <SpidusManager configName="noontax_config_web_prepare_tax" enableLoading>
    <Layout>
      <Header>
        <HeaderTitle>เตรียมข้อมูลเพื่อยื่นภาษี</HeaderTitle>
        <State step={3} />
      </Header>
      <Body>
        <BodyTitle>ยื่นแบบออนไลน์</BodyTitle>
        <BodyDescription style={{maxWidth: 290}}>
          กรอกข้อมูลเพิ่มเติมเพื่อยื่นภาษีออนไลน์ ผ่านแอป RD SmartTax
          ของกรมสรรพากร
        </BodyDescription>
        <MainAnswerGroup caption="ข้อมูลผู้มีเงินได้" spaceBetweenLine="16px">
          <Dropdown answerName="personal_prefix_name" caption="คำนำหน้าชื่อ" />
          <TextInput answerName="personal_first_name" caption="ชื่อจริง" />
          <TextInput answerName="personal_last_name" caption="นามสกุล" />
          <TextInput.PersonalId
            answerName="personal_id"
            caption="เลขประจำตัวประชาชน"
          />
          <TextInput.PersonalId
            answerName="payer_id_40_1"
            caption="เลขประจำตัวผู้เสียภาษีอากรผู้จ่ายเงินได้"
            tooltip={['ตามในใบ 50 ทวิ']}
          />
        </MainAnswerGroup>
        <AddressAnswerGroup
          caption="ที่อยู่ตามบัตรประชาชน"
          spaceBetweenLine="16px">
          <TextInput
            answerName="personal_address_house_no"
            caption="บ้านเลขที่"
          />
          <TextInput
            answerName="personal_address_sub_district"
            caption="แขวง/ตำบล"
          />
          <TextInput
            answerName="personal_address_district"
            caption="เขต/อำเภอ"
          />
          <TextInput answerName="personal_address_province" caption="จังหวัด" />
          <TextInput
            answerName="personal_address_postcode"
            caption="รหัสไปรษณีย์"
          />
        </AddressAnswerGroup>
        <MainAnswerGroup
          answerName="spouse_info"
          caption="ข้อมูลคู่สมรส"
          spaceBetweenLine="16px">
          <Dropdown answerName="spouse_prefix_name" caption="คำนำหน้าชื่อ" />
          <TextInput answerName="spouse_first_name" caption="ชื่อจริง" />
          <TextInput answerName="spouse_last_name" caption="นามสกุล" />
          <TextInput.PersonalId
            answerName="spouse_id"
            caption="เลขประจำตัวประชาชน"
          />
        </MainAnswerGroup>

        <MainAnswerGroup answerName="children_info" caption="ข้อมูลลูก">
          {range(1, 11).map(i => (
            <SubAnswerGroup
              key={i}
              answerName={`child_${i}_info`}
              caption={`ลูกคนที่ ${i}`}>
              {({value}) => (
                <>
                  <Info>
                    {value.birthdate &&
                      moment(value.birthdate, 'DD/MM/YYYY').xformat(
                        `- เกิดวันที่ DD MMMM YYYY`,
                      )}
                  </Info>
                  <Info>
                    {
                      {inlaw: '- เป็นลูกแท้ๆ', adopted: '- เป็นลูกบุญธรรม'}[
                        value.type
                      ]
                    }
                  </Info>
                  <Info>
                    {{disable: '- พิการ', infirm: '- ทุพพลภาพ'}[
                      value.handicapStatus
                    ] || '- ร่างกายปกติ'}
                  </Info>
                  <TextInput.PersonalId
                    answerName={`child_${i}_id`}
                    caption="เลขประจำตัวประชาชน"
                  />
                </>
              )}
            </SubAnswerGroup>
          ))}
        </MainAnswerGroup>

        <MainAnswerGroup answerName="dad_info" caption="ข้อมูลพ่อ">
          <TextInput.PersonalId
            answerName="dad_id"
            caption="เลขประจำตัวประชาชน"
          />
        </MainAnswerGroup>
        <MainAnswerGroup answerName="mom_info" caption="ข้อมูลแม่">
          <TextInput.PersonalId
            answerName="mom_id"
            caption="เลขประจำตัวประชาชน"
          />
        </MainAnswerGroup>
        <MainAnswerGroup answerName="sdad_info" caption="ข้อมูลพ่อคู่สมรส">
          <TextInput.PersonalId
            answerName="sdad_id"
            caption="เลขประจำตัวประชาชน"
          />
        </MainAnswerGroup>
        <MainAnswerGroup answerName="smom_info" caption="ข้อมูลแม่คู่สมรส">
          <TextInput.PersonalId
            answerName="smom_id"
            caption="เลขประจำตัวประชาชน"
          />
        </MainAnswerGroup>
        <MainAnswerGroup
          answerName="disabled_info"
          caption="ข้อมูลผู้พิการ/ทุพพลภาพในอุปการะ">
          <TextInput.PersonalId
            answerName="disabled_id"
            caption="เลขประจำตัวประชาชน"
          />
        </MainAnswerGroup>
      </Body>
      <Footer>
        <BackButton
          to={paths.prepareTaxStep2Path()}
          onClick={() =>
            dataLayer.push({event: 'back_to_confirm_personal_tax_summary'})
          }>
          กลับ
        </BackButton>
        <Space />
        <NextButton onClick={props.onNext}>ยื่นแบบ</NextButton>
      </Footer>
      <TermAndConditionModal
        isShowModal={props.isShowTermAndConditionModal}
        setIsShowModal={props.setIsShowTermAndConditionModal}
        onConfirm={props.onConfirmTermAndCondition}
      />
      <ErrorModal
        isShowModal={props.isShowErrorModal}
        setIsShowModal={props.setIsShowErrorModal}
        onConfirm={props.onConfirmErrorModal}
        children={props.errorMessage}
      />
    </Layout>
  </SpidusManager>
)

const enhancer = compose(
  withRouter(),
  withState(
    'isShowTermAndConditionModal',
    'setIsShowTermAndConditionModal',
    false,
  ),
  withState('isShowErrorModal', 'setIsShowErrorModal', false),
  withState('errorMessage', 'setErrorMessage', null),
  withHandlers({
    setIsShowErrorModal: props => value => {
      if (!value) {
        dataLayer.push({event: 'submit_personal_tax_filing_require_data'})
      }
      props.setIsShowErrorModal(value)
    },
    onNext: props => async () => {
      dataLayer.push({event: 'submit_personal_tax_filing_require_data'})

      const invalid_message = await spidusStore.execute(
        '[@noontax_info_for_prepare_tax_to_rd(invalid_message)]',
      )

      if (invalid_message) {
        dataLayer.push({
          event:
            'submit_personal_tax_filing_require_data_failure_from_noon_validator',
        })

        props.setErrorMessage(invalid_message)
        props.setIsShowErrorModal(true)
      } else {
        dataLayer.push({
          event:
            'submit_personal_tax_filing_require_data_success_from_noon_validator',
        })

        props.setIsShowTermAndConditionModal(true)
      }
    },
    onConfirmTermAndCondition: props => async () => {
      dataLayer.push({event: 'confirm_terms_and_conditions'})

      try {
        const response = await httpPost(`rd_smart_tax/send_data_pnd91`, {
          sessionId: spidusStore.getSessionId(),
          env:
            window.location.host === 'noon.in.th' ||
            window.location.host === 'www.noon.in.th'
              ? 'production'
              : 'uat',
        })
        const code = response.data.data.controlCode

        dataLayer.push({
          event:
            'submit_personal_tax_filing_require_data_success_from_rd_validator',
        })

        props.setIsShowTermAndConditionModal(false)
        props.history.push(paths.prepareTaxSuccessPath(code))
      } catch (e) {
        try {
          dataLayer.push({
            event:
              'submit_personal_tax_filing_require_data_failure_from_rd_validator',
          })

          const error_message = e.response.data.message

          props.setIsShowTermAndConditionModal(false)
          props.setErrorMessage(error_message)
          props.setIsShowErrorModal(true)
        } catch (e) {
          alert('ขออภัยเกิดข้อผิดพลาดบางอย่าง')
        }
      }
    },
    onConfirmErrorModal: props => () => {
      props.setIsShowErrorModal(false)
    },
  }),
)

export default enhancer(Step3)
