import React from 'react'
import styled, { css } from 'react-emotion'

import Button from '../share/Button'
import CoronaBanner from '../../static/images/Corona-Banner-new-Landing.png'
import CoronaBannerMobile from '../../static/images/375x427-Ads-Corona.jpg'

import CoronaBannerLanding from '../../static/images/Corona-Banner-Landing.jpg'
import CoronaBannerLandingMobile from '../../static/images/Corona-Banner-MB-Landing.jpg'

import CoronaBannerUndermaintenance from '../../static/images/Corona-Banner-Undermaintenance.jpg'
import CoronaBannerUndermaintenanceMobile from '../../static/images/Corona-Banner-MB-undermaintenance-Mobile.png'

import TaxBannerLanding from '../../static/images/Banner_noon_Tax_63_Desktop.png'
import TaxBannerLandingMobile from '../../static/images/Banner_noon_Tax_63_Mobile.png'

import { Products } from './utils'
import { responsive } from '../../core/style'
import { displayNumber } from '../../core/helper'

const Banner = styled.div`
  width: 100%;
  height: 688px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("${CoronaBanner}");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  ${props =>
    responsive.tablet(css`
      background-position: left;
    `)};

  ${props =>
    responsive.mobile(css`
      height: 367px;

      background-image: url("${CoronaBannerMobile}");
  `)};
`

const BannerWrap = styled.div`
  transform: translateX(250px);

  ${props =>
    responsive.desktop(css`
      transform: translateX(200px);
    `)};

  ${props =>
    responsive.tablet(css`
      padding: 50px;

      background-color: rgba(255, 255, 255, 0.9);
      border-radius: 50px;

      transform: translateX(0);
    `)};

  ${props =>
    responsive.mobile(css`
      padding: 48px 30px;

      border-radius: 5px;
    `)};
`

const BannerText = styled.div`
  font-size: 59px;
  font-weight: bold;
  line-height: 70px;
  text-align: center;

  color: ${props => props.theme.GREY80};

  > span {
    color: #d62c3b;
  }

  ${props =>
    responsive.mobile(css`
      font-size: 30px;
      line-height: 35px;
    `)};
`

const Price = styled.div`
  font-size: 34px;
  font-weight: normal;
  text-align: center;
  line-height: 101px;
  color: ${props => props.theme.GREY80};

  > span {
    font-family: Prompt, Roboto, sans-serif;
    font-size: 67px;
    font-weight: bold;
  }

  ${props =>
    responsive.mobile(css`
      font-size: 19px;
      line-height: 29px;

      height: 60px;

      > span {
        font-size: 38px;
        line-height: 57px;
      }
    `)};
`

const BuyButton = styled(Button)`
  min-width: 240px;
  height: 65px;

  font-size: 28px;
  line-height: 43px;

  border-radius: 78px;

  ${props =>
    responsive.mobile(css`
      min-width: 139px;

      font-size: 19px;
      line-height: 29px;

      height: 38px;
    `)};
`

const _Header = ({ onPriceClick }) => {
  const packages = Products.map(product => product.packages.map(p => p)).flat()
  let minPrice = 99999
  packages.forEach(p => {
    if (p.price < minPrice) minPrice = p.price
  })
  return (
    <Banner>
      <BannerWrap>
        <BannerText>
          ประกันภัย<span>ไวรัสโคโรนา</span>
          <br /><br />
          {/* <Price>
            เบี้ยเริ่มต้นเพียง <span>{displayNumber(minPrice)}</span> บาท/ปี
          </Price> */}
        </BannerText>
        <BuyButton
          aid="corona-insurance-product-campaign-banner-action-button"
          onClick={onPriceClick}
        >
          สนใจซื้อ
        </BuyButton>
      </BannerWrap>
    </Banner>
  )
}

export const UndermaintenanceLayout = styled.div``
export const UndermaintenanceBannerDesktop = styled.img`
  width: 100%;

  @media screen and (max-width: 500px) {
    display: none;
  }
`
export const UndermaintenanceBannerMobile = styled.img`
  width: 100%;

  @media screen and (min-width: 501px) {
    display: none;
  }
`

export const UndermaintenanceHeader = props => (
  <UndermaintenanceLayout>
    <UndermaintenanceBannerDesktop src={CoronaBannerUndermaintenance} />
    <UndermaintenanceBannerMobile src={CoronaBannerUndermaintenanceMobile} />
  </UndermaintenanceLayout>
)

export const CoronaAdBanner = props => (
  <UndermaintenanceLayout>
    <UndermaintenanceBannerDesktop src={CoronaBannerLanding} />
    <UndermaintenanceBannerMobile src={CoronaBannerLandingMobile} />
  </UndermaintenanceLayout>
)

export const TaxAdBanner = props => (
  <UndermaintenanceLayout>
    <UndermaintenanceBannerDesktop src={TaxBannerLanding} />
    <UndermaintenanceBannerMobile src={TaxBannerLandingMobile} />
  </UndermaintenanceLayout>
)

export default _Header
