import { isBlank } from '../../core/helper'
import { type } from '../NoonSmart/options'

export const getTopic = (category, subCategory) => {
  if (isBlank(category)) return null
  if (category === 'life') {
    if (isBlank(subCategory)) return null
    return `ประกันชีวิต${type.find(t => t.value === subCategory).label}`
  }
  if (category === 'health') {
    if (subCategory === 'hs') {
      return `คุ้มครองค่ารักษาพยาบาล`
    }
    else if (subCategory === 'ci') {
      return `คุ้มครองโรคร้ายแรง`
    }
  }
  if (category === 'motor') {
  }
  return null
}
