import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { navigate } from '@reach/router'
import React from 'react'
import styled, { css } from 'react-emotion'
import { connect } from 'react-redux'
import { compose, withProps } from 'recompose'
import windowSize from '../../core/react-window-size'
import { responsive } from '../../core/style'
import FooterMenuList from './FooterMenuList'

const SOCIAL_MEDIA_LIST = [
  { link: 'https://www.facebook.com/nooninsurland', icon: ['fab', 'facebook'] },
  { link: 'https://twitter.com/nooninth', icon: ['fab', 'twitter'] },
  {
    link: 'https://www.youtube.com/channel/UC27zHa7O3PaHOBOb34QHGwQ',
    icon: ['fab', 'youtube'],
  },
]

const Container = styled.footer`
  display: ${props => (props.isNoFooter ? 'none' : 'flex')};
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: #101820;
  color: white;
  padding: 0 0 36px;

  ${props =>
    responsive.tablet(css`
      padding: 0;
    `)};
`

const CopyrightPart = styled.div`
  margin-top: 35px;
  margin-bottom: 25px;
  display: flex;
  width: 100%;
  max-width: 915px;
  justify-content: space-between;
  align-items: center;

  ${props =>
    responsive.tablet(css`
      margin-top: 5px;
      padding: 0 25px;
    `)};

  ${props =>
    responsive.mobile(css`
      flex-direction: column-reverse;
      min-height: 159px;
      justify-content: center;
    `)};
`

const CopyrightContent = styled.div`
  width: 400px;
  font-size: 12px;
  cursor: pointer;

  ${props =>
    responsive.mobile(css`
      margin-top: 30px;
      max-width: 280px;
      padding: 0 20px;
      text-align: center;
    `)};
`

const IconPart = styled.div`
  > a {
    color: white;
  }
`

const CustomIcon = styled(FontAwesomeIcon)`
  font-size: 20px;
  margin-right: 15px;
  cursor: pointer;

  ${props =>
    responsive.mobile(css`
      font-size: 30px;
    `)};
`

const Footer = ({ windowWidth, isNoFooter, copyrightYear }) => (
  <Container id="footer" isNoFooter={isNoFooter}>
    <FooterMenuList />
    <CopyrightPart>
      <CopyrightContent onClick={() => navigate('/policy')}>
        {windowWidth <= 425 ? (
          <React.Fragment>
            COPYRIGHT © {copyrightYear} ALL RIGHTS RESERVED <br />
            นโยบายและเงื่อนไขการใช้งาน <br />
            EMPOWERED BY TQD
          </React.Fragment>
        ) : (
          <React.Fragment>
            COPYRIGHT © {copyrightYear} ALL RIGHTS RESERVED | นโยบายและเงื่อนไขการใช้งาน
            EMPOWERED BY TQD
          </React.Fragment>
        )}
      </CopyrightContent>
      <IconPart>
        {SOCIAL_MEDIA_LIST.map((s, idx) => (
          <a key={idx} href={s.link} target="_blank">
            <CustomIcon icon={s.icon} />
          </a>
        ))}
      </IconPart>
    </CopyrightPart>
  </Container>
)

const enhancer = compose(
  connect(
    (state, props) => ({
      isNoFooter: state.notFound.isNoFooter,
    }),
    {}
  ),
  withProps(props => {
    const copyrightYear = new Date().getFullYear()
    return {copyrightYear}
  }),
)

export default windowSize(enhancer(Footer))
