import React from 'react'
import styled, {css} from 'react-emotion'
import classnames from 'classnames'
import {get} from 'lodash'
import {Icon} from '../components/index'

const Layout = styled.div`
  display: flex;
  margin-bottom: ${props => props.marginBottom};
  margin-top: ${props => props.marginTop};
`
const LeftPanel = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
`
const Caption = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  font-size: 16px;
  line-height: 20px;
  padding-right: 10px;
  font-family: 'Sarabun', sans-serif;
  color: #333333;
`
const RightPanel = styled.div`
  flex: 1;
  > * {
    width: 100% !important;
  }
`
const ErrorLayout = styled.div`
  color: #ff0034;
  font-size: 12px;
  height: 0;
  overflow: visible;
  @media (max-width: 425px) {
    height: unset;
  }
`
const ErrorMessage = styled.span`
  margin-left: 4px;
`

const withCaption = (options = {}) => Component => ({
  caption,
  width,
  leftWidth,
  rightWidth,
  marginBottom,
  className,
  required,
  captionStyle = {},
  valueStyle = {},
  style = {},
  touched,
  hideError,
  errorOpacity,
  errorColor,
  error,
  asyncError,
  type,
  ...childrenProps
}) =>
  caption !== undefined ? (
    <Layout
      className={classnames(
        className,
        get(options, 'className.name', options.className),
      )}
      marginBottom={marginBottom}
      style={style}
      width={
        leftWidth !== undefined && rightWidth !== undefined
          ? parseInt(leftWidth) + parseInt(rightWidth)
          : width || null
      }>
      <LeftPanel width={leftWidth} style={captionStyle}>
        <Caption>
          {caption} {caption && required && `*`}
        </Caption>
        {touched && (error || asyncError) && (
          <ErrorLayout className="error-container">
            <Icon icon={['fas', 'exclamation-circle']} />
            <ErrorMessage
              className="error"
              type={type}
              errorOpacity={errorOpacity}
              errorColor={errorColor}>
              {error || asyncError}
            </ErrorMessage>
          </ErrorLayout>
        )}
      </LeftPanel>
      <RightPanel width={rightWidth} style={valueStyle}>
        <Component {...childrenProps} caption={caption} />
      </RightPanel>
    </Layout>
  ) : (
    <Component {...childrenProps} />
  )

export default withCaption
