import {takeLatest, put, call, all} from 'redux-saga/effects'
import {createReducer, Creator} from '../helper'
import {httpGet, httpPost, API_HOST} from '../../core/httpClient'
import {message} from 'antd'
import {navigate} from '@reach/router'
import {obj2Url} from '../../core/helper'

const SET_DATA = 'SET_DATA'
const SEARCH_BOX = 'SEARCH_BOX'
const GENERAL_SEARCH = 'GENERAL_SEARCH'
const GET_ALL_LEAD_COUNT_LANDING = 'GET_ALL_LEAD_COUNT_LANDING'
const POST_FREE_FORM = 'POST_FREE_FORM'
const GET_FREE_COUNT = 'GET_FREE_COUNT'
const GET_QUESTION = 'GET_QUESTION'
const POST_QUESTION = 'POST_QUESTION'

export const setData = Creator(SET_DATA, 'data')
export const searchBox = Creator(SEARCH_BOX, 'data')
export const searchGeneral = Creator(GENERAL_SEARCH, 'word')
export const getAllLeadCountLanding = Creator(GET_ALL_LEAD_COUNT_LANDING)
export const postFreeForm = Creator(POST_FREE_FORM, 'data')
export const getFreeCount = Creator(GET_FREE_COUNT)
export const getQuestion = Creator(GET_QUESTION, 'id')
export const postQuestion = Creator(POST_QUESTION, 'data')

export function* getAllLeadCountLandingSaga() {
  const urlLeadCount = `${API_HOST}insurance_leads/lead_count`
  const urlLeadPremium = `${API_HOST}insurance_leads/all_lead_premium`
  const urlInsurancePoliciesCount = `${API_HOST}insurance_policies/insurance_policies_count`
  try {
    const {lead, premium, policies} = yield all({
      lead: call(httpGet, urlLeadCount),
      premium: call(httpGet, urlLeadPremium),
      policies: call(httpGet, urlInsurancePoliciesCount),
    })

    yield put(
      setData({
        lead: lead.data.data.leadCount,
        premium: premium.data.data.allLeadPremium,
        policies: policies.data.data.insurancePoliciesCount,
      }),
    )
  } catch (error) {
    console.error('error', error)
  }
  yield put(setData({isLoading: false}))
}

export function* getFreeCountSaga() {
  const url = `${API_HOST}life_insurance_policy_events/count_life_insurance_policy_event`
  try {
    const response = yield httpGet(url)
    yield put(
      setData({
        countFree: response.data.data.count,
      }),
    )
  } catch (error) {
    console.error('error', error)
  }
}

export function* postFreeFormSaga({payload: {data}}) {
  yield put(setData({isLoading: true}))

  const url = `${API_HOST}life_insurance_policy_events/count_life_insurance_policy_event`
  try {
    yield httpPost(url, data)
    message.success('ลงทะเบียนสำเร็จแล้ว', 3)
    navigate('/free-form-confirm')
  } catch (error) {
    switch (error.response.status) {
      case 422:
        yield put(setData({errorMessage: 'วันเกิดไม่ถูกต้อง'}))
        break
      case 403:
        yield put(setData({errorMessage: 'อีเมลถูกลงทะเบียนแล้ว'}))
        break
      default:
        yield put(setData({errorMessage: 'เซิฟเวอร์มีปัญหา'}))
        break
    }
  }

  yield put(setData({isLoading: false}))
}

const getLifeParam = type => {
  switch (type) {
    case 'whole_life':
    case 'term':
      return 5
    case 'saving':
      return 1
    case 'annuity':
      return 3
    default:
      return 5
  }
}

export function* searchBoxSaga({payload: {data}}) {
  const motor = ['motor', 'addOn']
  const life = ['whole_life', 'term', 'saving', 'annuity']
  const health = ['hs', 'ci']

  if (motor.includes(data.insuranceType)) {
    navigate(`/smart/InsuranceCategory/motor/intermediate?${obj2Url(data)}`)
  }
  if (life.includes(data.insuranceType)) {
    navigate(
      `/smart/InsuranceCategory/life/intermediate/Lifeform/${
        data.insuranceType
      }/${getLifeParam(data.insuranceType)}?${obj2Url(data)}`,
    )
  }
  if (health.includes(data.insuranceType)) {
    navigate(
      `/smart/InsuranceCategory/health/intermediate/Healthform/${
        data.insuranceType
      }?${obj2Url(data)}`,
    )
  }
}

export function* searchGeneralSaga({payload: {word}}) {
  yield put(setData({initialSearch: false}))

  const trimWord = word.trim()
  const url = `${API_HOST}general_search`
  try {
    const response = yield httpGet(url, {user_query: trimWord})
    yield put(setData({arSearch: response.data.data.searchResult}))
  } catch (error) {
    console.error('error', error)
  }
  if (word !== '') {
    yield put(setData({initialSearch: true}))
  }
}

export function* getQuestionSaga({payload: {id}}) {
  yield put(setData({isLoadingQuestion: true}))

  const url = `${API_HOST}user_question`
  try {
    const response = yield httpGet(url)
    const data = response.data.data.questions
    const newData = data.filter(value => value.id !== id)
    const randomQuestion = Math.floor(
      Math.random() * Math.floor(newData.length),
    )
    yield put(
      setData({
        questions: newData[randomQuestion],
      }),
    )
  } catch (error) {
    console.error('error', error)
  }
  yield put(setData({isLoadingQuestion: false}))
}

export function* postQuestionSaga({payload: {data}}) {
  const url = `${API_HOST}user_question`
  try {
    yield httpPost(url, data)
  } catch (error) {
    console.error('error', error)
  }
}

export function* watchLandingSaga() {
  yield takeLatest(SEARCH_BOX, searchBoxSaga)
  yield takeLatest(GENERAL_SEARCH, searchGeneralSaga)
  yield takeLatest(GET_ALL_LEAD_COUNT_LANDING, getAllLeadCountLandingSaga)
  yield takeLatest(POST_FREE_FORM, postFreeFormSaga)
  yield takeLatest(GET_FREE_COUNT, getFreeCountSaga)
  yield takeLatest(GET_QUESTION, getQuestionSaga)
  yield takeLatest(POST_QUESTION, postQuestionSaga)
}

const initial = {
  data: {},
  arSearch: [],
  isLoading: false,
  isLoadingQuestion: false,
  errorMessage: '',
  lead: 0,
  premium: 0,
  policies: 0,
  countFree: 0,
  questions: [],
  initialSearch: false,
}

export default createReducer(initial, state => ({
  [SET_DATA]: ({data}) => ({...state, ...data}),
}))
