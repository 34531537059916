import React from 'react'
import styled from 'react-emotion'
import Select from 'react-select'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

const CustomStyleSelect = styled(Select)`
  position: relative;

  height: 40px;
  width: 100%;
  border: 1px solid #ffd482;
  opacity: ${props => (props.disabled ? '0.5' : '1')};
  font-size: 14px;
  font-family: ${props => props.theme.headerFont};
  background-color: white;
  border-radius: 5px;

  &.is-focused > .Select-control {
    box-shadow: none;
  }

  &.is-focused:not(.is-open) > .Select-control {
    box-shadow: none;
  }

  &.is-open .Select-control .Select-arrow-zone {
    > .anticon {
      transform: scaleY(-1);
    }
  }

  &.is-open {
    border-radius: 5px 5px 0 0 !important;
  }

  > :hover {
    cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  }

  > .Select-control {
    position: relative;

    height: calc(100%);
    background-color: ${props => props.disabled && '#E5E5E5'};
    color: black;
    padding: 12px 0;

    > .Select-multi-value-wrapper {
      > .Select-placeholder {
        position: absolute;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;

        color: black;
        opacity: 0.6;

        width: 100%;
        text-align: center;
      }

      > .Select-input {
        position: absolute;
        top: 0;
        left: 10px;

        display: flex;
        width: 100%;
        height: 100%;
        align-items: center;

        > input {
          height: 100%;
          border: none;
          background-color: transparent;
        }
      }

      > .Select-value > .Select-value-label {
        position: absolute;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
        width: 100%;
        max-width: calc(100% - 40px);
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: wrap;
      }
    }

    > .Select-clear-zone {
      display: none;
    }

    > .Select-arrow-zone {
      position: absolute;
      top: 0;
      bottom: 0;

      display: flex;
      justify-content: flex-end;
      align-items: center;
      width: 100%;
      height: 100%;
      padding-right: 10px;

      color: #ffd482;
    }
  }

  > .Select-menu-outer {
    position: absolute;
    z-index: 1;

    width: 100%;
    border: none;

    > .Select-menu {
      overflow-y: auto;
      overflow-x: hidden;
      max-height: 220px;
      align-items: flex-start;
      border-radius: 0 0 5px 5px;
      background-color: ${props =>
        props.themeColor === 'orange' ? 'white' : props.theme.DARKBLUE};
      outline: ${props =>
        props.themeColor === 'orange' &&
        `1px solid ${props.theme.EXTRALIGHTORANGE}`};
      font-size: 16px;
      line-height: 20px;

      > .Select-noresults {
        color: white;
      }

      > .Select-option {
        color: ${props =>
          props.themeColor === 'orange' ? 'rgba(51, 51, 51, 0.5)' : 'white'};
        padding: 13px 15px;
        line-height: 20px;
        text-align: left;
        word-break: break-word;
        :hover {
          background-color: ${props =>
            props.themeColor === 'orange'
              ? 'rgba(255, 212, 130, 0.5)'
              : 'rgba(106, 148, 203, 0.5)'};
          color: ${props =>
            props.themeColor === 'orange' ? 'black' : 'white'};
        }
      }

      /* > .Select-option.is-focused {
        color: ${props => (props.themeColor === 'orange' ? 'black' : 'white')};
        background-color: ${props =>
          props.themeColor === 'orange'
            ? 'rgba(255, 212, 130, 0.5)'
            : 'rgba(106, 148, 203, 0.5)'};
      }

      > .Select-option.is-selected {
        color: ${props => (props.themeColor === 'orange' ? 'black' : 'white')};
        background-color: ${props =>
          props.themeColor === 'orange'
            ? 'rgba(255, 212, 130, 0.5)'
            : 'rgba(106, 148, 203, 0.5)'};
      } */
    }
  }
`

const CustomOptionContainer = styled.div`
  display: flex;

  .header {
    width: ${props => (props.haveSubmenu ? '127px' : '100%')};
  }

  .sub-menu {
    width: ${props => (props.haveSubmenu ? '322px' : '0')};
  }

  :hover {
    .header {
      background-color: rgba(106, 148, 203, 0.5);
    }
    .sub-menu {
      display: flex;
    }
  }
`

const OptionHeader = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
  padding-left: 15px;
`

const SubMenu = styled.div`
  display: none;
  flex-direction: column;
`

const CustomSubmenu = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
  padding-left: 21px;

  background-color: rgba(106, 148, 203, 0.15);
  color: rgba(255, 255, 255, 0.5);

  :hover {
    background-color: rgba(106, 148, 203, 0.5);
    color: white;
  }
`

const CustomIcon = styled(FontAwesomeIcon)`
  font-size: 20px;
  cursor: pointer;
`

const CustomOption = ({
  option: {header, submenu = []},
  selectValue,
  onSelect,
}) => (
  <CustomOptionContainer haveSubmenu={submenu.length > 0}>
    <OptionHeader className="header">{header}</OptionHeader>
    <SubMenu className="sub-menu">
      {submenu &&
        submenu.map(item => (
          <CustomSubmenu
            key={item.value}
            onClick={() => {
              onSelect(item)
            }}>
            {item.label}
          </CustomSubmenu>
        ))}
    </SubMenu>
  </CustomOptionContainer>
)

const CustomSelect = props => (
  <CustomStyleSelect
    {...props}
    arrowRenderer={() => <CustomIcon icon={['fa', 'angle-down']} />}
    optionComponent={props.isCustomOption ? CustomOption : undefined}
  />
)

export default CustomSelect
