import React from 'react'
import styled from 'react-emotion'
import {navigate} from '@reach/router'
import Button from '../share/Button'

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 50px;
`

const CustomButton = styled(Button)`
  min-width: 100px;
  height: 30px;
  margin-top: 20px;
  font-size: 12px;
`

const Header = styled.div`
  font-size: 20px;
  font-weight: bold;
`

const Content = styled.div`
  font-size: 16px;
`

const ForgetPasswordResendEmail = () => (
  <Container>
    <Header>อีเมลถูกส่งแล้ว</Header>
    <Content>ลองเช็คในอินบอกซ์ของคุณสิ!</Content>
    <CustomButton
      onClick={() => {
        navigate(`/authorize/login`)
      }}
      icons={['fa', 'user-circle']}>
      เข้าสู่ระบบ
    </CustomButton>
  </Container>
)

export default ForgetPasswordResendEmail
