import React from 'react'
import styled, {css} from 'react-emotion'
import mountain from '../../static/images/mountain.svg'
import man from '../../static/images/man_landing_moutain.svg'
import woman from '../../static/images/woman_landing_mountain.svg'
import bigCircle from '../../static/images/man_landing_bigcircle.svg'
import cloud from '../../static/images/man_landing_1cloud.svg'
import {responsive} from '../../core/style'

const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  z-index: -3;
  opacity: 0.5;
`

const Mountain = styled.img`
  position: absolute;
  z-index: -2;
  height: 2650px;
  right: calc(50vw - 420px);
  top: 450px;

  ${props =>
    responsive.tablet(css`
      right: calc(50vw - 482px);
      top: 399px;
    `)};

  ${props =>
    responsive.mobile(css`
      right: calc(50vw - 675px);
      top: 1160px;
    `)};
`
const OntopMountain = styled.img`
  position: absolute;
  height: 320px;
  width: 180px;
  z-index: -1;
  top: ${props => (props.gender === 'male' ? 150 : 160)}px;
  left: calc(50vw - 472px);
  transition: top 0.5s;

  ${props =>
    responsive.tablet(css`
      left: calc(50vw - 414px);
      top: ${props.gender === 'male' ? '104px' : '114px'};
    `)};

  ${props =>
    responsive.mobile(css`
      left: calc(50vw - 215px);
      top: ${props.gender === 'male' ? '862px' : '883px'};
    `)};
`

const BigCircle = styled.img`
  position: absolute;
  height: 600px;
  width: 600px;
  left: calc(50vw - 730px);

  z-index: -2;

  ${props =>
    responsive.tablet(css`
      right: calc(50vw - 660px);
    `)};

  ${props =>
    responsive.mobile(css`
      left: calc(50vw - 450px);
      top: 750px;
    `)};
`

const Cloud1 = styled.img`
  position: absolute;
  height: 250px;
  width: 250px;
  left: calc(50vw - 301px);
  z-index: -2;
  top: 277px;

  ${props =>
    responsive.tablet(css`
      top: 200px;
    `)};

  ${props =>
    responsive.mobile(css`
      left: calc(50vw - 10px);
      top: 925px;
    `)};
`

const Background = ({gender}) => (
  <Container>
    <BigCircle src={bigCircle} />
    <Cloud1 src={cloud} />
    <OntopMountain gender={gender} src={gender === 'male' ? man : woman} />
    <Mountain src={mountain} />
  </Container>
)

export default Background
