import { connect } from 'react-redux'
import { compose, lifecycle, withHandlers, withState } from 'recompose'

import {
  deleteMyPolicy,
  fetchMyPolicy,
  scrapMyPolicy,
} from '../../../ducks/myPolicy'

const enhancer = compose(
  connect(
    state => ({
      isLoading: state.myPolicy.isLoading || false,
      policyList: state.myPolicy.policyList || [],
    }),
    { deleteMyPolicy, fetchMyPolicy, scrapMyPolicy }
  ),
  withState('inputLink', 'setInputLink', ''),
  withState('isShowModal', 'setIsShowModal', false),
  withHandlers({
    onDelete: ({ deleteMyPolicy, fetchMyPolicy }) => (
      policyId,
      cb = () => {}
    ) => {
      deleteMyPolicy(policyId, {
        onSuccess: () => {
          fetchMyPolicy()
          cb()
        },
      })
    },
    onInputChange: ({ setInputLink }) => e => {
      setInputLink(e.target.value)
    },
    onSubmitLink: ({
      inputLink,
      setInputLink = () => {},
      scrapMyPolicy = () => {},
      fetchMyPolicy = () => {},
    }) => () => {
      scrapMyPolicy(inputLink, {
        onSuccess: () => {
          setInputLink('')
          fetchMyPolicy()
        },
      })
    },
  }),
  lifecycle({
    componentDidMount() {
      this.props.setInputLink('')
      this.props.fetchMyPolicy({})
    },
  })
)

export default enhancer
