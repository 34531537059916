import React from 'react'
import styled, { css } from 'react-emotion'
import { compose, withHandlers } from 'recompose'
import { reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import TrackVisibility from 'react-on-screen'
import windowSize from '../../core/react-window-size'
import { Field as ReduxField } from 'redux-form'

import Field, {
  Label,
  ErrorText,
  FieldContainer,
  InputContainer,
  ErrorContainer,
  ExclamationCircle,
} from '../share/Field'
import Button from '../share/Button'
import Selector from '../share/Field/Selector'

import { buyCorona } from '../../ducks/product'
import { Products } from './utils'

import {
  isEmail,
  isPhoneNumber,
  phoneFormatter,
  formatParser,
} from '../../core/helper'
import { responsive } from '../../core/style'

const Container = styled.div`
  padding: 100px 20px;

  color: ${props => props.theme.GREY80};
  background-color: rgba(229, 229, 229, 0.2);

  ${props =>
    responsive.mobile(css`
      padding: 65px 20px 103px;
    `)};
`

const LeadForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 514px;
  margin: 0 auto;
`

const LeadTopic = styled.div`
  margin-bottom: 57px;

  font-size: 26px;
  line-height: 39px;
  text-align: center;

  > span {
    font-size: 40px;
    font-weight: bold;
  }

  ${props =>
    responsive.mobile(css`
      margin-bottom: 52px;

      font-size: 16px;
      line-height: 24px;

      > span {
        font-size: 30px;
        line-height: 24px;
      }
    `)};
`

const LeadField = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 37px 47px;

  background-color: #e7eef6;

  > div:last-child {
    width: 100%;
  }

  ${props =>
    responsive.mobile(css`
      padding: 37px 30px;

      > div {
        width: 100% !important;
      }
    `)};
`

const CustomField = styled(Field)`
  flex-direction: column;
  margin-bottom: 30px;

  > span {
    justify-content: flex-start;
    margin: 0 0 10px;

    font-size: 20px;
  }

  > div {
    input {
      padding: 0 20px;
      height: 48px;

      text-align: left;
      font-size: 20px;
    }
  }

  > div {
    > div {
      width: 100%;
      > input {
        width: 100%;
      }
    }
  }

  &.product {
    width: 420px;
    margin: 0 auto 30px;

    > span.label {
      justify-content: center;
    }

    > div {
      > div {
        font-size: 16px;
      }
    }
  }

  &.firstName {
    width: 200px;
    margin-right: 20px;
  }

  &.lastName {
    width: 200px;
  }

  &.gender {
    flex-direction: row;
    > span {
      min-width: unset;
      margin-right: auto;
    }
    > div {
      width: fit-content;
      > div {
        width: fit-content;

        > div.male {
          width: 150px;
          height: 48px;
          border-radius: 24px 0 0 24px;
        }
        > div.female {
          width: 150px;
          height: 48px;
          border-radius: 0 24px 24px 0;
        }
      }
    }
  }

  &.birthdate {
    > div {
      > div {
        width: 100%;
      }
    }
  }

  ${props =>
    responsive.mobile(css`
      > span {
        font-size: 16px;
        line-height: 24px;
      }

      &.product {
        width: 100%;
      }

      &.firstName {
        margin-right: 0;
      }

      &.gender {
        > span {
          line-height: 35px;
          margin-bottom: 0;
        }
        > div {
          > div {
            > div.male {
              width: 110px;
              height: 35px;
              border-radius: 24px 0 0 24px;
            }
            > div.female {
              width: 110px;
              height: 35px;
              border-radius: 0 24px 24px 0;
            }
          }
        }
      }
    `)};
`

const Consent = styled.div`
  display: flex;
  width: 100%;
  margin-top: 30px;
`

const CustomCheckbox = styled(Field)`
  height: 30px;
  width: 30px;
  margin: 0;

  > div {
    display: flex;
  }

  > div.error-container {
    display: block;
  }
`

const ConsentText = styled.div`
  font-size: 20px;
  line-height: 30px;

  ${props =>
    responsive.mobile(css`
      font-size: 14px;
      line-height: 21px;
    `)};
`

const Link2 = styled.span`
  text-decoration: underline;

  cursor: pointer;
`

const SubmitButton = styled(Button)`
  margin-top: 25px;
  width: 100% !important;
  height: 48px;

  font-size: 20px;

  ${props =>
    responsive.mobile(css`
      font-size: 16px;
      line-height: 24px;
    `)};
`

const Topic = styled.div`
  width: 100%;
  margin-bottom: 30px;

  font-size: 24px;
  font-weight: bold;
  line-height: 36px;

  border-bottom: 1px solid #707070;

  ${props =>
    responsive.mobile(css`
      font-size: 20px;
      line-height: 30px;
    `)};
`

const PackageContainer = styled(FieldContainer)`
  flex-direction: column;
  width: 420px;
  height: 48px;
  margin: 0 auto 50px;

  .Select-value-label {
    font-size: 20px;
  }

  > span {
    justify-content: center;
    margin: 0 0 10px;

    font-size: 20px;
  }

  ${props =>
    responsive.mobile(css`
      width: 100%;
      .Select-value-label {
        font-size: 16px;
      }

      > span {
        font-size: 16px;
      }
    `)};
`

const PackageValue = props => {
  return (
    <div className="Select-value" title={props.value.label}>
      <span className="Select-value-label">
        <img
          src={props.value.company.logo}
          alt={props.value.company.ref}
          style={{
            width: '30px',
            height: '30px',
            marginRight: '12px',
            borderRadius: '50%',
          }}
        />
        {props.children}
      </span>
    </div>
  )
}

const PackageField = props => {
  return (
    <PackageContainer className="product">
      <Label>แบบประกันที่เลือก</Label>
      <InputContainer>
        <Selector
          searchable={false}
          {...props.input}
          onChange={e => props.input.onChange(e.value)}
          onBlur={() => props.input.onBlur()}
          options={props.options}
          valueComponent={PackageValue}
        />
      </InputContainer>
      {props.meta.touched && props.meta.error && (
        <ErrorContainer className="error-container">
          <ExclamationCircle icon={['fas', 'exclamation-circle']} />
          <ErrorText className="error">{error}</ErrorText>
        </ErrorContainer>
      )}
    </PackageContainer>
  )
}

const Lead = props => {
  const packages = Products.map(product =>
    product.packages.map(p => ({
      ...p,
      color: product.color,
      company: product.company,
    }))
  ).flat()
  return (
    <Container id="corona_lead" aid="corona-insurance-lead-form">
      <LeadForm onSubmit={props.onSubmit} noValidate>
        <LeadTopic>
          <span>ซื้อประกันภัยไวรัสโคโรนา</span>
          <TrackVisibility once>
            {({ isVisible }) => isVisible && props.onLeadFormVisible()}
          </TrackVisibility>
          กรอกข้อมูลเพื่อซื้อประกัน
        </LeadTopic>
        <ReduxField
          name="product"
          component={PackageField}
          options={packages}
        />
        <LeadField>
          <CustomField
            className="gender"
            name="gender"
            type="switch"
            label="เพศ"
          />
          <CustomField className="firstName" name="firstName" label="ชื่อ" />
          <CustomField className="lastName" name="lastName" label="นามสกุล" />
          <CustomField
            className="birthdate"
            name="birthdate"
            label="วันเกิด"
            type="datepicker"
          />
          <CustomField
            className="telnumber"
            name="telnumber"
            label="เบอร์โทรศัพท์"
            format={phoneFormatter}
            parse={formatParser}
          />
          <CustomField className="email" name="email" label="อีเมล" />
          <Consent>
            <CustomCheckbox name="consent" type="checkbox" />
            <ConsentText>
              ข้าพเจ้าฯ ตกลงยินยอมรับ{' '}
              <Link2 onClick={props.onOpenModal}>ข้อตกลงและเงื่อนไข</Link2>
            </ConsentText>
          </Consent>
          <SubmitButton
            htmlType="submit"
            aid="corona-insurance-lead-form-submit-button"
          >
            ซื้อเลย
          </SubmitButton>
        </LeadField>
      </LeadForm>
    </Container>
  )
}

const validate = values => {
  const errors = {}

  if (!values.product) {
    errors.product = 'กรุณาเลือก'
  }

  if (!values.gender) {
    errors.gender = 'ต้องใส่ข้อมูลในช่องนี้'
  }

  if (!values.firstName) {
    errors.firstName = 'ต้องใส่ข้อมูลในช่องนี้'
  }
  if (!values.lastName) {
    errors.lastName = 'ต้องใส่ข้อมูลในช่องนี้'
  }

  if (!values.telnumber) {
    errors.telnumber = 'ต้องใส่ข้อมูลในช่องนี้'
  } else if (!isPhoneNumber(values.telnumber)) {
    errors.telnumber = 'รูปแบบหมายเลขไม่ถูกต้อง'
  }

  if (!values.consent) {
    errors.consent = 'กรุณาเลือกยอมรับ'
  }

  if (!values.birthdate) {
    errors.birthdate = 'ต้องใส่ข้อมูลในช่องนี้'
  } else if (values.birthdate.length < 10) {
    errors.birthdate = 'กรุณากรอกวันเดือนปี (วว/ดด/ปปปป)'
  }

  if (values.email !== '' && !isEmail(values.email)) {
    errors.email = 'รูปแบบอีเมลไม่ถูกต้อง'
  }

  errors._error = Object.keys(errors).length

  return errors
}

const enhancer = compose(
  connect(
    state => ({
      initialValues: {
        product: 28746,
      },
    }),
    { buyCorona }
  ),
  reduxForm({ form: 'corona_form', validate }),
  withHandlers({
    onSubmit: ({ handleSubmit, buyCorona }) => {
      dataLayer.push({
        event: 'submit_data_from_lead_form',
        leadFormName: 'corona_insurance',
      })

      return handleSubmit(values => {
        buyCorona(values)
      })
    },
  })
)

export default windowSize(enhancer(Lead))
