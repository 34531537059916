import React from 'react'
import styled from 'react-emotion'

import { displayNumber } from '../../core/helper'

const Container = styled.div`
  width: 320px;
  height: 550px;
  padding: 0 10px;
`

const Inner = styled.div`
  width: 100%;
  height: 550px;
  overflow: hidden;

  border-radius: 5px;
  border: 1px solid #e5e5e5;
`

const Header = styled.div`
  height: 92px;
  padding-left: 55px;

  background-color: ${props => props.bgColor};
`

const HeaderText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding-left: 12px;

  font-size: 12px;
  line-height: 18px;
  background-color: ${props => props.bgColor};

  > span {
    font-size: 16px;
    line-height: 24px;
    > b {
      font-size: 30px;
    }
  }
`

const Body = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 415px;
  padding: 35px 25px;
`

const BuyButton = styled.div`
  height: 43px;

  font-size: 20px;
  font-weight: bold;
  line-height: 43px;
  text-align: center;

  background-color: ${props => props.bgColor};
  cursor: pointer;
`

const Info = styled.div``

const Row = styled.div`
  padding-bottom: 5px;
  margin-bottom: 18px;

  border-bottom: 1px solid #707070;
`

const Topic = styled.div`
  font-size: 12px;
  line-height: 16px;
  font-family: 'Sarabun', sans-serif;
`

const Value = styled.pre`
  margin: 0;
  font-size: 12px;
  line-height: 16px;
  white-space: pre-wrap;
  overflow: hidden;
  font-family: 'Sarabun', sans-serif;
  > b {
    font-family: 'Sarabun', sans-serif;
    font-size: 16px;
    > br {
      display: none;
    }
  }

  > br {
    display: none;
  }
`

const Detail = styled.div`
  font-size: 16px;
  line-height: 24px;
  text-decoration: underline;
  text-align: center;

  cursor: pointer;
`

const Product = props => {
  return (
    <Container className={props.className}>
      <Inner>
        <Header bgColor={props.data.color.primary}>
          <HeaderText
            bgColor={
              props.index % 2 === 0
                ? props.data.color.secondary
                : props.data.color.spare
            }
          >
            {props.data.company.name}
            <br />
            <span>
              เบี้ยประกัน <b>{displayNumber(props.data.price)}</b> บาท/ปี
            </span>
          </HeaderText>
        </Header>
        <Body>
          <Info>
            <Row>
              <Topic>ตรวจพบเชื้อไวรัสโคโรนา (COVID-19) จ่ายทันที</Topic>
              <Value>
                {props.data.details.found ? (
                  <React.Fragment>
                    <b>{props.data.details.found}</b> บาท
                  </React.Fragment>
                ) : (
                  '-'
                )}
              </Value>
            </Row>
            <Row>
              <Topic>ค่ารักษาพยาบาล (บาท)</Topic>
              <Value>
                {props.data.details.hs ? (
                  <React.Fragment>
                    <b>{props.data.details.hs}</b>
                  </React.Fragment>
                ) : (
                  '-'
                )}
              </Value>
            </Row>
            <Row>
              <Topic>เจ็บป่วยขั้นวิกฤตจากเชื้อไวรัสโคโรนา (COVID-19)</Topic>
              <Value>
                {props.data.details.critical ? (
                  <React.Fragment>
                    <b>{props.data.details.critical}</b> บาท/วัน
                  </React.Fragment>
                ) : (
                  '-'
                )}
              </Value>
            </Row>
            <Row>
              <Topic>
                เงินชดเชยกรณีนอนโรงพยาบาล เมื่อติดเชื้อไวรัสโคโรนา (COVID-19)
                (บาท)
              </Topic>
              <Value>
                {props.data.details.admit ? (
                  <React.Fragment>
                    <b>{props.data.details.admit}</b>
                  </React.Fragment>
                ) : (
                  '-'
                )}
              </Value>
            </Row>
            <Row>
              <Topic>
                เสียชีวิต สูญเสียอวัยวะ สายตา ทุพพลภาพสิ้นเชิงจากอุบัติเหตุ
                (อบ.1) คุ้มครองถึง
              </Topic>
              <Value>
                {props.data.details.lost ? (
                  <React.Fragment>
                    <b>{props.data.details.lost}</b> บาท
                  </React.Fragment>
                ) : (
                  '-'
                )}
              </Value>
            </Row>
          </Info>
          <Detail onClick={props.onDetailClick}>ดูรายละเอียด</Detail>
        </Body>
        <BuyButton
          bgColor={
            props.index % 2 === 0
              ? props.data.color.secondary
              : props.data.color.spare
          }
          onClick={props.onBuyClick}
        >
          ซื้อเลย
        </BuyButton>
      </Inner>
    </Container>
  )
}

export default Product
